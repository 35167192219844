import { useState } from 'react';
import Subscription, {
    SubscriptionBundle,
} from '../../interfaces/subscription';
import { Button, Table, message } from 'antd';
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import translate, { LanguageKey } from '../../translate/translate';
import dayjs from 'dayjs';
import {
    getOrderProductCommitmentPeriod,
    getSubscriptionCommitmentPeriod,
} from '../../utils/subscriptionUtils';
import { OrderProduct } from '../../interfaces/order';

interface OrderProductWithSubscription extends OrderProduct {
    subscription: Subscription;
}

type Props = {
    subscriptionBundle: SubscriptionBundle;
    selectedAddons: string[];
    onClose: (
        changes: {
            uuid: string;
            resign_date?: string;
            active: boolean;
        }[]
    ) => void;
    language: LanguageKey;
};

export default function CancelAddonsPublic({
    subscriptionBundle,
    selectedAddons,
    onClose,
    language,
}: Props) {
    let defaultSelectedRowKeys = selectedAddons || [];
    if (
        defaultSelectedRowKeys.length === 0 &&
        subscriptionBundle.subscriptions.length === 1
    ) {
        defaultSelectedRowKeys = [subscriptionBundle.subscriptions[0].uuid];
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
        defaultSelectedRowKeys
    );
    const [cancelReason, setCancelReason] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const addOns: OrderProductWithSubscription[] = [];

    subscriptionBundle.subscriptions.forEach((s) => {
        return (
            s.add_ons &&
            s.add_ons.forEach((sa) => {
                addOns.push({
                    ...sa,
                    subscription: s as Subscription,
                });
            })
        );
    });

    return (
        <div>
            <Table
                pagination={false}
                dataSource={addOns}
                rowKey={(addOn: OrderProductWithSubscription) => {
                    return addOn.subscription.uuid + '__' + addOn.product.uuid;
                }}
                columns={[
                    {
                        title: translate('add_on', language),
                        render: (addOn: OrderProductWithSubscription) => {
                            console.log(addOn);
                            return addOn.product.title;
                        },
                    },
                    {
                        title: 'Uppsagnafrestur',
                        render: (addOn: OrderProductWithSubscription) => {
                            const notice = addOn.product.cancel_notice || 0;

                            let upcomingDates =
                                subscriptionBundle.upcomingDates || [];

                            if (upcomingDates.length > notice) {
                                upcomingDates = upcomingDates.slice(0, notice);
                            }

                            const {
                                commitmentEndDate,
                                commitmentHasEnded,
                                finalNoticeAndCommitmentDate,
                                freeToCancel,
                            } = getOrderProductCommitmentPeriod(addOn);

                            const {
                                commitment_period,
                                commitment_period_type,
                            } = addOn.product;

                            return (
                                <div>
                                    <div>
                                        {notice > 0 && (
                                            <>
                                                <div>
                                                    <small>
                                                        {translate(
                                                            'cancel_notice',
                                                            language
                                                        )}{' '}
                                                        <strong>
                                                            {' '}
                                                            {notice.toString() +
                                                                ' ' +
                                                                translate(
                                                                    'times',
                                                                    language
                                                                )}
                                                        </strong>
                                                    </small>
                                                </div>
                                            </>
                                        )}

                                        {commitmentEndDate &&
                                            !commitmentHasEnded && (
                                                <div>
                                                    <small>
                                                        {translate(
                                                            'commitment_period',
                                                            language
                                                        )}
                                                        :{' '}
                                                        <strong>
                                                            {commitment_period}{' '}
                                                            {translate(
                                                                commitment_period_type as
                                                                    | 'MONTH'
                                                                    | 'WEEK'
                                                                    | 'DAY'
                                                                    | 'YEAR',
                                                                language
                                                            )}
                                                        </strong>
                                                    </small>
                                                </div>
                                            )}

                                        {!freeToCancel &&
                                            finalNoticeAndCommitmentDate && (
                                                <small>
                                                    {translate(
                                                        'subscription_will_end',
                                                        language
                                                    )}{' '}
                                                    <strong>
                                                        {finalNoticeAndCommitmentDate.format(
                                                            'D. MMM YYYY'
                                                        )}
                                                    </strong>
                                                </small>
                                            )}

                                        {notice === 0 && commitmentHasEnded && (
                                            <>
                                                {translate(
                                                    'no_cancel_notice',
                                                    language
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {/* {notice > 0 &&
                                        subscriptionBundle.upcomingDates && (
                                            <div>
                                                {upcomingDates.map((d, key) => (
                                                    <small
                                                        className="bundleMandatoryDate"
                                                        key={key}
                                                    >
                                                        {d.format(
                                                            'D. MMM YYYY'
                                                        )}
                                                    </small>
                                                ))}
                                            </div>
                                        )} */}
                                </div>
                            );
                        },
                    },
                ]}
                showHeader={false}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                        setSelectedRowKeys(selectedRowKeys as string[]);
                    },
                }}
            ></Table>
            <br />

            <label style={{ paddingBottom: 10, display: 'block' }}>
                {translate('cancel_reason', language)}
            </label>
            <TextArea
                placeholder={translate('cancel_reason', language).toString()}
                value={cancelReason}
                onChange={(e) => {
                    setCancelReason(e.target.value);
                }}
                rows={4}
            ></TextArea>
            <br />
            <br />

            <Button
                loading={loading}
                onClick={() => {
                    if (!cancelReason) {
                        message.error(
                            translate('cancel_reason_required', language)
                        );
                        return;
                    }

                    if (selectedRowKeys.length === 0) {
                        message.error(
                            translate('please_select_at_least_one', language)
                        );
                        return;
                    }

                    setLoading(true);
                    axios
                        .post(`/askrift/api/cancel_addons_public/`, {
                            uuids: selectedRowKeys,
                            cancel_reason: cancelReason,
                        })
                        .then((rsp) => {
                            if (rsp.data.success) {
                                message.success(translate('success', language));

                                // rsp.data.changes = {
                                //     "uuid": subscription.uuid,
                                //     "resign_date": subscription.resign_date,
                                //     "active": subscription.active,
                                // }

                                onClose(rsp.data.changes);
                            }
                        });
                }}
                block
                type="primary"
                size="large"
            >
                {translate('cancel', language)}
            </Button>
        </div>
    );
}
