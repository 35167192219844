import { useState } from 'react';
import Product, {
    Currency,
    RepeatCheckoutProduct,
} from '../../interfaces/product';
import { PublicSubscription } from '../../interfaces/subscription';
import { Alert, Button, Card, Form, Image, message, Modal, Table } from 'antd';
import translate, { LanguageKey } from '../../translate/translate';
import ProductAddonsTable from '../RepeatCheckout/ProductAddonsTable/ProductAddonsTable';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { displayCurrencyPrice } from '../../utils';
import axios from 'axios';
import { SimpleCustomer } from '../../interfaces/customer';
import CustomFormItems from '../RepeatCheckout/CustomFormItems';
type Props = {
    products: Product[];
    subscriptions: PublicSubscription[];
    language: LanguageKey;
    currency: Currency['currency_code'];
    customer: SimpleCustomer | undefined;
};

export default function AddOns({
    products,
    subscriptions,
    language,
    currency,
    customer,
}: Props) {
    const [addonsModalVisible, setAddonsModalVisible] = useState(false);
    const subscriptionProductUUIDs = subscriptions.map((s) => s.product.uuid);
    const [selectedAddons, setSelectedAddons] = useState<
        RepeatCheckoutProduct['details'][]
    >([]);
    const [loading, setLoading] = useState(false);

    let addOnProducts = products
        .filter((product) => product.type === 'ADD_ON')
        .filter(
            (addonProduct) =>
                addonProduct.add_on_available_for?.some((uuid) =>
                    subscriptionProductUUIDs.includes(uuid)
                ) &&
                addonProduct.add_on_available_at?.some(
                    (place) => place === 'MY_SUBSCRIPTION_PAGE'
                )
        );

    // Flat list of subscription.addOns to hide if subscriptions already have the add-on
    const subscriptionAddOns = subscriptions.flatMap((s) => s.add_ons || []);

    addOnProducts = addOnProducts.filter((addOnProduct) =>
        // Show addon if ANY subscription is missing it
        subscriptions.some(
            (subscription) =>
                !(subscription.add_ons || []).some(
                    (subscriptionAddOn) =>
                        subscriptionAddOn.product.uuid === addOnProduct.uuid
                )
        )
    );

    const addToSubscription = (
        addons: RepeatCheckoutProduct['details'][],
        values: any
    ) => {
        const addOns = addons.map((addon) => {
            return {
                uuid: addon.uuid,
                quantity: 1,
            };
        });

        axios
            .post('/askrift/api/buy_addons_public/', {
                subscriptions: subscriptions.map((s) => s.uuid),
                addons: addOns,
                ...values,
            })
            .then((res) => {
                message.success(translate('success', language));
                window.location.reload();
            })
            .catch((err) => {
                message.error(translate('error_came_up', language));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return addOnProducts.length > 0 ? (
        <Card
            title={translate('add_ons', language)}
            bodyStyle={{
                padding: 0,
            }}
            style={{
                overflow: 'hidden',
            }}
            extra={
                <Button
                    size="small"
                    onClick={() => {
                        setAddonsModalVisible(true);
                    }}
                >
                    <SearchOutlined />
                    {translate('details', language)}
                </Button>
            }
        >
            <img
                src={
                    customer?.shop.add_on_cover_image?.image ||
                    '/static/images/addons.png'
                }
                alt="Addons"
                style={{
                    width: '100%',
                }}
            />
            {/* <div
                style={{
                    background: `url(${customer?.shop.add_on_cover_image?.image || '/static/images/addons.png'})`,
                    backgroundSize: '100%',
                    width: '100%',
                    paddingBottom: '56%',
                }}
            ></div> */}

            <Modal
                open={addonsModalVisible}
                onCancel={() => {
                    setAddonsModalVisible(false);
                    setSelectedAddons([]);
                }}
                footer={null}
                width={1000}
                className="editCustomerPublicModal"
            >
                <div id="editCustomerPublicBuyAddonsModal">
                    <div style={{ flex: 1 }}>
                        <h3>{translate('add_ons', language)}</h3>
                        <ProductAddonsTable
                            addOns={addOnProducts.filter(
                                (p) =>
                                    !selectedAddons.some(
                                        (a) => a.uuid === p.uuid
                                    )
                            )}
                            selectedLanguage={language}
                            currency={currency}
                            onAddonSelected={(addon) => {
                                console.log(addon);
                                setSelectedAddons([...selectedAddons, addon]);
                            }}
                        ></ProductAddonsTable>
                    </div>
                    <div id="yourAddonOrder">
                        <h3>{translate('your_order', language)}</h3>
                        <Table
                            className="white_table"
                            // size="small"
                            pagination={false}
                            style={{
                                width: '100%',
                            }}
                            dataSource={selectedAddons}
                            columns={[
                                {
                                    title: translate('products', language),
                                    dataIndex: 'title',
                                    key: 'title',
                                },
                                {
                                    title: translate('price', language),
                                    key: 'price',
                                    render: (_, record) => {
                                        return displayCurrencyPrice(
                                            false,
                                            currency,
                                            record
                                        );
                                    },
                                },
                                {
                                    title: '',
                                    key: 'remove',
                                    align: 'right',
                                    render: (_, record) => {
                                        return (
                                            <Button
                                                size="small"
                                                type="dashed"
                                                onClick={() => {
                                                    setSelectedAddons(
                                                        selectedAddons.filter(
                                                            (a) =>
                                                                a.uuid !==
                                                                record.uuid
                                                        )
                                                    );
                                                }}
                                            >
                                                X
                                            </Button>
                                        );
                                    },
                                },
                            ]}
                            expandable={{
                                expandedRowClassName: () => 'expandedAddonRow',
                                expandedRowRender: (addon) => {
                                    return (
                                        <small
                                            style={{
                                                color: '#ff9800',
                                                textAlign: 'center',
                                                display: 'block',
                                            }}
                                        >
                                            *{' '}
                                            {addon.transaction_count_limit &&
                                                addon.transaction_count_limit ===
                                                    1 && (
                                                    <>
                                                        {translate(
                                                            'addon_one_time_description',
                                                            language
                                                        )}
                                                    </>
                                                )}
                                            {addon.transaction_count_limit &&
                                                addon.transaction_count_limit >
                                                    1 && (
                                                    <>
                                                        {translate(
                                                            'addon_multiple_times_description',
                                                            language
                                                        )}{' '}
                                                        {
                                                            addon.transaction_count_limit
                                                        }{' '}
                                                        {translate(
                                                            'times',
                                                            language
                                                        )}
                                                    </>
                                                )}
                                            {!addon.transaction_count_limit &&
                                                translate(
                                                    'addon_unlimited_description',
                                                    language
                                                )}
                                        </small>
                                    );
                                },
                                showExpandColumn: false,
                                expandedRowKeys: selectedAddons.map(
                                    (a) => a.uuid
                                ),
                            }}
                            rowKey={(record) => record.uuid}
                            footer={() => {
                                const getPrice = (
                                    product: RepeatCheckoutProduct['details']
                                ) => {
                                    let _amount = 0;
                                    const currencyAmount = product.prices.find(
                                        (p) =>
                                            p.currency.currency_code ===
                                            currency
                                    )?.amount;

                                    if (currencyAmount) {
                                        _amount = currencyAmount;
                                    }

                                    return _amount;
                                };

                                return (
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                {translate('total', language)}:{' '}
                                            </div>
                                            <div>
                                                +{' '}
                                                {displayCurrencyPrice(
                                                    selectedAddons.reduce(
                                                        (acc, addon) =>
                                                            acc +
                                                            getPrice(addon),
                                                        0
                                                    ),
                                                    currency
                                                )}
                                            </div>
                                        </div>

                                        <small>
                                            *{' '}
                                            {translate(
                                                'not_charged_now',
                                                language
                                            )}
                                        </small>
                                    </div>
                                );
                            }}
                        />

                        {selectedAddons.length > 0 && (
                            <Form
                                style={{ marginTop: 20 }}
                                onFinish={(values) => {
                                    setLoading(true);
                                    addToSubscription(selectedAddons, values);
                                }}
                            >
                                <div>
                                    <CustomFormItems
                                        style={{
                                            padding: 10,
                                            borderTop: '1px solid #f0f0f0',
                                        }}
                                        parentKey="custom_data"
                                        products={selectedAddons}
                                        selectedLanguage={language}
                                    ></CustomFormItems>
                                </div>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    block
                                    size="large"
                                >
                                    {translate('add_to_subscription', language)}
                                </Button>
                            </Form>
                        )}
                    </div>
                </div>
            </Modal>
        </Card>
    ) : (
        <></>
    );
}
