import { useEffect, useState } from 'react';
import { IncomingRequestLog } from '../../interfaces/requestLog';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import { Alert, Spin, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LogOverview from '../LogOverview/LogOverview';

type Props = {
    log: IncomingRequestLog;
};

// export interface IncomingRequestLog {
//     id: number;
//     created: string;
//     type: string;
//     headers: JSON;
//     service: string;
//     success: boolean;
//     ip_address: string;
//     user_agent: string;
//     referrer: string;
//     host: string;
//     path: string;
//     query_string: string;
//     payload: JSON;
//     data: JSON;
//     message: string;
//     our_response: JSON;
//     error: string;
//     traceback: string;
//     api_key: number;
//     shop: number;
//     external_request_logs: number[];
//     subscriptions: number[];
//     customers: number[];
//     subscription_transactions: number[];
//     orders: number[];
//     method: string;
//     status_code: number;
// }

export default function IncomingLogDetails(props: Props) {
    const [log, setLog] = useState<IncomingRequestLog>();

    useEffect(() => {
        axios
            .get('/api/incoming_request_log_details/' + props.log.id + '/')
            .then((rsp) => setLog(rsp.data));

        return () => {
            setLog(undefined);
        };
    }, [props.log]);

    return log ? (
        <Tabs
            defaultActiveKey="1"
            type="card"
            size={'large'}
            items={[
                {
                    label: 'Payload',
                    key: 'payload',
                    children: [
                        <div
                            style={{
                                margin: '20px 0px',
                                fontSize: '14px',
                                backgroundColor: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '5px',
                            }}
                        >
                            {log.message}
                        </div>,
                        <div
                            style={{
                                fontSize: '18px',
                                marginBottom: '10px',
                            }}
                        >
                            Method: {log.method}
                        </div>,

                        (SyntaxHighlighter as any)({
                            language: 'json',
                            style: dracula,
                            showLineNumbers: false,
                            wrapLines: true,
                            wrapLongLines: true,
                            children: JSON.stringify(log.path, null, 2),
                        }),
                        <div>
                            <h3>Incoming payload</h3>
                        </div>,
                        (SyntaxHighlighter as any)({
                            language: 'json',
                            style: dracula,
                            showLineNumbers: true,
                            wrapLines: true,
                            wrapLongLines: true,
                            children: JSON.stringify(
                                log.incoming_payload,
                                null,
                                2
                            ),
                        }),
                    ],
                },
                {
                    label: 'Okkar svar',
                    key: 'response',
                    children: [
                        <div
                            style={{
                                fontSize: '18px',
                                marginBottom: '10px',
                            }}
                        >
                            Status code: {log.status_code}
                        </div>,

                        (SyntaxHighlighter as any)({
                            language: 'json',
                            style: dracula,
                            showLineNumbers: true,
                            wrapLines: true,
                            wrapLongLines: true,
                            children: JSON.stringify(log.our_response, null, 2),
                        }),
                    ],
                },
                {
                    label: 'Önnur köll tengd þjónustu',
                    key: 'external_request_logs',
                    children: [
                        <div>
                            <LogOverview
                                admin={true}
                                ids={log.external_request_logs.map((log) =>
                                    log.toString()
                                )}
                            />
                        </div>,
                    ],
                },
            ]}
        />
    ) : (
        <div>
            <h2>Sæki gögn</h2>
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
        </div>
    );
}
