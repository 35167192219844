import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import SVG from 'react-inlinesvg';

import ThreeDSecureAPIModal from '../../components/ThreeDSecureAPIModal/ThreeDSecureAPIModal';
import translate from '../../translate/translate';
import './GetCardTokenIframe.less';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import axios from 'axios';

type Props = {};

export interface IWindow extends Window {
    uuid?: string;
    payment_provider?: 'SALT_PAY' | 'VALITOR' | 'STRAUMUR';
    language?: 'is' | 'en';
    currency?: string;
    use_three_d_secure?: boolean;
    ReactNativeWebView?: {
        postMessage: (data: string) => void;
    };
}

const w: IWindow = window;

const sendWindowPostMessage = (data: any) => {
    try {
        w.opener.postMessage(data, document.referrer);
        w.close();
    } catch (e) {
        try {
            w.ReactNativeWebView?.postMessage(JSON.stringify(data));
        } catch (e) {
            console.error(e);
        }
    }
};

export default function GetCardTokenIframe({}: Props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState<'is' | 'en'>(w.language || 'is');
    const [cardData, setCardData] = useState<{
        cardNumber: string;
        cvc: string;
        month: string;
        year: string;
    }>();

    return (
        <div id="getCardTokenIframe">
            <header>
                <div
                    style={{
                        textAlign: 'center',
                        margin: '20px',
                    }}
                >
                    <a href="/" target="_blank">
                        <SVG
                            style={{ width: '200px' }}
                            className="repeatLogo"
                            baseURL="/"
                            cacheRequests={false}
                            description="Teya"
                            preProcessor={(code) => {
                                code = code.replace(
                                    /<path/g,
                                    '<path fill="#000"'
                                );
                                return code;
                            }}
                            src="/static/images/logo/logo.svg"
                            title="Repeat logo"
                            key={'repeat_logo'}
                            uniqueHash={'repeat_logo'}
                            uniquifyIDs={true}
                        />
                        <h3>{translate('secure_repeat_gateway', language)}</h3>
                    </a>
                </div>

                <div className="text-center">
                    <SelectLanguage
                        defaultValue={w.language}
                        onChange={(val: 'is' | 'en') => setLanguage(val)}
                    ></SelectLanguage>
                </div>
            </header>
            <Form
                disabled={loading}
                size="large"
                layout="vertical"
                style={{ margin: 'auto', maxWidth: '400px', padding: 20 }}
                form={form}
                onFinish={(values) => {
                    setLoading(true);

                    if (
                        w?.payment_provider === 'SALT_PAY' &&
                        !w?.use_three_d_secure
                    ) {
                        axios
                            .post(
                                '/payment/api/save_card_as_token_from_gateway/',
                                {
                                    card_number: values.card_number,
                                    cvc: values.cvc,
                                    month: values.month,
                                    year: values.year,
                                    shop_uuid: w.uuid,
                                }
                            )
                            .then((rsp) => {
                                sendWindowPostMessage({
                                    repeatCardSaveSuccess: rsp.data.success,
                                    cardToken: rsp.data.card_token,
                                    ...rsp.data,
                                });

                                if (!rsp.data.success) {
                                    setLoading(false);
                                }
                            })
                            .catch((err) => {
                                setLoading(false);
                                sendWindowPostMessage({
                                    repeatCardSaveSuccess: false,
                                    ...err.response.data,
                                });
                            });
                    } else {
                        setCardData({
                            cardNumber: values.card_number,
                            cvc: values.cvc,
                            month: values.month,
                            year: values.year,
                        });
                    }
                }}
            >
                <Form.Item
                    name="card_number"
                    label={
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>{translate('card_number', language)}</div>

                            <div id="paymentOptions">
                                <img
                                    alt="logo"
                                    src="/static/images/credit_card_icons/mastercard.svg"
                                />
                                <img
                                    alt="logo"
                                    src="/static/images/credit_card_icons/maestro.svg"
                                />
                                <img
                                    alt="logo"
                                    src="/static/images/credit_card_icons/visa.svg"
                                />
                            </div>
                        </div>
                    }
                    rules={[
                        {
                            required: true,
                            message: translate(
                                'card_number_required',
                                language
                            ),
                        },
                    ]}
                >
                    <Input
                        type="number"
                        placeholder={translate(
                            'card_number',
                            language
                        ).toString()}
                        size="large"
                        autoComplete="cc-number"
                    />
                </Form.Item>

                <div className="cardInfo">
                    <Form.Item
                        name="month"
                        label={'MM'}
                        className="cardExtra"
                        rules={[
                            {
                                len: 2,
                                message: 'Þessi reitur á að innihalda 2 stafi',
                            },
                            {
                                required: true,
                                message: translate('field_required', language),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={'MM'}
                            maxLength={2}
                            autoComplete="cc-exp-month"
                        />
                    </Form.Item>
                    <Form.Item
                        name="year"
                        label={'YY'}
                        className="cardExtra"
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', language),
                            },
                            {
                                len: 2,
                                message: 'Þessi reitur á að innihalda 2 stafi',
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            maxLength={2}
                            placeholder={'YY'}
                            autoComplete="cc-exp-year"
                        />
                    </Form.Item>
                    <Form.Item
                        name="cvc"
                        label="CVC"
                        className="cardExtra"
                        rules={[
                            {
                                required: true,
                                message: translate('field_required', language),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            maxLength={4}
                            placeholder="CVC"
                            autoComplete="cc-csc"
                        />
                    </Form.Item>
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        block
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                    >
                        {translate('confirm', language)}
                    </Button>
                </Form.Item>
            </Form>

            {cardData && w.payment_provider && w.uuid && (
                <ThreeDSecureAPIModal
                    cardData={cardData}
                    shopUUID={w.uuid}
                    paymentProvider={w.payment_provider}
                    onSuccess={(rsp) => {
                        sendWindowPostMessage({
                            repeatCardSaveSuccess: true,
                            ...rsp,
                        });

                        // Close the window
                        window.close();
                    }}
                    onCancel={(rsp) => {
                        sendWindowPostMessage({
                            repeatCardSaveSuccess: false,
                            ...rsp,
                        });
                    }}
                    currencyCode={w.currency || 'ISK'}
                    language={language}
                ></ThreeDSecureAPIModal>
            )}
        </div>
    );
}
