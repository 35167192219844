import { useState } from 'react';
import { SimpleCustomer } from '../../interfaces/customer';
import { Currency } from '../../interfaces/product';
import translate, { LanguageKey } from '../../translate/translate';
import { Button, Form, message } from 'antd';
import Three3DSecureModal from '../RepeatCheckout/3DSecureModal/3DSecureModal';
import CreditCardFormFields from '../../components/CreditCardFormFields/CreditCardFormFields';
import axios from 'axios';
interface Props {
    onCancel: () => void;
    onSuccess: () => void;
    customer: SimpleCustomer;
    currencyCode: Currency['currency_code'];
    language: LanguageKey;
}

export default function UpdatePublicCreditCardNew({
    customer,
    onCancel,
    onSuccess,
    currencyCode,
    language,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [threeDSecureModal, setThreeDSecureModal] = useState<{
        cardNumber: string;
        cvc: string;
        month: string;
        year: string;
        customer?: SimpleCustomer;
        billingCountry?: string;
    }>();

    const [form] = Form.useForm();

    return (
        <div id="updateCreditCardContainer">
            <Form
                size="large"
                form={form}
                layout="vertical"
                onFinish={(values) => {
                    setLoading(true);

                    console.log(values);

                    if (
                        values.card_number &&
                        values.cvc &&
                        values.month &&
                        values.year
                    ) {
                        setThreeDSecureModal({
                            cardNumber: values.card_number,
                            cvc: values.cvc,
                            month: values.month,
                            year: values.year,
                        });
                    }
                }}
            >
                <CreditCardFormFields
                    language={language}
                    form={form}
                    paymentProvider={customer.shop.payment_provider}
                />

                <Form.Item>
                    <Button
                        loading={loading}
                        block
                        type="primary"
                        htmlType="submit"
                    >
                        {translate('confirm', language)}
                    </Button>
                </Form.Item>
            </Form>

            <div id="paymentOptionsContainer">
                <div id="paymentOptions">
                    <img
                        alt="logo"
                        src="/static/images/credit_card_icons/mastercard.svg"
                    />
                    <img
                        alt="logo"
                        src="/static/images/credit_card_icons/maestro.svg"
                    />
                    <img
                        alt="logo"
                        src="/static/images/credit_card_icons/visa.svg"
                    />
                    <img
                        alt="logo"
                        src="/static/images/credit_card_icons/unionpay.svg"
                    />
                    <img
                        alt="logo"
                        src="/static/images/credit_card_icons/jcb.svg"
                    />
                    <img
                        alt="logo"
                        src="/static/images/credit_card_icons/dinersclub.svg"
                    />
                    <img
                        alt="logo"
                        src="/static/images/credit_card_icons/discover.svg"
                    />
                </div>
            </div>

            {!!threeDSecureModal && (
                <Three3DSecureModal
                    onCancel={() => {
                        onCancel();
                        setThreeDSecureModal(undefined);
                    }}
                    shop={customer.shop}
                    customer={customer}
                    currencyCode={currencyCode}
                    onSuccess={(cardData) => {
                        axios
                            .post('/vista_kort/' + customer.shop.uuid + '/', {
                                card_number: threeDSecureModal.cardNumber,
                                cvc: threeDSecureModal.cvc,
                                month: threeDSecureModal.month,
                                year: threeDSecureModal.year,
                                currency: currencyCode,
                                customer_uuid: customer.uuid,
                                ...cardData,
                            })
                            .then((rsp) => {
                                message.success(
                                    translate('card_saved', language)
                                );
                                setLoading(false);
                                onSuccess();
                            })
                            .catch((err) => {
                                message.error(
                                    translate('error_came_up', language)
                                );
                                setLoading(false);
                            });
                    }}
                    {...threeDSecureModal}
                ></Three3DSecureModal>
            )}
        </div>
    );
}
