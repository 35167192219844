import { Form, Input, FormInstance, Select } from 'antd';
import translate, { LanguageKey } from '../../translate/translate';
import countries from '../../utils/countries';

type Props = {
    language: LanguageKey;
    form: FormInstance;
    paymentProvider: string;
};

export default function CreditCardFormFields({
    language,
    form,
    paymentProvider,
}: Props) {
    return (
        <div>
            <Form.Item
                name="card_number"
                label={translate('card_number', language)}
                rules={[
                    {
                        required: true,
                        message: translate('card_number_required', language),
                    },
                ]}
            >
                <Input
                    type="number"
                    placeholder={translate('card_number', language).toString()}
                    size="large"
                    autoComplete="cc-number"
                    onBlur={async (e) => {
                        const countrySelected =
                            form.getFieldValue('billing_country');
                        if (!countrySelected) {
                            const value = e.target.value;
                            if (value?.length > 8) {
                                const firstEight = value.slice(0, 8);
                                try {
                                    const response = await fetch(
                                        `/payment/api/get_bin_detail/${firstEight}/`
                                    );
                                    const binData = await response.json();
                                    if (binData.country_alpha2) {
                                        form.setFieldValue(
                                            'billing_country',
                                            binData.country_alpha2
                                        );
                                    }
                                } catch (error) {
                                    console.error(
                                        'Error fetching BIN data:',
                                        error
                                    );
                                }
                            }
                        }
                    }}
                />
            </Form.Item>

            <div className="cardInfoFormFields">
                <Form.Item
                    name="month"
                    label={'MM'}
                    className="cardExtra"
                    rules={[
                        {
                            len: 2,
                            message: 'Þessi reitur á að innihalda 2 stafi',
                        },
                        {
                            required: true,
                            message: translate('field_required', language),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={'MM'}
                        maxLength={2}
                        autoComplete="cc-exp-month"
                    />
                </Form.Item>
                <Form.Item
                    name="year"
                    label={'YY'}
                    className="cardExtra"
                    rules={[
                        {
                            required: true,
                            message: translate('field_required', language),
                        },
                        {
                            len: 2,
                            message: 'Þessi reitur á að innihalda 2 stafi',
                        },
                    ]}
                >
                    <Input
                        size="large"
                        maxLength={2}
                        placeholder={'YY'}
                        autoComplete="cc-exp-year"
                    />
                </Form.Item>
                <Form.Item
                    name="cvc"
                    label="CVC"
                    className="cardExtra"
                    rules={[
                        {
                            required: true,
                            message: translate('field_required', language),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        maxLength={4}
                        placeholder="CVC"
                        autoComplete="cc-csc"
                    />
                </Form.Item>
            </div>
            <div>
                <Form.Item
                    hidden={paymentProvider !== 'VERIFONE'}
                    name="billing_country"
                    label={translate('billing_country', language)}
                    rules={[
                        {
                            required: paymentProvider !== 'VERIFONE',
                            message: translate('field_required', language),
                        },
                    ]}
                >
                    <Select
                        allowClear
                        placeholder={translate('billing_country', language)}
                        showSearch
                        options={countries}
                        style={{
                            width: '100%',
                        }}
                        {...({
                            autoComplete: 'new-password',
                        } as any)}
                        filterOption={(input, option) => {
                            return option?.label
                                ?.toString()
                                .toLowerCase()
                                .includes(input.toLowerCase());
                        }}
                    ></Select>
                </Form.Item>
            </div>
        </div>
    );
}
