import { Button, Card, Dropdown, Input, List, Modal, Space } from 'antd';
import { useEffect, useState, useContext, useCallback } from 'react';
import Product from '../../interfaces/product';
import AuthContext from '../../authContext';
import axios from 'axios';
import { debounce } from 'lodash';
import {
    SearchOutlined,
    LoadingOutlined,
    CodeOutlined,
    CaretDownOutlined,
    FileExcelOutlined,
} from '@ant-design/icons';
import AdminProductPreview from '../../components/AdminProductPreview/AdminProductPreview';
import '../../components/ProductOverview/productOverview.less';
import BuildProductPicker from '../../components/BuildProductPicker/BuildProductPicker';
import ExcelModal from '../../components/ExcelModal/ExcelModal';

let pageSize = 25;

export default function ProductOverview() {
    const { selectedShop } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState<Product[]>();
    const [searchTerm, setSearchTerm] = useState('');
    const [waitingForHandler, setwaitingForHandler] = useState(false);
    const [pagination, setPagination] = useState<{
        count: number;
        next: string;
        next_page_number: number;
        previous: string;
        previous_page_number: number;
        total_pages: number;
        results: Product[];
    }>();
    const [totalProducts, setTotalProducts] = useState<number>();
    const [buildProductModal, setBuildProductModal] = useState<boolean>(false);
    const [excelModalOpen, setExcelModalOpen] = useState<boolean>(false);
    useEffect(() => {
        if (selectedShop) {
            getProducts();
        }
    }, [searchTerm, selectedShop]);

    const searchProducts = (term: string) => {
        setSearchTerm(term);
    };

    const getProducts = (page?: number) => {
        if (!selectedShop) {
            return;
        }
        let url =
            '/verslun/api/admin_product/?shop__uuid=' +
            selectedShop.uuid +
            '&ordering=-created';

        if (page) {
            url += '&page=' + page;
        }

        if (searchTerm) {
            url += '&search=' + searchTerm;
            pageSize = 100;
        }

        url += '&page_size=' + pageSize;
        axios.get(url).then((rsp) => {
            if (!totalProducts) {
                setTotalProducts(rsp.data.count);
            }

            setProducts(rsp.data.results);
            setLoading(false);
            setPagination(rsp.data);
            setwaitingForHandler(false);
        });
    };

    const handler = useCallback(debounce(searchProducts, 400), []);

    return (
        <>
            <Card
                id="adminProductOverviewContainer"
                className="basePageCard"
                extra={
                    selectedShop && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 10,
                            }}
                        >
                            <Button
                                href={'/ny_vara/' + selectedShop.uuid}
                                type="dashed"
                            >
                                +
                            </Button>
                        </div>
                    )
                }
                title={
                    <>
                        <>
                            <img
                                style={{ height: 35, marginRight: 0 }}
                                src="/static/images/svg/tag.svg"
                                alt=""
                            />{' '}
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            key: '1',
                                            label: (
                                                <>
                                                    <CodeOutlined /> Fá kóða
                                                </>
                                            ),
                                            onClick: () => {
                                                setBuildProductModal(true);
                                            },
                                        },
                                        {
                                            key: '2',
                                            label: (
                                                <>
                                                    <FileExcelOutlined /> Excel
                                                </>
                                            ),
                                            onClick: () => {
                                                setExcelModalOpen(true);
                                            },
                                        },
                                    ],
                                }}
                            >
                                <Button type="text">
                                    <h3>
                                        Vörur <CaretDownOutlined />
                                    </h3>
                                </Button>
                            </Dropdown>
                        </>
                    </>
                }
            >
                {selectedShop && (
                    <>
                        {(totalProducts || 0) > 5 && (
                            <Input
                                size="large"
                                style={{
                                    width: '100%',
                                    borderRadius: 10,
                                    marginBottom: 10,
                                }}
                                allowClear
                                placeholder="Leita að vöru"
                                prefix={
                                    waitingForHandler ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <SearchOutlined />
                                    )
                                }
                                onChange={(e) => {
                                    const term = e.currentTarget.value;
                                    setwaitingForHandler(true);

                                    if (term && term.length > 1) {
                                        handler(term);
                                    } else {
                                        setProducts(pagination?.results || []);
                                        setSearchTerm('');
                                        setTimeout(() => {
                                            setwaitingForHandler(false);
                                        }, 500);
                                    }
                                }}
                            />
                        )}
                        <Card
                            loading={loading}
                            headStyle={{
                                display: 'none',
                            }}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={products || []}
                                locale={{
                                    emptyText: (
                                        <Space direction="vertical">
                                            {!searchTerm &&
                                                !waitingForHandler && (
                                                    <div>
                                                        <h2>
                                                            Stofnaðu fyrstu
                                                            vöruna þína
                                                            <span
                                                                role="img"
                                                                aria-label="emoji"
                                                            >
                                                                🚀
                                                            </span>
                                                        </h2>
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            block
                                                        >
                                                            <a
                                                                href={
                                                                    '/ny_vara/' +
                                                                    selectedShop.uuid
                                                                }
                                                            >
                                                                + Stofna vöru
                                                            </a>
                                                        </Button>
                                                    </div>
                                                )}

                                            {(searchTerm ||
                                                waitingForHandler) && (
                                                <div>
                                                    <h3>
                                                        Engar vörur fundust með
                                                        leitarorðinu:
                                                    </h3>
                                                    <div>
                                                        <h3>
                                                            <b>{searchTerm}</b>
                                                        </h3>
                                                    </div>
                                                </div>
                                            )}
                                        </Space>
                                    ),
                                }}
                                renderItem={(product) => (
                                    <AdminProductPreview
                                        product={product}
                                        shop={selectedShop}
                                        lastItem={
                                            products?.indexOf(product) ===
                                            (products?.length || 0) - 1
                                        }
                                    />
                                )}
                                pagination={
                                    pagination &&
                                    !searchTerm &&
                                    pagination.count > pageSize
                                        ? {
                                              pageSize: pageSize,
                                              total: pagination.count,
                                              showSizeChanger: false,
                                              size: 'small',
                                              onChange: (page) => {
                                                  getProducts(page);
                                              },
                                          }
                                        : false
                                }
                            />
                        </Card>
                    </>
                )}
            </Card>

            {buildProductModal && (
                <Modal
                    width={1400}
                    open={buildProductModal}
                    footer={null}
                    onCancel={() => {
                        setBuildProductModal(false);
                    }}
                >
                    <BuildProductPicker></BuildProductPicker>
                </Modal>
            )}

            <ExcelModal
                open={excelModalOpen}
                onClose={() => {
                    setExcelModalOpen(false);
                }}
                productFilterPayload={{
                    shop_uuid: selectedShop?.uuid,
                }}
            ></ExcelModal>
        </>
    );
}
