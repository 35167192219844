import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Popover,
    Table,
    Button,
    Layout,
    Card,
    Menu,
    Dropdown,
    Badge,
    message,
    Checkbox,
    Avatar,
    Tooltip,
} from 'antd';
import {
    CaretDownOutlined,
    FileExcelOutlined,
    DownOutlined,
    MailOutlined,
    QuestionCircleTwoTone,
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    EnvironmentOutlined,
    CreditCardOutlined,
    BankOutlined,
    QuestionCircleOutlined,
    WarningFilled,
} from '@ant-design/icons';
import CreateTransactionsModal from './CreateTransactionsModal/CreateTransactionsModal';
import CreateSubscriptionModal from './CreateSubscriptionModal/CreateSubscriptionModal';
import EmailModal from '../../components/EmailModal/EmailModal';
import './SubscriptionOverview.less';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import AuthContext from '../../authContext';

/* Interfaces */
import Subscription, {
    SubscriptionFilter,
    SubscriptionFilterPayload,
} from '../../interfaces/subscription';
import Product from '../../interfaces/product';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import {
    getNextTransactionDate,
    getSubscriptionPaymentString,
} from '../../utils/subscriptionUtils';
import { displayCurrencyPrice, getCronLabel } from '../../utils';
import brandingConfig from '../../utils/brandingConfig';
import ChangeSubscriptionsModal from './ChangeSubscriptionsModal/ChangeSubscriptionsModal';
import SubscriptionDeliveryOptionModal from './SubscriptionDeliveryOptionModal/SubscriptionDeliveryOptionModal';
import ExcelModal from '../../components/ExcelModal/ExcelModal';
import SelectedAttributeInfo from '../../components/SelectedAttributeInfo/SelectedAttributeInfo';
dayjs.locale('is'); // use locale

interface IWindow extends Window {
    uuid?: string;
}

const w: IWindow = window;
const { Content } = Layout;
const pageSize = 100;

export default function SubscriptionOverview() {
    const { selectedShop } = useContext(AuthContext);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [products, setProducts] = useState<Product[]>([]);
    const [createTransactionsModal, setCreateTransactionsModal] =
        useState<boolean>(false);
    const [createSubscriptionModal, setCreateSubscriptionModal] =
        useState<boolean>(false);
    const [emailModal, setEmailModal] = useState(false);
    const [changeSubscriptionsModal, setChangeSubscriptionsModal] =
        useState(false);
    const [subscriptionCount, setSubscriptionCount] = useState<
        number | undefined
    >();
    const [selectedCount, setSelectedCount] = useState(0);
    const [
        subscriptionDeliveryOptionModal,
        setSubscriptionDeliveryOptionModal,
    ] = useState<Subscription | false>(false);
    const [availableFrequencies, setAvailableFrequencies] = useState<
        {
            interval: number;
            interval_type: 'MONTH' | 'WEEK' | 'DAY' | 'YEAR';
        }[]
    >([]);
    const [filterPayload, setFilterPayload] =
        useState<SubscriptionFilterPayload>();

    const [excelModal, setExcelModal] = useState(false);

    const [filters, setFilters] = useState<SubscriptionFilter>({
        activeChecked: true,
        inactiveChecked: false,
        active: 'true',
        ordering: '-created',
        product__in: '',
        payment_method__in: '',
        frequencies: [],
    });

    const [allSelected, setAllSelected] = useState<boolean>(false);

    /* UUID listi af subscriptions */
    /* Þessi listi inniheldur excluded uuids EF allSelected er true
    annars inniheldur hann uuids fyrir þá sem eru selected */
    const [selectionList, setSelectionList] = useState<string[]>([]);
    const [paymentFilters, setPaymentFilters] = useState([]);

    useEffect(() => {
        if (selectedShop) {
            axios
                .get(
                    '/verslun/api/product/?type=SUBSCRIPTION&shop__uuid=' +
                        selectedShop?.uuid
                )
                .then((rsp) => {
                    setProducts(rsp.data.results);
                });

            axios
                .post('/askrift/api/get_shop_frequenies/', {
                    shop_uuid: selectedShop?.uuid,
                })
                .then((rsp) => {
                    const data = rsp.data.data || [];
                    setAvailableFrequencies(data);
                });

            axios
                .get(
                    `/verslun/api/get_shop_payment_filters/${selectedShop.uuid}/`
                )
                .then((rsp) => {
                    const data = rsp.data;

                    setPaymentFilters(data);
                });
        }
    }, [selectedShop]);

    useEffect(() => {
        if (!selectedShop) return;

        setFilterPayload({
            shop: selectedShop.uuid,
            filters: filters,
            all_selected: allSelected,
            selection_list: selectionList,
            frequencies: filters.frequencies,
        });
    }, [filters, allSelected, selectionList, selectedShop]);

    useEffect(() => {
        setRowSelections();
    }, [subscriptions, selectionList]);

    useEffect(() => {
        setSelectedCount(
            allSelected
                ? (subscriptionCount || 0) - selectionList.length
                : selectionList.length
        );
    }, [allSelected, subscriptionCount, selectionList]);

    useEffect(() => {
        const activeList = [];
        setAllSelected(false);
        setSelectionList([]);

        if (filters.activeChecked) activeList.push('true');
        if (filters.inactiveChecked) activeList.push('false');
        const activeParam = activeList.join(',');

        if (activeParam !== filters.active) {
            setFilters({
                ...filters,
                active: activeParam,
            });
        } else {
            getSubscriptions();
        }
    }, [filters, selectedShop]);

    const buildParams = () => {
        const url = Object.keys(filters)
            .filter(
                (key) =>
                    key !== 'inactiveChecked' &&
                    key !== 'activeChecked' &&
                    key !== 'frequencies'
            )
            .map((key) => {
                // @ts-ignore
                return `${key}=${filters[key]}`;
            });

        if (filters.frequencies && filters.frequencies.length) {
            const filterQuery = filters.frequencies.map((obj) => {
                return obj.interval + '_' + obj.interval_type;
            });

            url.push('frequencies=' + filterQuery);
        }

        return url;
    };

    const getPage = (pageNumber: number | null, test: string) => {
        if (!selectedShop) {
            return Promise.resolve({
                data: {
                    count: 0,
                    results: [],
                },
            });
        }

        const params = buildParams();
        return axios.get(
            `/askrift/api/subscription_overview/?page_size=${pageSize}&product__shop__uuid=${
                selectedShop?.uuid
            }${pageNumber ? '&page=' + pageNumber : ''}&${params.join('&')}`
        );
    };

    const setRowSelections = () => {
        if (allSelected) {
            setSelectedRowKeys(
                subscriptions
                    .map((s: Subscription) => s.uuid)
                    .filter((uuid: string) => !selectionList.includes(uuid))
            );
        }

        if (!allSelected) {
            setSelectedRowKeys(selectionList);
        }
    };

    const getSubscriptions = (pageNumber: null | number = null) => {
        setLoading(true);

        getPage(pageNumber, 'from getSubscriptons').then((rsp) => {
            setSubscriptionCount(rsp.data.count);
            setSubscriptions(rsp.data.results);
            setRowSelections();
            setLoading(false);
        });
    };

    const createShipments = () => {
        if (selectedCount < 1) {
            message.error('Þú veðrur að velja lágmark eina áskrift');
            return;
        }

        setCreateTransactionsModal(true);
    };

    const onCheckboxChange = (e: CheckboxChangeEvent) => {
        setLoading(true);

        const name = e.target.name;
        const checked = e.target.checked;

        if (name === 'activeChecked') {
            setFilters({
                ...filters,
                activeChecked: checked,
            });
        } else if (name === 'inactiveChecked') {
            setFilters({
                ...filters,
                inactiveChecked: checked,
            });
        }
    };

    const makeSelectionChange = (rowKeys: string[], selected: boolean) => {
        /* Ef ALLT er valið og eitthvað var "deselectað" */
        if (allSelected && !selected) {
            /* Núna er selection list = excludedUUIDs */
            //@ts-ignore
            setSelectionList([...new Set([...selectionList, ...rowKeys])]);
        }

        /* Ef ALLT er valið og eitthvað var selectað */
        if (allSelected && selected) {
            /* Núna er selection list = excludedUUIDs */
            setSelectionList(
                selectionList.filter((uuid) => !rowKeys.includes(uuid))
            );
        }

        /* Ef það er ekki "hakað við" ALLT og eitthvað var selectað */
        if (!allSelected && selected) {
            /* Núna er selection list = Valin UUIDs */
            // @ts-ignore
            setSelectionList([...new Set([...selectionList, ...rowKeys])]);
        }

        /* Ef það er ekki "hakað við" ALLT og eitthvað var deselectað */
        if (!allSelected && !selected) {
            /* Núna er selection list = Valin UUIDs */
            setSelectionList(
                selectionList.filter((uuid) => !rowKeys.includes(uuid))
            );
        }
    };

    let columns = [
        {
            title: (
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    className="miniText"
                >
                    Nafn
                </div>
            ),

            render: (subscription: Subscription) => {
                return (
                    <div
                        style={{
                            maxWidth: 200,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {!subscription.is_paused && (
                            <Badge
                                //@ts-ignore
                                status={
                                    subscription.resign_date &&
                                    subscription.active
                                        ? 'yellow'
                                        : subscription.active
                                          ? 'success'
                                          : 'error'
                                }
                            />
                        )}{' '}
                        <a
                            className="capitalize"
                            style={{
                                color: 'rgba(0, 0, 0, 0.65)',
                            }}
                            href={`/vidskiptavinur/${subscription.customer.uuid}/`}
                        >
                            {subscription.customer?.name}
                        </a>
                        {subscription.is_paused && (
                            <div style={{ color: 'gray', fontSize: 11 }}>
                                <WarningFilled style={{ color: '#FFD23F' }} />{' '}
                                Þessi áskrift er í pásu
                            </div>
                        )}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    onClick: () => {
                        setFilters({
                            ...filters,
                            ordering:
                                filters.ordering === 'customer__name'
                                    ? '-customer__name'
                                    : 'customer__name',
                        });
                    },
                };
            },
        },
        {
            title: <div className="nowrap miniText">Tegund áskriftar</div>,
            key: 'tegund_askriftar',
            filters: products.map((product) => {
                return {
                    value: product.id,
                    text: product.title,
                };
            }),
            filterSearch: products.length > 10,
            render: (subscription: Subscription) => {
                return (
                    <>
                        <a
                            style={{
                                color: 'inherit',
                            }}
                            type="text"
                            href={`/breyta_voru/${subscription.product.uuid}/`}
                        >
                            {subscription.product.title}
                        </a>
                        {subscription.subscription_product?.attributes &&
                            subscription.subscription_product.attributes
                                .length > 0 && (
                                <div>
                                    {subscription.subscription_product.attributes.map(
                                        (attr, key) => {
                                            return (
                                                <SelectedAttributeInfo
                                                    key={
                                                        key +
                                                        attr.attribute.uuid
                                                    }
                                                    orderAttribute={attr}
                                                    attribute={attr.attribute}
                                                    hideLabel={true}
                                                    fontSize={10}
                                                    inlineBlock={true}
                                                ></SelectedAttributeInfo>
                                            );
                                        }
                                    )}
                                </div>
                            )}

                        {subscription.add_ons &&
                            subscription.add_ons.length > 0 && (
                                <div style={{ color: 'gray' }}>
                                    <small>
                                        +{' '}
                                        {subscription.add_ons
                                            .map((addOn) => {
                                                return addOn.product.title;
                                            })
                                            .join(', ')}
                                    </small>
                                </div>
                            )}
                    </>
                );
            },
        },

        {
            title: (
                <div className="nowrap miniText">
                    <Tooltip
                        title='Allar sendingar miðast út frá grunndagsetningu áskriftar ("Frá...")'
                        placement="top"
                    >
                        <QuestionCircleOutlined /> Tíðni
                    </Tooltip>
                </div>
            ),
            key: 'frequency',
            className: 'frequencyInfo',
            filters: availableFrequencies.map((obj) => {
                return {
                    value: obj.interval + '_' + obj.interval_type,
                    text: getCronLabel(obj.interval, obj.interval_type),
                };
            }),
            render: (subscription: Subscription) => {
                const cronSettings = subscription.subscription_product
                    .cron_transaction
                    ? subscription.subscription_product.cron_transaction
                    : subscription.product.cron_transaction;

                if (!cronSettings) {
                    return 'Ekki virkt';
                } else {
                    const fromDate = cronSettings
                        ? cronSettings.base_date_is_signup_date
                            ? subscription.created
                            : cronSettings.base_date
                        : '';

                    const fromDateIsFuture =
                        fromDate && dayjs(fromDate).isAfter(new Date());
                    return (
                        <div className="nowrap miniText">
                            <div>
                                {getCronLabel(
                                    cronSettings.interval || 1,
                                    cronSettings.interval_type,
                                    subscription.product
                                )}
                            </div>
                            <div
                                style={{
                                    color: 'gray',
                                    fontSize: 11,
                                }}
                            >
                                Frá {dayjs(fromDate).format('D. MMM YYYY')}
                            </div>
                        </div>
                    );
                }
            },
        },

        {
            title: (
                <div className="nowrap miniText">
                    <Tooltip
                        title="Dagsetningin sem áskriftin var stofnuð"
                        placement="top"
                    >
                        <QuestionCircleOutlined /> Stofnað
                    </Tooltip>
                </div>
            ),
            render: (subscription: Subscription) => (
                <div className="nowrap miniText">
                    <div>
                        {dayjs(subscription.created).format('D. MMM, YYYY')}
                    </div>
                </div>
            ),
            onHeaderCell: () => {
                return {
                    onClick: () => {
                        setFilters({
                            ...filters,
                            ordering:
                                filters.ordering === '-created'
                                    ? 'created'
                                    : '-created',
                        });
                    },
                };
            },
        },
        {
            title: (
                <div className="nowrap miniText">
                    <Tooltip
                        title="Segir til um hvenær nýjasta sendingin/rukkun átti sér stað"
                        placement="top"
                    >
                        <QuestionCircleOutlined /> Nýjast
                    </Tooltip>
                </div>
            ),
            render: (subscription: Subscription) => {
                return (
                    <div className="nowrap miniText">
                        {subscription.newest_transaction ? (
                            <>
                                <div>
                                    <div>
                                        {dayjs(
                                            subscription.newest_transaction
                                        ).format('D. MMM, YYYY')}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Tooltip
                                title={
                                    <>
                                        <p>
                                            Þessi tiltekna áskrift hefur aldrei
                                            fengið sendingu/verið rukkuð.
                                        </p>
                                        <p>
                                            Athugaðu að það þarf ekki það þýða
                                            að þessi viðskiptavinur hafi ekki
                                            fengið aðrar vörur sendar
                                        </p>
                                    </>
                                }
                                placement="top"
                            >
                                <div style={{ fontSize: 12, color: '#ff5967' }}>
                                    <QuestionCircleOutlined /> Ekkert fannst
                                </div>
                            </Tooltip>
                        )}
                    </div>
                );
            },
            /* onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        if (sort_by === 'newest_transaction') {
                            setSort_by('-newest_transaction');
                        } else {
                            setSort_by('newest_transaction');
                        }
                    },
                };
            }, */
        },

        {
            title: <div className="nowrap miniText">Magn</div>,
            render: (subscription: Subscription) => {
                return (
                    <div className="nowrap miniText">
                        {subscription.subscription_product.quantity || 1}
                    </div>
                );
            },
        },

        {
            title: <div className="nowrap miniText">Verð</div>,
            render: (subscription: Subscription) => {
                return (
                    <div className="nowrap miniText">
                        {displayCurrencyPrice(
                            subscription.subscription_product.price,
                            subscription.subscription_product.currency_code
                        )}
                    </div>
                );
            },
        },

        {
            title: (
                <div className="nowrap miniText">
                    <Tooltip title="Dagsetning næstu sendingar" placement="top">
                        <QuestionCircleOutlined /> Næst
                    </Tooltip>
                </div>
            ),
            render: (subscription: Subscription) => {
                const { isToday, isTomorrow, formattedDate } =
                    getNextTransactionDate(subscription);

                return (
                    <div className="nowrap miniText">
                        {formattedDate}

                        <div
                            style={{
                                color: brandingConfig.successColor,
                                fontSize: 11,
                            }}
                        >
                            {isToday && <>Í dag</>}
                            {isTomorrow && <>Á morgun</>}
                        </div>
                    </div>
                );
            },
        },
        {
            title: (
                <div className="nowrap miniText">
                    <Tooltip
                        title="Fleiri stillingar, eins og greiðslumáti, fjöldi sendinga og fleira"
                        placement="top"
                    >
                        <QuestionCircleOutlined /> Fleira
                    </Tooltip>
                </div>
            ),
            render: (subscription: Subscription) => {
                const paymentString =
                    getSubscriptionPaymentString(subscription);

                return (
                    <div className="text-right">
                        <Avatar.Group
                            maxStyle={{
                                color: '#000',
                                backgroundColor: '#000',
                            }}
                        >
                            {subscription.subscription_product
                                .delivery_option && (
                                <Tooltip
                                    title="Afhendingarmáti - Smelltu til að sjá nánar."
                                    placement="top"
                                >
                                    <Avatar
                                        onClick={() => {
                                            setSubscriptionDeliveryOptionModal(
                                                subscription
                                            );
                                        }}
                                        className="subscriptionOverviewBubble"
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        src={<EnvironmentOutlined />}
                                    />
                                </Tooltip>
                            )}

                            <Tooltip
                                title="Fjöldi sendinga sem viðkomandi hefur fengið"
                                placement="top"
                            >
                                <Avatar
                                    className="subscriptionOverviewBubble"
                                    src={
                                        <span>
                                            {subscription.transaction_count}
                                        </span>
                                    }
                                />
                            </Tooltip>

                            {paymentString === 'Kort' && (
                                <Tooltip title="Kort">
                                    <Avatar
                                        className="subscriptionOverviewBubble"
                                        src={<CreditCardOutlined />}
                                    />
                                </Tooltip>
                            )}

                            {paymentString === 'Krafa' && (
                                <Tooltip title="Krafa">
                                    <Avatar
                                        className="subscriptionOverviewBubble"
                                        src={<BankOutlined />}
                                    />
                                </Tooltip>
                            )}

                            {paymentString !== 'Krafa' &&
                                paymentString !== 'Kort' && (
                                    <Tooltip title={paymentString}>
                                        <Avatar
                                            className="subscriptionOverviewBubble"
                                            src={<BankOutlined />}
                                        />
                                    </Tooltip>
                                )}
                        </Avatar.Group>
                    </div>
                );
            },
            key: 'payment_method',
            filters: paymentFilters,
        },
        // {
        //     title: 'Verð',
        //     render: (subscription) => {
        //         return kr(
        //             subscription.product
        //                 .price
        //         );
        //     },
        // },
    ];

    if (!!!availableFrequencies.length) {
        columns = columns.filter((column) => column.key !== 'frequency');
    }

    return (
        <Card
            id="subscription_overview"
            title={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img
                        style={{ height: 35, marginRight: 10 }}
                        src="/static/images/svg/group.svg"
                        alt=""
                    />{' '}
                    Áskriftir ({subscriptionCount})
                </div>
            }
            className="basePageCard"
            extra={
                <>
                    <Button
                        onClick={() => {
                            setCreateSubscriptionModal(true);
                        }}
                        size="small"
                        icon={'+'}
                        style={{ marginRight: 10 }}
                    >
                        Bæta við
                    </Button>
                </>
            }
        >
            <Card
                style={{
                    overflow: 'hidden',
                    border: 'none',
                }}
                title={
                    <div>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        onClick={createShipments}
                                        icon={
                                            <img
                                                style={{
                                                    height: 15,
                                                }}
                                                src="/static/images/svg/box.svg"
                                                alt=""
                                            />
                                        }
                                    >
                                        {' '}
                                        Stofna sendingar
                                    </Menu.Item>

                                    <Menu.Item
                                        onClick={() => {
                                            const some_selected =
                                                !!selectedRowKeys.length;

                                            if (!some_selected) {
                                                message.error(
                                                    'Þú verður að velja lágmark eina sendingu.'
                                                );
                                                return;
                                            }

                                            setEmailModal(true);
                                        }}
                                        icon={<MailOutlined />}
                                    >
                                        Senda tölvupósta
                                    </Menu.Item>

                                    <Menu.Item
                                        onClick={() => {
                                            const some_selected =
                                                !!selectedRowKeys.length;

                                            if (!some_selected) {
                                                message.error(
                                                    'Þú verður að velja lágmark eina sendingu til að sækja Excel skjal'
                                                );
                                                return;
                                            }

                                            setExcelModal(true);
                                        }}
                                        icon={<FileExcelOutlined />}
                                        style={{
                                            color: '#22532e',
                                        }}
                                    >
                                        Sækja Excel skjal
                                    </Menu.Item>

                                    <Menu.Item
                                        onClick={() =>
                                            setChangeSubscriptionsModal(true)
                                        }
                                        icon={<EditOutlined></EditOutlined>}
                                    >
                                        Breyta áskriftum
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type="primary">
                                Aðgerðir <DownOutlined />
                            </Button>
                        </Dropdown>

                        <span
                            style={{
                                marginLeft: 8,
                                marginRight: 8,
                            }}
                        >
                            {allSelected && (
                                <>
                                    ALLAR síður valdar{' '}
                                    {!!selectionList.length && (
                                        <>
                                            að {selectionList.length} áskriftum
                                            undanskildum (alls:{' '}
                                            {(subscriptionCount || 0) -
                                                selectionList.length}
                                            )
                                        </>
                                    )}
                                </>
                            )}

                            {!allSelected && selectedRowKeys.length > 0
                                ? `${selectedRowKeys.length} ${
                                      selectedRowKeys.length > 1
                                          ? 'valdar áskriftir'
                                          : 'valin áskrift'
                                  }`
                                : ''}
                        </span>
                    </div>
                }
                extra={
                    <div>
                        <div
                            style={{
                                borderBottom: '1px solid #cacaca',
                                position: 'relative',
                                marginBottom: 5,
                            }}
                        >
                            <Popover
                                content={
                                    <div>
                                        <div>
                                            ATH að áskriftir AFHAKAST þegar þú
                                            breytir síum.
                                        </div>
                                        <div>
                                            Best er að sía fyrst og haka svo.
                                        </div>
                                    </div>
                                }
                            >
                                <QuestionCircleTwoTone
                                    twoToneColor="#afafaf"
                                    style={{
                                        position: 'absolute',
                                        fontSize: 16,
                                        top: 2,
                                    }}
                                />
                            </Popover>
                            <h4
                                style={{
                                    fontSize: '16px',
                                    position: 'relative',
                                    left: 20,
                                    marginBottom: 0,
                                }}
                            >
                                Síur
                            </h4>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div>
                                <div>
                                    <Checkbox
                                        disabled={loading}
                                        name="activeChecked"
                                        onChange={onCheckboxChange}
                                        checked={filters.activeChecked}
                                    />{' '}
                                    Virkar áskriftir
                                </div>

                                <div>
                                    <Checkbox
                                        disabled={loading}
                                        name="inactiveChecked"
                                        onChange={onCheckboxChange}
                                        checked={filters.inactiveChecked}
                                    />{' '}
                                    Óvirkar áskriftir
                                </div>
                            </div>
                        </div>
                    </div>
                }
            >
                <>
                    <Table
                        loading={loading}
                        className="white_table"
                        onChange={(pagination, filter, sorter, extra) => {
                            const action = extra.action;

                            if (action === 'filter') {
                                const frequencies = (
                                    filter.frequency || []
                                ).map((x) => {
                                    return {
                                        interval: parseInt(
                                            x.toString().split('_')[0]
                                        ),
                                        interval_type: x
                                            .toString()
                                            .split('_')[1] as
                                            | 'MONTH'
                                            | 'YEAR'
                                            | 'DAY'
                                            | 'WEEK',
                                    };
                                });

                                const productIn = filter.tegund_askriftar
                                    ? filter.tegund_askriftar.join(',')
                                    : '';

                                const paymentType = filter.payment_method
                                    ? filter.payment_method.join(',')
                                    : '';

                                setFilters({
                                    ...filters,
                                    product__in: productIn,
                                    //@ts-ignore
                                    payment_method__in: paymentType,
                                    frequencies,
                                });
                                setSelectedRowKeys([]);
                            }
                        }}
                        rowSelection={{
                            selectedRowKeys,
                            /* onChange: (selectedRowKeys: string[]) => {
                                                setSelectedRowKeys(selectedRowKeys);
                                            }, */

                            onSelectMultiple(
                                selected,
                                selectedRows,
                                changedRows
                            ) {
                                makeSelectionChange(
                                    changedRows.map((o) => o.uuid),
                                    selected
                                );
                            },
                            onSelect: (obj, selected) => {
                                makeSelectionChange([obj.uuid], selected);
                            },

                            hideSelectAll: true,
                            columnTitle: (
                                <>
                                    <Dropdown
                                        overlay={
                                            <Menu>
                                                <Menu.Item
                                                    onClick={() => {
                                                        setAllSelected(true);
                                                        setSelectionList([]);
                                                    }}
                                                >
                                                    <CheckOutlined
                                                        style={{
                                                            color: brandingConfig.successColor,
                                                        }}
                                                    />{' '}
                                                    Haka við allar síður
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={() => {
                                                        setAllSelected(false);
                                                        setSelectionList([]);
                                                    }}
                                                >
                                                    <CloseOutlined
                                                        style={{
                                                            color: brandingConfig.dangerColor,
                                                        }}
                                                    />{' '}
                                                    Afhaka allt
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        <Button size="small">
                                            Haka við <CaretDownOutlined />
                                        </Button>
                                    </Dropdown>
                                </>
                            ),
                        }}
                        pagination={{
                            hideOnSinglePage: true,
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            total: subscriptionCount,
                            onChange: (pageNumber) => {
                                if (pageNumber !== selectedPage) {
                                    setSelectedPage(pageNumber);
                                    setLoading(true);
                                    getPage(pageNumber, 'from pagination').then(
                                        (rsp) => {
                                            setSubscriptions(rsp.data.results);
                                            setSubscriptionCount(
                                                rsp.data.count
                                            );
                                            setLoading(false);
                                        }
                                    );
                                }
                            },
                        }}
                        locale={{
                            emptyText: (
                                <div>
                                    <h2>
                                        Ekkert fannst{' '}
                                        <span role="img" aria-label="emoji">
                                            🫙
                                        </span>
                                    </h2>
                                </div>
                            ),
                        }}
                        dataSource={subscriptions}
                        rowKey="uuid"
                        columns={columns}
                    />
                </>
            </Card>

            {createTransactionsModal && (
                <CreateTransactionsModal
                    open={!!createTransactionsModal}
                    selectedCount={selectedCount}
                    selectionList={selectionList}
                    allSelected={allSelected}
                    filters={filters}
                    shop_uuid={selectedShop?.uuid || ''}
                    title={
                        <>
                            <MailOutlined /> Stofna sendingar
                        </>
                    }
                    onCancel={() => {
                        setCreateTransactionsModal(false);
                    }}
                ></CreateTransactionsModal>
            )}

            {createSubscriptionModal && (
                <CreateSubscriptionModal
                    open={createSubscriptionModal}
                    onCancel={() => {
                        setCreateSubscriptionModal(false);
                    }}
                    shop_uuid={selectedShop?.uuid || ''}
                    onSuccess={(subscription: Subscription) => {
                        setSubscriptions([subscription, ...subscriptions]);
                        setCreateSubscriptionModal(false);
                    }}
                ></CreateSubscriptionModal>
            )}

            {changeSubscriptionsModal && filterPayload && (
                <ChangeSubscriptionsModal
                    onCancel={() => setChangeSubscriptionsModal(false)}
                    open={changeSubscriptionsModal}
                    subscriptionFilterPayload={filterPayload}
                    shopUUID={selectedShop?.uuid || ''}
                    selectedCount={selectedCount}
                />
            )}

            {!!emailModal && selectedShop && (
                <EmailModal
                    dataType="SUBSCRIPTION_FILTERS"
                    subscriptionFilterPayload={filterPayload}
                    selectedCount={selectedCount}
                    onCancel={() => {
                        setEmailModal(false);
                    }}
                    open={!!emailModal}
                    shop={selectedShop.uuid}
                ></EmailModal>
            )}

            {!!subscriptionDeliveryOptionModal && (
                <SubscriptionDeliveryOptionModal
                    open={!!subscriptionDeliveryOptionModal}
                    subscription={subscriptionDeliveryOptionModal}
                    onCancel={() => {
                        setSubscriptionDeliveryOptionModal(false);
                    }}
                    onSuccess={(
                        resData: Subscription['subscription_product']
                    ) => {
                        const newSubscriptions = subscriptions.map((s) => {
                            if (
                                s.uuid === subscriptionDeliveryOptionModal.uuid
                            ) {
                                return {
                                    ...s,
                                    subscription_product: {
                                        ...s.subscription_product,
                                        ...resData,
                                    },
                                };
                            } else {
                                return s;
                            }
                        });

                        setSubscriptions(newSubscriptions);
                    }}
                ></SubscriptionDeliveryOptionModal>
            )}

            {
                <ExcelModal
                    open={excelModal}
                    onClose={() => {
                        setExcelModal(false);
                    }}
                    subscriptionFilterPayload={filterPayload}
                />
            }
        </Card>
    );
}
