import { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Card,
    Space,
    Button,
    Modal,
    Dropdown,
    Badge,
    Alert,
    message,
    Checkbox,
    Table,
} from 'antd';
import {
    SettingFilled,
    CheckCircleFilled,
    CommentOutlined,
    HistoryOutlined,
    StopOutlined,
    DeleteOutlined,
    PlusCircleFilled,
    BankOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import {
    SubscriptionTransaction,
    SubscriptionTransactionFilterRequest,
} from '../../../interfaces/transaction';
import { displayCurrencyPrice, kr } from '../../../utils';
import EditTransactionModal from '../../Transactions/EditTransactionsModal/EditTransactionsModal';
import RefundTransaction from '../../../components/RefundTransaction/RefundTransaction';
import DeleteTransactionModal from '../../Transactions/DeleteTransactionsModal/DeleteTransactionsModal';
import { getSubscriptionPaymentString } from '../../../utils/subscriptionUtils';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import TransactionPaymentStatus from '../../../components/TransactionPaymentStatus/TransactionPaymentStatus';
import TransactionDeliveryInfo from '../../../components/TransactionDeliveryInfo/TransactionDeliveryInfo';
import TransactionHistory from '../../../components/TransactionHistory/TransactionHistory';
import SendToShopifyModal from './SendToShopifyModal/SendToShopifyModal';
import CreateCustomerTransactions from './CreateCustomerTransactions/CreateCustomerTransactions';
import SendToPaydayModal from './SendToPaydayModal/SendToPaydayModal';
import RepeatClaim from '../../../interfaces/repeatClaim';
dayjs.locale('is'); // use locale

type Props = {
    customer_uuid: string;
    shop_uuid: string;
    repeatClaims?: RepeatClaim[];
};

const translateClaimStatus = (status: string) => {
    switch (status) {
        case 'pending':
            return 'Í vinnslu';
        case 'rejected':
            return 'Villa kom upp';
        case 'paid':
            return 'Greidd';
        case 'unpaid':
            return 'Ógreidd';
        case 'cancelled':
            return 'Krafa felld niður';
        default:
            return status;
    }
};

const translateClaimCreateSuccess = (status: string) => {
    switch (status) {
        case 'pending':
            return 'Í vinnslu';
        case 'rejected':
            return 'Villa kom upp';
        case 'paid':
            return 'Tókst';
        case 'unpaid':
            return 'Tókst';
        case 'cancelled':
            return 'Tókst';
        default:
            return status;
    }
};

interface IWindow extends Window {
    me?: number;
}

const w: IWindow = window;

export default function CustomerTransactions(props: Props) {
    const [transactions, setTransactions] = useState<SubscriptionTransaction[]>(
        []
    );
    const [loading, setLoading] = useState(true);
    const [transactionCount, setTransactionCount] = useState<
        number | undefined
    >();
    const [createTransactionsModal, setCreateTransactionsModal] =
        useState<boolean>(false);
    const [editTransactionModal, setEditTransactionModal] = useState<
        | false
        | {
              options: {
                  mark_paid?: boolean;
                  mark_fulfilled?: boolean;
                  comment?: boolean;
              };
              transactions: SubscriptionTransaction[];
          }
    >(false);
    const [refundTransactionModal, setRefundTransactionModal] = useState<
        false | SubscriptionTransaction
    >(false);
    const [deleteTransactionModal, setDeleteTransactionModal] = useState<
        false | number[]
    >(false);
    const [sendToShopifyModal, setSendToShopifyModal] = useState<
        false | SubscriptionTransaction
    >(false);
    const [paydayModal, setPaydayModal] = useState<
        false | SubscriptionTransaction
    >(false);
    const [sendToWoocommerceModal, setSendToWoocommerceModal] = useState<
        false | SubscriptionTransaction
    >(false);
    const [transactionHistoryModal, setTransactionHistoryModal] = useState<
        false | string
    >(false);
    const [waitingForWoocommerce, setWaitingForWoocommerce] = useState(false);
    const [showDeleted, setShowDeleted] = useState(false);
    const [claimsBeingRefetched, setClaimsBeingRefetched] = useState<number[]>(
        []
    );

    useEffect(() => {
        axios
            .get(
                `/askrift/api/subscription_transactions/?page_size=999&ordering=-created&subscription__customer__uuid=${props.customer_uuid}&is_deleted=${showDeleted}`
            )
            .then((rsp) => {
                setTransactions(rsp.data.results);
                setTransactionCount(rsp.data.count);
                setLoading(false);
            });
    }, [showDeleted]);

    const getSelection = (
        ids: number[]
    ): SubscriptionTransactionFilterRequest => {
        return {
            selectedCount: 1,
            selectionList: ids,
            allSelected: false,
            shop_uuid: props.shop_uuid,
            filters: {
                is_completed: true,
                is_uncompleted: true,
                subscription__active: 'true,false',
                is_free: '',
                ordering: '-created',
                subscription__product__in: '',
                subscription__payment_method__in: 'BANK_CLAIM,CARDS',
                frequencies: '',
                max_per_customer: '',
                min_per_customer: '',
            },
        };
    };

    const hasAddOnTransactions = (transaction: SubscriptionTransaction) => {
        return transaction.add_on_transactions?.length || 0 > 0;
    };

    const getAddonPrice = (transaction: SubscriptionTransaction) => {
        return (
            transaction.add_on_transactions?.reduce((acc, addOnTransaction) => {
                console.log('addOnTransaction', addOnTransaction);
                return (
                    acc + addOnTransaction.amount * addOnTransaction.quantity
                );
            }, 0) || 0
        );
    };

    const refetchClaimStatus = (claim: RepeatClaim) => {
        setClaimsBeingRefetched([...claimsBeingRefetched, claim.id]);

        axios.get(`/banking/claims/${claim.id}/`).then((rsp) => {
            Modal.success({
                title: 'Tókst!',
                content: (
                    <>
                        <div>Upplýsingar um stöðu sóttar</div>
                        <br></br>
                        <div>
                            Athugaðu að staða kröfu getur verið allt að 24
                            klukkutímum eftirá miðað við raunstöðu í bankanum.
                        </div>
                    </>
                ),
            });

            setClaimsBeingRefetched(
                claimsBeingRefetched.filter((id) => id !== claim.id)
            );
        });
    };

    return (
        <Card
            loading={loading}
            className="customerTransactionsCard"
            title={
                <div>
                    Sendingar {transactionCount && `(${transactionCount})`}
                </div>
            }
            extra={
                <Button
                    size="small"
                    type="text"
                    onClick={() => setCreateTransactionsModal(true)}
                >
                    <PlusCircleFilled /> Búa til sendingu
                </Button>
            }
        >
            {w.me === 1 && (
                <div className="text-right" style={{ marginBottom: 5 }}>
                    Sýna eyddar sendingar{' '}
                    <Checkbox
                        onChange={(val) => setShowDeleted(val.target.checked)}
                    ></Checkbox>
                </div>
            )}

            <div id="customerShipments">
                <Space
                    size={15}
                    direction="vertical"
                    style={{ display: 'flex' }}
                >
                    {transactions.length === 0 && (
                        <div
                            style={{
                                color: '#ccc',
                            }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    style={{
                                        width: 40,
                                        opacity: 0.2,
                                        marginTop: 10,
                                    }}
                                    src="/static/images/svg/box.svg"
                                    alt="Engar sendingar"
                                />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div
                                    style={{
                                        color: '#ccc',
                                    }}
                                >
                                    Engar sendingar
                                </div>
                            </div>
                        </div>
                    )}

                    {transactions.map(
                        (transaction: SubscriptionTransaction, key: number) => {
                            const customActions = [];

                            const repeatClaims = props.repeatClaims?.filter(
                                (claim) =>
                                    claim.subscription_transactions?.includes(
                                        transaction.id
                                    )
                            );

                            if (
                                !!transaction.subscription.product.payday_info
                            ) {
                                customActions.push({
                                    label: (
                                        <>
                                            <img
                                                style={{
                                                    width: 15,
                                                    position: 'relative',
                                                    top: -2,
                                                }}
                                                src="/static/images/svg/payday_logo.svg"
                                                alt="Payday logo"
                                            />{' '}
                                            Búa til Payday pöntun
                                        </>
                                    ),
                                    key: 'send_to_payday',
                                    onClick: () => {
                                        setPaydayModal(transaction);
                                    },
                                });
                            }

                            if (
                                !!transaction.subscription.product.shopify_info
                            ) {
                                customActions.push({
                                    label: (
                                        <>
                                            <img
                                                style={{
                                                    width: 15,
                                                    position: 'relative',
                                                    top: -2,
                                                }}
                                                src="/static/images/svg/shopify.svg"
                                                alt="Shopify logo"
                                            />{' '}
                                            Búa til Shopify pöntun
                                        </>
                                    ),
                                    key: 'send_to_shopify',
                                    onClick: () => {
                                        setSendToShopifyModal(transaction);
                                    },
                                });
                            }

                            if (
                                !!transaction.subscription.product
                                    .woocommerce_info
                            ) {
                                customActions.push({
                                    label: (
                                        <>
                                            <img
                                                style={{
                                                    width: 15,
                                                    position: 'relative',
                                                    top: -2,
                                                }}
                                                src="/static/images/svg/woocommerce.svg"
                                                alt="Woocommerce logo"
                                            />{' '}
                                            Búa til Woocommerce pöntun
                                        </>
                                    ),
                                    key: 'send_to_woocommerce',
                                    onClick: () => {
                                        setSendToWoocommerceModal(transaction);
                                    },
                                });
                            }

                            const hasAddons = hasAddOnTransactions(transaction);

                            const dataSource = [
                                {
                                    col_one:
                                        transaction.subscription?.product.title,
                                    col_two: displayCurrencyPrice(
                                        transaction.amount,
                                        transaction.currency_code
                                    ),
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                },
                            ] as {
                                col_one: string;
                                col_two: string;
                                style?: React.CSSProperties;
                            }[];

                            if (hasAddons) {
                                transaction?.add_on_transactions?.forEach(
                                    (addOnTransaction, key) => {
                                        dataSource.push({
                                            col_one:
                                                addOnTransaction.product
                                                    ?.title ||
                                                'Viðbótarvara ' + (key + 1),
                                            col_two:
                                                '+ ' +
                                                displayCurrencyPrice(
                                                    addOnTransaction.amount,
                                                    transaction.currency_code
                                                ),
                                        });
                                    }
                                );
                            }

                            dataSource.push({
                                col_one: 'Greiðsluleið',
                                col_two: getSubscriptionPaymentString(
                                    transaction.subscription,
                                    transaction
                                ),
                                style: {
                                    fontWeight: 'lighter',
                                    fontSize: '13px',
                                },
                            });

                            if (transaction.perform_payment_time) {
                                dataSource.push({
                                    col_one: (
                                        <div style={{ color: 'orange' }}>
                                            Verður ekki rukkað fyrr en
                                        </div>
                                    ) as any,
                                    col_two: (
                                        <div style={{ color: 'orange' }}>
                                            {dayjs(
                                                transaction.perform_payment_time
                                            ).format('D. MMM')}
                                        </div>
                                    ) as any,
                                });
                            }

                            dataSource.push({
                                col_one: 'Stofnað',
                                col_two: dayjs(transaction.created).format(
                                    'D. MMM YYYY HH:mm'
                                ),
                                style: {
                                    fontWeight: 'lighter',
                                    fontSize: '13px',
                                },
                            });

                            if (transaction.payment_received_date) {
                                dataSource.push({
                                    col_one: (
                                        <div
                                        // style={{
                                        //     color: '#3ac79f',
                                        // }}
                                        >
                                            Greitt
                                        </div>
                                    ) as any,
                                    col_two: (
                                        <div
                                        // style={{
                                        //     color: '#3ac79f',
                                        // }}
                                        >
                                            {dayjs(
                                                transaction.payment_received_date
                                            ).format('D. MMM YYYY HH:mm')}
                                        </div>
                                    ) as any,
                                    style: {
                                        fontWeight: 'lighter',
                                        fontSize: '13px',
                                    },
                                });
                            }
                            return (
                                <Card
                                    className="ant-card-gray"
                                    key={key}
                                    title={
                                        <>
                                            <div>
                                                <TransactionPaymentStatus
                                                    transaction={transaction}
                                                />{' '}
                                                <span
                                                    style={{ paddingLeft: 5 }}
                                                >
                                                    {transaction.is_fulfilled ? (
                                                        <Badge
                                                            status="success"
                                                            text="Uppfyllt"
                                                        />
                                                    ) : (
                                                        <Badge
                                                            status="error"
                                                            text="Óuppfyllt"
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </>
                                    }
                                    size="small"
                                    extra={
                                        <>
                                            <Space>
                                                <Dropdown
                                                    menu={{
                                                        items: [
                                                            {
                                                                label: 'Endurgreiðsla',
                                                                key: 'refund',
                                                                onClick: () => {
                                                                    setRefundTransactionModal(
                                                                        transaction
                                                                    );
                                                                },
                                                                disabled:
                                                                    (transaction.is_paid ===
                                                                        false &&
                                                                        !!transaction
                                                                            .payment_request_log
                                                                            ?.length) ||
                                                                    transaction.is_free ||
                                                                    transaction.is_refunded ||
                                                                    transaction
                                                                        .subscription
                                                                        ?.payment_method ===
                                                                        'BANK_CLAIM',
                                                                icon: (
                                                                    <HistoryOutlined />
                                                                ),
                                                            },
                                                            {
                                                                key: 'mark_paid_unpaid',
                                                                onClick: () => {
                                                                    setEditTransactionModal(
                                                                        {
                                                                            options:
                                                                                {
                                                                                    mark_paid:
                                                                                        true,
                                                                                },
                                                                            transactions:
                                                                                [
                                                                                    transaction,
                                                                                ],
                                                                        }
                                                                    );
                                                                },
                                                                icon: (
                                                                    <CheckCircleFilled />
                                                                ),
                                                                label: 'Merkja sem greitt/ógreitt',
                                                            },

                                                            {
                                                                key: 'mark_fulfilled_unfulfilled,',
                                                                icon: (
                                                                    <StopOutlined />
                                                                ),
                                                                onClick: () => {
                                                                    setEditTransactionModal(
                                                                        {
                                                                            options:
                                                                                {
                                                                                    mark_fulfilled:
                                                                                        true,
                                                                                },
                                                                            transactions:
                                                                                [
                                                                                    transaction,
                                                                                ],
                                                                        }
                                                                    );
                                                                },
                                                                label: 'Merkja sem uppfyllt/óuppfyllt',
                                                            },

                                                            // {
                                                            //     key: 'charge_card',
                                                            //     onClick: () => {
                                                            //         setChargeTransactionModal(
                                                            //             transaction
                                                            //         );
                                                            //     },
                                                            //     disabled:
                                                            //         transaction.is_paid !==
                                                            //         false,
                                                            //     icon: (
                                                            //         <CreditCardFilled />
                                                            //     ),
                                                            //     label: 'Rukka kort',
                                                            // },

                                                            {
                                                                key: 'add_comment',
                                                                onClick: () => {
                                                                    setEditTransactionModal(
                                                                        {
                                                                            options:
                                                                                {
                                                                                    comment:
                                                                                        true,
                                                                                },
                                                                            transactions:
                                                                                [
                                                                                    transaction,
                                                                                ],
                                                                        }
                                                                    );
                                                                },
                                                                icon: (
                                                                    <CommentOutlined />
                                                                ),

                                                                label: 'Bæta við athugasemd',
                                                            },

                                                            ...customActions,
                                                            {
                                                                key: 'delete',
                                                                onClick: () => {
                                                                    setDeleteTransactionModal(
                                                                        [
                                                                            transaction.id,
                                                                        ]
                                                                    );
                                                                },
                                                                icon: (
                                                                    <DeleteOutlined />
                                                                ),
                                                                danger: true,
                                                                label: 'Eyða',
                                                            },
                                                        ],
                                                    }}
                                                >
                                                    <div>
                                                        <Button size="small">
                                                            <SettingFilled></SettingFilled>{' '}
                                                            Aðgerðir
                                                        </Button>
                                                    </div>
                                                </Dropdown>

                                                {w.me === 1 &&
                                                    !!transaction.history &&
                                                    !!transaction.history
                                                        .length && (
                                                        <span>
                                                            <HistoryOutlined
                                                                onClick={() => {
                                                                    if (
                                                                        transaction.history
                                                                    )
                                                                        setTransactionHistoryModal(
                                                                            transaction.uuid
                                                                        );
                                                                }}
                                                            />
                                                        </span>
                                                    )}

                                                <div>
                                                    {w.me === 1 && (
                                                        <>
                                                            {' '}
                                                            <Button
                                                                size="small"
                                                                href={`
                                                    /bakendi/subscription/subscriptiontransaction/${transaction.id}/change/
                                                    `}
                                                            >
                                                                Bakendi
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </Space>
                                        </>
                                    }
                                >
                                    {transaction.is_refunded && (
                                        <Alert
                                            message="Þessi sending hefur verið
                                            endurgreidd"
                                            type="error"
                                            style={{ textAlign: 'center' }}
                                        />
                                    )}
                                    <small>
                                        <Table
                                            className="transparent-table"
                                            dataSource={dataSource}
                                            // Skip border on last row
                                            rowClassName={(record, index) => {
                                                return index ===
                                                    dataSource.length - 1
                                                    ? 'no-border'
                                                    : '';
                                            }}
                                            columns={[
                                                {
                                                    title: '',
                                                    render: (text, record) => {
                                                        return (
                                                            <div
                                                                style={
                                                                    record.style
                                                                        ? record.style
                                                                        : {}
                                                                }
                                                            >
                                                                {record.col_one}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    title: '',

                                                    align: 'right',
                                                    render: (text, record) => {
                                                        return (
                                                            <div
                                                                style={
                                                                    record.style
                                                                }
                                                            >
                                                                {record.col_two}
                                                            </div>
                                                        );
                                                    },
                                                },
                                            ]}
                                            showHeader={false}
                                            pagination={false}
                                            size="small"
                                            bordered={false}
                                        ></Table>
                                    </small>

                                    {!!repeatClaims?.length &&
                                        repeatClaims.map((claim) => {
                                            return (
                                                <Card
                                                    title={
                                                        <>
                                                            {(claim.status ===
                                                                'paid' ||
                                                                claim.status ===
                                                                    'rejected') && (
                                                                <Badge
                                                                    status={
                                                                        claim.status ===
                                                                        'paid'
                                                                            ? 'success'
                                                                            : 'error'
                                                                    }
                                                                />
                                                            )}{' '}
                                                            <BankOutlined />{' '}
                                                            Bankakrafa
                                                        </>
                                                    }
                                                    size="small"
                                                    extra={
                                                        <div>
                                                            {' '}
                                                            <Button
                                                                size="small"
                                                                type="text"
                                                                disabled={claimsBeingRefetched.includes(
                                                                    claim.id
                                                                )}
                                                                onClick={() => {
                                                                    refetchClaimStatus(
                                                                        claim
                                                                    );
                                                                }}
                                                            >
                                                                <SyncOutlined
                                                                    spin={claimsBeingRefetched.includes(
                                                                        claim.id
                                                                    )}
                                                                />
                                                            </Button>
                                                        </div>
                                                    }
                                                >
                                                    {/* <div className="repeatClaimRow">
                                                        <div>Krafa stofnuð</div>
                                                        <div>
                                                            {dayjs(
                                                                claim.created
                                                            ).format(
                                                                'D. MMM YYYY - HH:mm'
                                                            )}
                                                        </div>
                                                    </div> */}
                                                    <div className="repeatClaimRow">
                                                        <div>Stofnun kröfu</div>
                                                        <div>
                                                            {translateClaimCreateSuccess(
                                                                claim.status
                                                            )}
                                                        </div>
                                                    </div>

                                                    {!!![
                                                        'pending',
                                                        'rejected',
                                                    ].includes(
                                                        claim.status
                                                    ) && (
                                                        <div className="repeatClaimRow">
                                                            <div>
                                                                Staða greiðslu
                                                            </div>
                                                            <div>
                                                                <Badge
                                                                    status={
                                                                        [
                                                                            'paid',
                                                                        ].includes(
                                                                            claim.status
                                                                        )
                                                                            ? 'success'
                                                                            : [
                                                                                    'pending',
                                                                                    'cancelled',
                                                                                ].includes(
                                                                                    claim.status
                                                                                )
                                                                              ? 'warning'
                                                                              : 'error'
                                                                    }
                                                                />{' '}
                                                                {translateClaimStatus(
                                                                    claim.status
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="repeatClaimRow">
                                                        <div>Upphæð</div>
                                                        <div>
                                                            {displayCurrencyPrice(
                                                                claim.amount,
                                                                'ISK'
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            );
                                        })}

                                    <TransactionDeliveryInfo
                                        className="mt10"
                                        isPublic={false}
                                        subscriptionTransaction={transaction}
                                    ></TransactionDeliveryInfo>

                                    {transaction.staff_comments
                                        ?.sort((a, b) =>
                                            b.created > a.created ? 1 : -1
                                        )
                                        .map((comment, key) => {
                                            return (
                                                <Card
                                                    title={
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'lighter',
                                                                fontSize:
                                                                    '13px',
                                                            }}
                                                        >
                                                            {dayjs(
                                                                comment.created
                                                            ).format(
                                                                'D. MMM YYYY - HH:mm'
                                                            )}
                                                        </div>
                                                    }
                                                    key={key}
                                                    size="small"
                                                    extra={comment.user}
                                                    className="customerComment mb10"
                                                >
                                                    {comment.message}
                                                </Card>
                                            );
                                        })}
                                </Card>
                            );
                        }
                    )}
                </Space>
            </div>

            {!!editTransactionModal && (
                <EditTransactionModal
                    selection={getSelection(
                        editTransactionModal?.transactions.map((s) => s.id)
                    )}
                    open={!!editTransactionModal}
                    options={editTransactionModal.options}
                    title="Uppfæra sendingu"
                    onCancel={() => {
                        setEditTransactionModal(false);
                    }}
                    onSuccess={() => {
                        message.success('Tókst');

                        setEditTransactionModal(false);
                    }}
                ></EditTransactionModal>
            )}

            {!!refundTransactionModal && (
                <Modal
                    title="Endurgreiða færslu"
                    onCancel={() => {
                        setRefundTransactionModal(false);
                    }}
                    footer={null}
                    open={!!refundTransactionModal}
                >
                    <RefundTransaction
                        onCancel={(refundSuccessfull) => {
                            setRefundTransactionModal(false);

                            if (refundSuccessfull) {
                                transactions.map((transaction) => {
                                    if (
                                        transaction.id ===
                                        refundTransactionModal?.id
                                    ) {
                                        transaction.is_refunded = true;
                                    }
                                    return transaction;
                                });
                            }
                        }}
                        transaction={refundTransactionModal}
                    ></RefundTransaction>
                </Modal>
            )}

            {/* {!!chargeTransactionModal && (
                <ChargeTransactionsModal
                    selection={{
                        selectedCount: 1,
                        selectionList: [chargeTransactionModal.id],
                        allSelected: false,
                        // filters: SubscriptionTransactionFilter,
                        shop_uuid: shopuu,
                    }}
                    onCancel={() => {
                        setChargeTransactionModal(false);
                    }}
                    open={!!chargeTransactionModal}
                    onSuccess={(res_data: {
                        responses: { id: number; success: boolean }[];
                    }) => {
                        const responses = res_data.responses;
                        const res_ids = responses.map((data) => data.id);

                        setTransactions([
                            ...transactions.filter(
                                (transaction) =>
                                    !res_ids.includes(transaction.id)
                            ),
                            ...transactions
                                .filter((transaction) =>
                                    res_ids.includes(transaction.id)
                                )
                                .map((transaction) => {
                                    return {
                                        ...transaction,
                                        is_paid: responses.filter(
                                            (data) => data.id === transaction.id
                                        )[0].success,
                                    };
                                }),
                        ]);
                    }}
                ></ChargeTransactionsModal>
            )} */}

            {!!deleteTransactionModal && (
                <DeleteTransactionModal
                    selection={getSelection(deleteTransactionModal)}
                    open={!!deleteTransactionModal}
                    onOk={() => {
                        const ids = deleteTransactionModal;
                        setTransactions(
                            transactions.filter((transaction) => {
                                const affected = ids.includes(transaction.id);

                                if (affected) {
                                    return false;
                                }

                                return true;
                            })
                        );

                        setDeleteTransactionModal(false);
                    }}
                    onCancel={() => {
                        setDeleteTransactionModal(false);
                    }}
                ></DeleteTransactionModal>
            )}

            {!!sendToShopifyModal && (
                <SendToShopifyModal
                    subscriptionTransactions={transactions}
                    open={!!sendToShopifyModal}
                    onCancel={() => {
                        setSendToShopifyModal(false);
                    }}
                    preselectedTransactionUuids={[sendToShopifyModal.uuid]}
                ></SendToShopifyModal>
            )}

            {!!paydayModal && (
                <SendToPaydayModal
                    subscriptionTransactions={transactions}
                    open={!!paydayModal}
                    onCancel={() => {
                        setPaydayModal(false);
                    }}
                    preselectedTransactionUuids={[paydayModal.uuid]}
                ></SendToPaydayModal>
            )}

            {!!transactionHistoryModal && (
                <Modal
                    open={!!transactionHistoryModal}
                    onCancel={() => setTransactionHistoryModal(false)}
                >
                    <TransactionHistory
                        uuid={transactionHistoryModal}
                    ></TransactionHistory>
                </Modal>
            )}

            {!!sendToWoocommerceModal && (
                <Modal
                    confirmLoading={waitingForWoocommerce}
                    onCancel={() => setSendToWoocommerceModal(false)}
                    onOk={() => {
                        setWaitingForWoocommerce(true);
                        axios
                            .post('/askrift/create_custom_woocommerce_order', {
                                transaction_id: sendToWoocommerceModal.id,
                            })
                            .then(() => {
                                message.success('Tókst 🎉');
                                setSendToWoocommerceModal(false);
                            })
                            .finally(() => setWaitingForWoocommerce(false));
                    }}
                    title="Senda aftur í Woocommerce"
                    open={!!sendToWoocommerceModal}
                >
                    <p>
                        Athugaðu að ef Repeat er þegar búið að stofna pöntun í
                        Woocommerce þá verður samt til önnur.
                    </p>
                </Modal>
            )}

            {!!createTransactionsModal && (
                <CreateCustomerTransactions
                    customer_uuid={props.customer_uuid}
                    shop_uuid={props.shop_uuid}
                    onClose={() => {
                        setCreateTransactionsModal(false);
                    }}
                ></CreateCustomerTransactions>
            )}
        </Card>
    );
}
