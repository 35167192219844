import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Button, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import SVG from 'react-inlinesvg';
import ReactHtmlParser from 'react-html-parser';

import './PaymentRequestReceipt.scss';

import {
    displayCurrencyPrice,
    getPaymentTransactionDisplayAmount,
} from '../../utils';
import { Currency } from '../../interfaces/product';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import translate, { LanguageKey } from '../../translate/translate';
import { PaymentRequestLogPublic } from '../../interfaces/payment';

interface IWindow extends Window {
    uuid?: string;
    id?: number;
}

const w: IWindow = window;

export default function PaymentRequestReceipt() {
    const [payment, setPayment] = useState<PaymentRequestLogPublic>();
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageKey>('is');

    useEffect(() => {
        axios
            .get(`/payment/api/payment_request_public/${w.id}/${w.uuid}`)
            .then((rsp) => {
                if (!rsp.data.shop) {
                    axios
                        .get(`/verslun/api/public_customer/${w.uuid}/`)
                        .then((res) => {
                            setPayment({
                                ...rsp.data,
                                shop: res.data.shop,
                            });
                        });
                } else {
                    setPayment(rsp.data);
                }
            });
    }, []);

    const { shop, created } = payment || {};
    const { ssid, website, email, name, logo } = shop || {};
    const customer = payment?.customer;
    let paymentAmount = getPaymentTransactionDisplayAmount(payment);

    if (
        payment?.payment_provider === 'VERIFONE' &&
        payment.currency_code !== 'ISK'
    ) {
        paymentAmount = payment?.amount;
    }

    return (
        <div id="subscriptionTransactionReceipt">
            <div className="toolbar">
                <Space>
                    <SelectLanguage
                        defaultValue="is"
                        onChange={(val) => setSelectedLanguage(val)}
                    ></SelectLanguage>

                    <Button
                        onClick={() => {
                            window.print();
                        }}
                        size="small"
                    >
                        <PrinterOutlined />{' '}
                        {translate('print', selectedLanguage)}
                    </Button>
                </Space>
            </div>
            {payment && customer && (
                <div id="receiptPaper">
                    <header>
                        <div className="leftSide">
                            <div>
                                <img
                                    style={{ maxWidth: 90 }}
                                    src={logo?.image}
                                    alt="Shop Logo"
                                />
                            </div>

                            <h1>{name}</h1>
                            {ssid && <div>{ssid}</div>}
                            {website && <div>{website}</div>}
                            {email && <div>{email}</div>}

                            <div style={{ marginTop: 40 }}>
                                <div>
                                    <div>
                                        <strong>{customer.name}</strong>
                                    </div>
                                    <div>
                                        {customer.ssid && <>{customer.ssid}</>}
                                    </div>
                                </div>

                                {customer.street && (
                                    <>
                                        <div>
                                            {customer.street},{' '}
                                            {customer.postal_code}{' '}
                                            {customer.town}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="rightSide">
                            <h1>{translate('receipt', selectedLanguage)}</h1>

                            <h3>
                                {translate('date', selectedLanguage)}:{' '}
                                {dayjs(created).format('D. MMM YYYY')}
                            </h3>

                            {payment.success && !payment.is_refunded && (
                                <h2>{translate('paid', selectedLanguage)}</h2>
                            )}

                            {payment.is_refunded && (
                                <h2>
                                    {translate('refunded', selectedLanguage)}
                                </h2>
                            )}

                            {!payment.is_refunded && !payment.success && (
                                <h2>{translate('unpaid', selectedLanguage)}</h2>
                            )}
                        </div>
                    </header>

                    <main>
                        <div
                            style={{
                                marginTop: 40,
                                backgroundColor: '#f5f5f5',
                                padding: '10px 20px',
                            }}
                        >
                            <div>
                                {translate('description', selectedLanguage)}
                            </div>
                        </div>

                        <div>
                            {!payment.order &&
                                payment?.subscription_transactions?.map(
                                    (subscriptionTransaction, key) => {
                                        return (
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: 20,
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                                key={key}
                                            >
                                                <div>
                                                    {
                                                        subscriptionTransaction.product_title
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                )}

                            {payment.order &&
                                payment.order.products.map((product) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor: '#fff',
                                                padding: 20,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>{product.product.title}</div>
                                            <div>
                                                {product.quantity} x{' '}
                                                {displayCurrencyPrice(
                                                    product.price,
                                                    payment.currency_code
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                        <hr />

                        <div
                            style={{
                                textAlign: 'right',
                                marginTop: 40,
                            }}
                        >
                            <div>{translate('amount', selectedLanguage)}</div>
                            <h1>
                                {displayCurrencyPrice(
                                    paymentAmount,
                                    payment.currency_code
                                )}
                            </h1>
                        </div>
                    </main>

                    <footer className="footer">
                        <div
                            style={{
                                textAlign: 'center',
                                paddingTop: 40,
                            }}
                        >
                            {shop?.receipt_footer && (
                                <div
                                    style={{
                                        marginBottom: 20,
                                        fontSize: 12,
                                        color: 'gray',
                                    }}
                                >
                                    {
                                        ReactHtmlParser(
                                            shop.receipt_footer
                                        ) as React.ReactNode
                                    }
                                </div>
                            )}
                            <div>
                                <a href="https://repeat.is" target="blank">
                                    <SVG
                                        style={{
                                            width: '120px',
                                            boxSizing: 'initial',
                                            paddingBottom: '0px',
                                        }}
                                        id="hero_logo"
                                        baseURL="/"
                                        cacheRequests={true}
                                        description="Logo"
                                        src={'/static/images/logo/logo.svg'}
                                        title="Logo"
                                        key="hero_logo"
                                        uniqueHash="hero_logo"
                                        uniquifyIDs={false}
                                        fill={'lightgray'}
                                    />
                                    <div
                                        style={{
                                            color: 'lightgray',
                                            fontSize: 12,
                                        }}
                                    >
                                        {translate(
                                            'powered_by_repeat',
                                            selectedLanguage
                                        )}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </footer>
                </div>
            )}
        </div>
    );
}
