import axios from 'axios';
import { useState, useEffect } from 'react';
import { Form, Select, Input, Button, message } from 'antd';
import { LockOutlined, CodeSandboxOutlined } from '@ant-design/icons';
import { DeliveryConfig, DeliveryOption } from '../../../interfaces/delivery';
import { kr } from '../../../utils';
import useDropp from '../../../customHooks/Dropp/Dropp';
import translate from '../../../translate/translate';

import Shop from '../../../interfaces/shop';
import { useQuery } from '@tanstack/react-query';

interface PostboxOption {
    postboxId: string;
    name: string;
    address: string;
    postcode: string;
    town: string;
    latitude: string;
    longitude: string;
}

export type DeliveryChoicePayload = {
    externalDeliveryLocationId?: string;
    deliveryOption?: DeliveryOption;
    postalCode?: string;
    locationTitle?: string;
    shippingProvider?: string;
    title?: string;
    deliveryTown?: string;
};

export default function AllDeliveryChoices(props: {
    onSuccess?: (res: any) => void;
    size?: 'small' | 'large';
    returnType?: 'response' | 'payload';
    shop: Shop;
    onSelect?: (payload: DeliveryChoicePayload) => void;
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [selectedDeliveryOption, setSelectedDeliveryOption] =
        useState<DeliveryOption | null>();
    const [postboxOptions, setPostboxOptions] = useState<PostboxOption[]>([]);
    const [parcelpointOptions, setParcelpointOptions] = useState<
        PostboxOption[]
    >([]);

    const [selectedPostalCode, setSelectedPostalCode] = useState<
        string | null
    >();

    const [
        selectedExternalDeliveryLocationId,
        setSelectedExternalDeliveryLocationId,
    ] = useState<string | null>();

    const [isChanged, setIsChanged] = useState(false);

    const [initialDeliveryOptionDetails, setInitialDeliveryOptionDetails] =
        useState<{
            icon: string;
            provider_title: string;
            title: string;
        } | null>(null);

    const { droppSelection, clearDroppSelection, openDroppModal } = useDropp();

    const lang = 'is';

    const { data, isLoading } = useQuery({
        queryKey: ['all_delivery_choices'],
        queryFn: () =>
            axios.get(
                '/shipping/api/delivery_config_overview/' + props.shop.uuid
            ),
    });

    // useEffect(() => {
    //     if (
    //         props.subscription.subscription_product
    //             ?.external_delivery_location_id &&
    //         deliveryConfig?.dropp?.store_id
    //     ) {
    //         setDroppLocationId(
    //             props.subscription.subscription_product
    //                 .external_delivery_location_id,
    //             deliveryConfig?.dropp?.store_id
    //         );
    //     }
    // }, [deliveryConfig]);

    useEffect(() => {
        if (
            !selectedPostalCode ||
            selectedPostalCode?.toString().length !== 3
        ) {
            setPostboxOptions([]);
            setParcelpointOptions([]);
            form.setFieldsValue({
                posturinn_dpo_name: undefined,
                posturinn_dpt_name: undefined,
            });
        } else if (selectedPostalCode.toString().length === 3) {
            axios
                .post('/shipping/api/posturinn_postboxes', {
                    shop_uuid: props.shop.uuid,
                    postal_code: selectedPostalCode,
                })
                .then((rsp) => {
                    setPostboxOptions(rsp.data || []);
                });

            axios
                .post('/shipping/api/posturinn_parcelpoints', {
                    shop_uuid: props.shop.uuid,
                    postal_code: selectedPostalCode,
                })
                .then((rsp) => {
                    setParcelpointOptions(rsp.data || []);
                });
        }
    }, [selectedPostalCode]);

    const deliveryConfigs = data?.data;
    const deliveryOptions =
        deliveryConfigs?.flatMap((config: DeliveryConfig) => config.options) ||
        [];

    return (
        <div>
            <Form
                form={form}
                size={props.size || 'large'}
                layout="vertical"
                onFinish={(values) => {
                    const payload = {
                        // Delivery
                        externalDeliveryLocationId: droppSelection?.id,
                        deliveryOption: selectedDeliveryOption,
                        postalCode:
                            values.posturinn_dpo_postal_code ||
                            values.posturinn_dpo_postal_code ||
                            droppSelection?.addressObject?.zip?.toString(),
                        locationTitle:
                            values.posturinn_dpt_name ||
                            values.posturinn_dpo_name ||
                            droppSelection?.name,
                        shippingProvider:
                            selectedDeliveryOption?.shipping_provider,
                        title: selectedDeliveryOption?.title,
                        deliveryTown: droppSelection?.addressObject?.town,
                    } as DeliveryChoicePayload;

                    props.onSelect?.(payload);
                    setIsChanged(false);
                }}
            >
                <Form.Item
                    name="delivery_option"
                    rules={[
                        {
                            required: true,
                            message: translate(
                                'delivery_method_required',
                                lang
                            ),
                        },
                    ]}
                >
                    <Select
                        placeholder={translate('select_delivery_option', lang)}
                        size={props.size || 'large'}
                        onSelect={(id: number) => {
                            setIsChanged(true);
                            const option = deliveryOptions.find(
                                (o: DeliveryOption) => o.id === id
                            );

                            if (option) {
                                if (option.shipping_provider) {
                                    setSelectedDeliveryOption(option);

                                    if (
                                        option.shipping_provider ===
                                        'DROPP_PICKUP'
                                    ) {
                                        openDroppModal();
                                    } else {
                                        clearDroppSelection();
                                    }
                                }
                            }
                        }}
                        children={deliveryOptions.map(
                            (option: DeliveryOption, i: number) => {
                                return (
                                    <Select.Option
                                        value={option.id}
                                        key={option.id}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                {option.hidden_from_public && (
                                                    <LockOutlined />
                                                )}{' '}
                                                {option.title}
                                            </div>

                                            <small>{kr(option.price)}</small>
                                        </div>
                                    </Select.Option>
                                );
                            }
                        )}
                    ></Select>
                </Form.Item>

                {selectedDeliveryOption?.shipping_provider ===
                    'POSTURINN_DPO' && (
                    // Póstbox
                    <>
                        <Form.Item
                            name="posturinn_dpo_postal_code"
                            label={translate('posturinn_dpo_postal_code', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(40% - 12px)',
                            }}
                        >
                            <Input
                                onChange={(e) => {
                                    const val = e.target.value;
                                    setSelectedPostalCode(val);
                                }}
                                placeholder={translate(
                                    'posturinn_dpo_postal_code',
                                    lang
                                ).toString()}
                                autoComplete="shipping postal-code"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posturinn_dpo_name"
                            label={translate('posturinn_dpo', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(60%)',
                                marginLeft: '12px',
                            }}
                        >
                            <Select
                                placeholder={translate('posturinn_dpo', lang)}
                                size="large"
                                defaultActiveFirstOption={true}
                                notFoundContent={translate(
                                    'nothing_found_for_postal_code',
                                    lang
                                )}
                                children={postboxOptions.map((option, i) => {
                                    return (
                                        <Select.Option
                                            value={option.name}
                                            key={option.postboxId}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <div>{option.name}</div>
                                            </div>
                                        </Select.Option>
                                    );
                                })}
                            ></Select>
                        </Form.Item>
                    </>
                )}
                {selectedDeliveryOption?.shipping_provider ===
                    'POSTURINN_DPT' && (
                    // Pósturinn pakkaport sækja
                    <>
                        <Form.Item
                            name="posturinn_dpt_postal_code"
                            label={translate('posturinn_dpt_postal_code', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(40% - 12px)',
                            }}
                        >
                            <Input
                                onChange={(e) => {
                                    const val = e.target.value;
                                    setSelectedPostalCode(val);
                                }}
                                placeholder={translate(
                                    'posturinn_dpt_postal_code',
                                    lang
                                ).toString()}
                                autoComplete="shipping postal-code"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item
                            name="posturinn_dpt_name"
                            label={translate('posturinn_dpt', lang)}
                            rules={[
                                {
                                    required: true,
                                    message: translate('field_required', lang),
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(60%)',
                                marginLeft: '12px',
                            }}
                        >
                            <Select
                                placeholder={translate('posturinn_dpt', lang)}
                                size="large"
                                defaultActiveFirstOption={true}
                                notFoundContent={translate(
                                    'nothing_found_for_postal_code',
                                    lang
                                )}
                                children={parcelpointOptions.map(
                                    (option, i) => {
                                        return (
                                            <Select.Option
                                                value={option.name}
                                                key={option.name}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>{option.name}</div>
                                                </div>
                                            </Select.Option>
                                        );
                                    }
                                )}
                            ></Select>
                        </Form.Item>
                    </>
                )}

                {!isChanged &&
                    initialDeliveryOptionDetails &&
                    !droppSelection && (
                        <div>
                            <div
                                style={{
                                    fontSize: 16,
                                    color: '#3d3d3d',
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: 'rgb(239 239 239)',
                                    padding: 5,
                                    borderRadius: 20,
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                {initialDeliveryOptionDetails.icon ? (
                                    <img
                                        src={initialDeliveryOptionDetails.icon}
                                        alt="Delivery icon"
                                        style={{ height: 30, marginRight: 10 }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            height: 30,
                                            marginRight: 10,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CodeSandboxOutlined />
                                    </div>
                                )}
                                <span>
                                    {
                                        initialDeliveryOptionDetails.provider_title
                                    }
                                </span>

                                {initialDeliveryOptionDetails.title && (
                                    <span>
                                        : {initialDeliveryOptionDetails.title}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                {droppSelection && (
                    <div
                        style={{
                            background: 'rgb(239 239 239)',
                            fontSize: 14,
                            padding: 5,
                            borderRadius: 20,
                            display: 'flex',
                            marginBottom: 20,
                            marginTop: -10,
                            color: '#3d3d3d',
                        }}
                    >
                        <img
                            src="/static/images/global_logos/dropp.svg"
                            alt="Dropp logo"
                            style={{ width: 40, marginRight: 5 }}
                        />
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                                {droppSelection.name}
                            </div>
                            <div>{droppSelection.address}</div>
                        </div>
                    </div>
                )}

                {isChanged && (
                    <Button
                        htmlType="submit"
                        block
                        loading={loading}
                        type="primary"
                    >
                        Staðfesta afhendingarmáta
                    </Button>
                )}
            </Form>
        </div>
    );
}
