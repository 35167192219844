import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import { Button, Layout, Menu, MenuProps, Select } from 'antd';
import { Outlet } from 'react-router-dom';
import {
    BarChartOutlined,
    SettingOutlined,
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DatabaseOutlined,
    BankOutlined,
} from '@ant-design/icons';
import AuthContext from '../../authContext';
import User from '../../interfaces/user';
import axios from 'axios';
import Shop from '../../interfaces/shop';
import Frontpage from '../../Pages/Frontpage/Frontpage';

type Props = {
    children?: React.ReactNode;
};

type MenuItem = Required<MenuProps>['items'][number];

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;
const isFrontpage = window.location.pathname === '/';

const pathname = window.location.pathname.split('/');

let defaultSelectedKeys: string[] = [];

if (pathname.includes('ny_verslun')) {
    defaultSelectedKeys = ['settings'];
}

if (pathname.includes('stjornbord')) {
    defaultSelectedKeys = ['dashboard'];
}
if (pathname.includes('askriftir')) {
    defaultSelectedKeys = ['askriftir'];
}

if (pathname.includes('sendingar')) {
    defaultSelectedKeys = ['sendingar'];
}

if (pathname.includes('tolfraedi')) {
    defaultSelectedKeys = ['tolfraedi'];
}

if (pathname.includes('pantanir')) {
    defaultSelectedKeys = ['pantanir'];
}

if (pathname.includes('efnisyfirlit')) {
    defaultSelectedKeys = ['efnisyfirlit'];
}

if (pathname.includes('vorur')) {
    defaultSelectedKeys = ['vorur'];
}

if (pathname.includes('ny_vara')) {
    defaultSelectedKeys = ['vorur'];
}

if (pathname.includes('nytt_efni')) {
    defaultSelectedKeys = ['efnisyfirlit'];
}

if (pathname.includes('staff')) {
    defaultSelectedKeys = ['staff'];
}

if (pathname.includes('loggar')) {
    defaultSelectedKeys = ['loggar'];
}

if (pathname.includes('repeat_banking')) {
    defaultSelectedKeys = ['repeat_banking'];
}

export default function HeaderAndContainerWrapper(props: Props) {
    const [user, setUser] = useState<User>();
    const [shops, setShops] = useState<Shop[]>([]);
    const [selectedShop, setSelectedShop] = useState<Shop>();
    const [shopsLoading, setShopsLoading] = useState(true);
    const [collapsed, setCollapsed] = useState(
        window.innerWidth < 800 ? true : false
    );
    const [userScopes, setUserScopes] = useState<string[] | 'ADMIN'>();

    const hasUserScope = (scope: string) => {
        return userScopes === 'ADMIN' || userScopes?.includes(scope);
    };

    useEffect(() => {
        if (selectedShop) {
            localStorage.setItem('selectedShop', selectedShop.uuid);
        }

        if (user && selectedShop) {
            if (w.me) {
                if (selectedShop.access && selectedShop.access.length === 0) {
                    setUserScopes('ADMIN');
                } else {
                    const userScopes = selectedShop.access?.find(
                        (a) => a.user === w.me
                    )?.scopes;

                    if (userScopes) {
                        setUserScopes(userScopes);
                    } else {
                        setUserScopes('ADMIN');
                    }
                }
            }
        }
    }, [selectedShop, user]);

    useEffect(() => {
        if (w.me) {
            axios.get('/account/api/user/' + w.me).then((rsp) => {
                setUser(rsp.data);
            });

            axios.get(`/verslun/api/shops/?page_size=999`).then((rsp) => {
                setShops(rsp.data.results);
                setShopsLoading(false);

                if (rsp.data.results.length === 1) {
                    setSelectedShop(rsp.data.results[0]);
                } else {
                    if (pathname.includes('stjornbord') && w.uuid) {
                        setSelectedShop(
                            rsp.data.results.find(
                                (s: Shop) => s.uuid === w.uuid
                            )
                        );
                    } else {
                        let localShop = localStorage.getItem('selectedShop');
                        localShop = localShop !== null ? localShop : null;

                        if (localShop) {
                            setSelectedShop(
                                rsp.data.results.find(
                                    (s: Shop) => s.uuid === localShop
                                )
                            );
                        } else {
                            console.log('No local shop');
                        }
                    }
                }
            });
        }
    }, [w.me]);

    const menuItems: MenuItem[] = [
        {
            key: 'dashboard',
            icon: <AppstoreOutlined />,
            label: <a href={'/stjornbord/'}>Stjórnborð</a>,
        },

        {
            key: 'askriftir_grp',
            label: 'Áskriftir',
            type: 'group',
            style:
                hasUserScope('SUBSCRIPTION_OVERVIEW') ||
                hasUserScope('SUBSCRIPTION_TRANSACTION_OVERVIEW')
                    ? {}
                    : { display: 'none' },
            children: [
                {
                    key: 'askriftir',
                    style: hasUserScope('SUBSCRIPTION_OVERVIEW')
                        ? {}
                        : { display: 'none' },
                    icon: (
                        <img
                            src="/static/images/svg/group.svg"
                            alt="Askriftir"
                            style={{
                                width: 20,
                                marginRight: 10,
                                marginTop: -5,
                            }}
                        />
                    ),
                    label: (
                        <a href={'/askriftir/' + selectedShop?.uuid + '/'}>
                            Áskriftir
                        </a>
                    ),
                },
                {
                    key: 'sendingar',
                    style: hasUserScope('SUBSCRIPTION_TRANSACTION_OVERVIEW')
                        ? {}
                        : { display: 'none' },
                    icon: (
                        <img
                            src="/static/images/svg/box.svg"
                            alt="Sendingar"
                            style={{
                                width: 20,
                                marginRight: 10,
                                marginTop: -5,
                            }}
                        />
                    ),
                    label: (
                        <a href={'/sendingar/' + selectedShop?.uuid + '/'}>
                            Sendingar
                        </a>
                    ),
                },
            ],
        },

        {
            key: 'vorur_grp',
            style:
                hasUserScope('PRODUCTS') || hasUserScope('ORDER_OVERVIEW')
                    ? {}
                    : { display: 'none' },
            label: 'Verslun',
            type: 'group',
            children: [
                {
                    key: 'vorur',
                    style: hasUserScope('PRODUCTS') ? {} : { display: 'none' },
                    icon: (
                        <img
                            style={{ height: 18 }}
                            src="/static/images/svg/tag.svg"
                            alt=""
                        />
                    ),
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <a href={'/vorur/' + selectedShop?.uuid}>Vörur</a>

                            {!collapsed && (
                                <Button
                                    size="small"
                                    type="text"
                                    href={
                                        '/ny_vara/' + selectedShop?.uuid + '/'
                                    }
                                >
                                    +
                                </Button>
                            )}
                        </div>
                    ),
                },
                {
                    style:
                        hasUserScope('ORDER_OVERVIEW') &&
                        selectedShop?.manage_orders
                            ? {}
                            : { display: 'none' },
                    key: 'pantanir',
                    icon: (
                        <img
                            style={{ height: 18 }}
                            src="/static/images/svg/shopping-cart.svg"
                            alt=""
                        />
                    ),
                    label: (
                        <a href={'/pantanir/' + selectedShop?.uuid}>Pantanir</a>
                    ),
                },
                {
                    style:
                        hasUserScope('GIFT_CERTIFICATES') &&
                        selectedShop?.sell_gift_certificates
                            ? {}
                            : { display: 'none' },
                    key: 'gjafabref',
                    icon: (
                        <img
                            style={{ height: 20 }}
                            src="/static/images/svg/surprise.svg"
                            alt=""
                        />
                    ),
                    label: (
                        <a href={'/gjafabref/' + selectedShop?.uuid}>
                            Gjafabréf
                        </a>
                    ),
                },
            ],
        },

        {
            key: 'efni_grp',
            label: 'Streymisveita',
            type: 'group',
            style: selectedShop?.manage_permission_based_content
                ? hasUserScope('REPEAT_MEDIA')
                    ? {}
                    : { display: 'none' }
                : { display: 'none' },
            children: [
                {
                    key: 'efnisyfirlit',
                    icon: (
                        <img
                            style={{
                                height: 17,
                            }}
                            src="/static/images/svg/media.svg"
                            alt=""
                        />
                    ),
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <a href={'/efnisyfirlit/' + selectedShop?.uuid}>
                                Streymisveita
                            </a>

                            {!collapsed && (
                                <Button
                                    size="small"
                                    type="text"
                                    href={
                                        '/nytt_efni/' + selectedShop?.uuid + '/'
                                    }
                                >
                                    +
                                </Button>
                            )}
                        </div>
                    ),
                },
            ],
        },
        {
            key: 'annad_grp',
            label: 'Stillingar og tölfræði',
            type: 'group',
            style: hasUserScope('SHOP_SETTINGS') ? {} : { display: 'none' },
            children: [
                {
                    key: 'settings',
                    icon: <SettingOutlined />,
                    label: (
                        <a href={'/ny_verslun/' + selectedShop?.uuid}>
                            Stillingar
                        </a>
                    ),
                },
                {
                    key: 'tolfraedi',
                    icon: (
                        <BarChartOutlined
                            style={{
                                position: 'relative',
                                top: 2,
                            }}
                        />
                    ),
                    label: (
                        <a href={'/tolfraedi/' + selectedShop?.uuid}>
                            Tölfræði
                        </a>
                    ),
                },
                {
                    key: 'loggar',
                    icon: <DatabaseOutlined />,
                    label: <a href={'/loggar/' + selectedShop?.uuid}>Loggar</a>,
                    style: hasUserScope('LOG_OVERVIEW')
                        ? {}
                        : { display: 'none' },
                },
                {
                    key: 'repeat_banking',
                    style:
                        selectedShop?.repeat_banking &&
                        hasUserScope('REPEAT_BANKING')
                            ? {}
                            : { display: 'none' },
                    icon: <BankOutlined />,
                    label: (
                        <a href={'/repeat_banking/' + selectedShop?.uuid}>
                            Bankakröfur
                        </a>
                    ),
                },
            ],
        },
    ];

    if (selectedShop || shops?.length > 1) {
        menuItems.push({
            key: 'storepicker_grp',
            label: 'Verslun',
            type: 'group',
            style: shops && (shops?.length || 0) > 1 ? {} : { display: 'none' },
            children: [
                {
                    key: 'storepicker',
                    className: 'repeatStorepicker',
                    label: (
                        <Select
                            showSearch
                            className="repeatShopSelect"
                            defaultValue={selectedShop?.uuid || shops[0]?.uuid}
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onSelect={(value: string) => {
                                const s = shops?.find(
                                    (shop) => shop.uuid === value
                                );
                                if (s && setSelectedShop) {
                                    setSelectedShop(s);
                                }
                            }}
                            optionFilterProp={'label'}
                            options={shops?.map((shop, key) => {
                                return {
                                    label: shop.name,
                                    value: shop.uuid,
                                };
                            })}
                        ></Select>
                    ),
                },
            ],
        });
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                shops,
                selectedShop,
                setSelectedShop,
                shopsLoading,
                userScopes,
            }}
        >
            {isFrontpage ? (
                <div>
                    <Frontpage />
                </div>
            ) : (
                <Layout className="repeatBranded">
                    <Header />

                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            marginTop: 10,
                            marginLeft: 10,
                        }}
                    >
                        {w.me && (
                            <div>
                                <div
                                    style={{
                                        textAlign: collapsed
                                            ? 'center'
                                            : 'right',
                                    }}
                                >
                                    <Button
                                        type="text"
                                        onClick={() => {
                                            setCollapsed(!collapsed);
                                        }}
                                    >
                                        {collapsed ? (
                                            <MenuUnfoldOutlined />
                                        ) : (
                                            <MenuFoldOutlined />
                                        )}
                                    </Button>
                                </div>

                                <Menu
                                    inlineCollapsed={collapsed}
                                    selectedKeys={defaultSelectedKeys}
                                    style={{ width: 256 }}
                                    mode="inline"
                                    items={menuItems}
                                />
                            </div>
                        )}

                        <Layout id="mainContainer">
                            <div className={isFrontpage ? '' : 'pageWrapper'}>
                                <Outlet />
                            </div>
                        </Layout>
                    </div>
                </Layout>
            )}
        </AuthContext.Provider>
    );
}
