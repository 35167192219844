import { useState, useEffect, useContext, useCallback } from 'react';
import { Card, Button, Space, Statistic, Alert } from 'antd';
import { UserOutlined, DatabaseOutlined } from '@ant-design/icons';
import SaltPay from '../SaltPay/SaltPay';
import ContentOverview from '../ContentOverview/ContentOverview';
import Axios from 'axios';
import AuthContext from '../../authContext';

// interfaces
import Shop from '../../interfaces/shop';

/* Components */
import EmailOverview from '../EmailOverview/EmailOverview';
import OrderBox from './OrderBox/OrderBox';
import CopyIframeCodeModal from '../CopyIframeCodeModal/CopyIframeCodeModal';
import ProductOverview from '../ProductOverview/ProductOverview';
import brandingConfig from '../../utils/brandingConfig';
import PublicWelfareModal from './PublicWelfareModal/PublicWelfareModal';
import PaymentProviderLogo from '../PaymentProviderLogo/PaymentProviderLogo';
import SMSOverview from './SMSOverview/SMSOverview';
import LogOverview from '../LogOverview/LogOverview';
import dayjs from 'dayjs';

export interface IWindow extends Window {
    me?: 1;
}

const w: IWindow = window;
interface ShopStatistics {
    shop_uuid: string;
    active_subscriptions: number;
    subscriptions_last_month: number;
    subscriptions_this_month: number;
    unfullfilled_shipments: number;
    unused_gift_certificates: number;
    used_gift_certificates: number;
}

export default function Dashboard() {
    const [clipboardModal, setClipboardModal] = useState<
        { uuid: string; type: 'gift_certificate' | 'product' } | false
    >(false);
    const [shopStatistics, setShopStatistics] = useState<ShopStatistics>();
    const [publicWelfareModal, setPublicWelfareModal] = useState<Shop | false>(
        false
    );
    const [foundErrors, setFoundErrors] = useState(false);

    const { selectedShop, shops, shopsLoading, userScopes } =
        useContext(AuthContext);

    useEffect(() => {
        if (selectedShop) {
            Axios.get(
                '/verslun/api/statistics/?shop=' + selectedShop.uuid
            ).then((rsp) => {
                setShopStatistics(rsp.data);
            });
        }
    }, [selectedShop]);

    const hasUserScope = useCallback(
        (scope: string) => {
            return userScopes === 'ADMIN' || userScopes?.includes(scope);
        },
        [userScopes]
    );

    return (
        <div id="mainDashboard">
            {selectedShop &&
                (userScopes === 'ADMIN' || (userScopes?.length || 0) > 0) && (
                    <Card
                        loading={shopsLoading}
                        className="mainDashboardCard"
                        title={
                            <h2
                                style={{
                                    margin: 0,
                                    fontSize: 24,
                                }}
                            >
                                {selectedShop.name}
                            </h2>
                        }
                        extra={
                            <Space>
                                {
                                    <Button
                                        onClick={() =>
                                            setPublicWelfareModal(selectedShop)
                                        }
                                        hidden={
                                            ![
                                                // '4d202eff-3414-4d2f-93e4-4872480f76a6',
                                                '0bee4608-d910-4981-a9ff-ddae03442e28', // Hjálpræðisherinn
                                                '537d5a65-7023-4f89-afb5-91ef3d046880', //Það er von
                                                // '148f812a-3317-4f17-a070-d784c6b0bc0b',
                                                // '2e6f515b-b71b-4a58-8622-761248fea2a6',
                                                // '4c09d4fb-b700-4e83-84d3-f15a385996a5',
                                                // 'ef4fe9ba-5705-4d59-88ee-96664892c2e6',
                                                // '4c920b7a-5e4c-499a-9974-6dfbee9c0d31',
                                                // '01e9316d-9ebd-4884-b6d6-f1c8a035195d',
                                                // '7b507ddd-bf3d-4f7f-9130-4a2097d9e119', //Local shop
                                            ].includes(selectedShop.uuid)
                                        }
                                    >
                                        <img
                                            style={{
                                                width: 20,
                                                marginRight: 5,
                                            }}
                                            alt="Skatturinn logo"
                                            src="/static/images/svg/rsk.svg"
                                        />{' '}
                                        Almannaheilla samtölur fyrir skattinn
                                    </Button>
                                }

                                {w.me === 1 && (
                                    <Button
                                        href={`/bakendi/shop/shop/${selectedShop.id}/change/`}
                                    >
                                        Bakendi
                                    </Button>
                                )}
                            </Space>
                        }
                    >
                        <div className="mainDashboardCardBody">
                            {hasUserScope('SUBSCRIPTION_OVERVIEW') && (
                                <Card
                                    className="statistics_container"
                                    title={
                                        <>
                                            <img
                                                style={{
                                                    height: 45,
                                                }}
                                                src="/static/images/svg/group.svg"
                                                alt=""
                                            />{' '}
                                            Áskriftir
                                        </>
                                    }
                                    extra={
                                        <Button type="primary">
                                            <a
                                                href={
                                                    '/askriftir/' +
                                                    selectedShop.uuid +
                                                    '/'
                                                }
                                            >
                                                Stjórnborð
                                            </a>
                                        </Button>
                                    }
                                >
                                    <>
                                        <Statistic
                                            loading={!shopStatistics}
                                            title="Virkar áskriftir"
                                            valueStyle={{
                                                fontSize: 35,
                                            }}
                                            value={
                                                shopStatistics?.active_subscriptions
                                            }
                                            prefix={<UserOutlined />}
                                        />
                                        <Statistic
                                            loading={!shopStatistics}
                                            valueStyle={{
                                                color: brandingConfig.successColor,
                                                fontSize: 28,
                                            }}
                                            title="Í þessum mánuði"
                                            value={
                                                '+ ' +
                                                shopStatistics?.subscriptions_this_month
                                            }
                                        />
                                        <Statistic
                                            loading={!shopStatistics}
                                            valueStyle={{
                                                color: brandingConfig.successColor,
                                                fontSize: 28,
                                            }}
                                            title="Í síðasta mánuði"
                                            value={
                                                '+ ' +
                                                shopStatistics?.subscriptions_last_month
                                            }
                                        />
                                    </>
                                </Card>
                            )}

                            {hasUserScope('ORDER_OVERVIEW') &&
                                selectedShop.manage_orders && (
                                    <OrderBox shop={selectedShop}></OrderBox>
                                )}

                            {hasUserScope(
                                'SUBSCRIPTION_TRANSACTION_OVERVIEW'
                            ) && (
                                <Card
                                    className="statistics_container"
                                    title={
                                        <div className="heading">
                                            <img
                                                style={{
                                                    height: 45,
                                                }}
                                                src="/static/images/svg/box.svg"
                                                alt=""
                                            />{' '}
                                            Sendingar
                                        </div>
                                    }
                                    extra={
                                        <Button type="primary">
                                            <a
                                                href={
                                                    '/sendingar/' +
                                                    selectedShop.uuid
                                                }
                                            >
                                                Stjórnborð
                                            </a>
                                        </Button>
                                    }
                                >
                                    <Statistic
                                        loading={!shopStatistics}
                                        valueStyle={{
                                            fontSize: 45,
                                        }}
                                        title="Óuppfylltar og/eða ógreiddar sendingar"
                                        value={
                                            shopStatistics?.unfullfilled_shipments
                                        }
                                    />
                                </Card>
                            )}

                            {hasUserScope('DEVELOPER') && (
                                <Card
                                    hidden={!foundErrors}
                                    title={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 10,
                                            }}
                                        >
                                            <DatabaseOutlined
                                                style={{
                                                    fontSize: 32,
                                                }}
                                            />

                                            <div>
                                                <div>Villur</div>

                                                <label
                                                    style={{
                                                        fontWeight: 'normal',
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {dayjs()
                                                        .subtract(1, 'month')
                                                        .format('D. MMM')}{' '}
                                                    - {dayjs().format('D. MMM')}
                                                </label>
                                            </div>
                                        </div>
                                    }
                                    extra={
                                        <Button
                                            href={
                                                '/loggar/' + selectedShop.uuid
                                            }
                                            type="primary"
                                        >
                                            Stjórnboð
                                        </Button>
                                    }
                                >
                                    <LogOverview
                                        hideHeader={true}
                                        shop={selectedShop}
                                        admin={false}
                                        pageSize={5}
                                        size="small"
                                        hiddenColumns={['shop']}
                                        pagination={false}
                                        onlyErrors={true}
                                        gte={dayjs()
                                            .subtract(1, 'month')
                                            .format('YYYY-MM-DD')}
                                        onResponse={(data) => {
                                            setFoundErrors(data.length > 0);
                                        }}
                                    ></LogOverview>
                                </Card>
                            )}

                            {hasUserScope('REPEAT_MEDIA') &&
                                selectedShop.manage_permission_based_content && (
                                    <ContentOverview
                                        shop={selectedShop}
                                    ></ContentOverview>
                                )}

                            {hasUserScope('GIFT_CERTIFICATES') &&
                                selectedShop.sell_gift_certificates && (
                                    <Card
                                        className="statistics_container"
                                        title={
                                            <>
                                                <img
                                                    style={{
                                                        height: 45,
                                                    }}
                                                    src="/static/images/svg/surprise.svg"
                                                    alt=""
                                                />{' '}
                                                Gjafabréf
                                            </>
                                        }
                                        extra={
                                            <Space>
                                                <Button
                                                    onClick={() => {
                                                        setClipboardModal({
                                                            uuid: selectedShop.uuid,
                                                            type: 'gift_certificate',
                                                        });
                                                    }}
                                                >
                                                    {'</>'}
                                                </Button>
                                                <Button type="primary">
                                                    <a
                                                        href={
                                                            '/gjafabref/' +
                                                            selectedShop.uuid
                                                        }
                                                    >
                                                        Stjórnborð
                                                    </a>
                                                </Button>
                                            </Space>
                                        }
                                    >
                                        <Statistic
                                            loading={!shopStatistics}
                                            title="Ókláruð gjafabréf"
                                            value={
                                                shopStatistics?.unused_gift_certificates
                                            }
                                        />
                                        <Statistic
                                            loading={!shopStatistics}
                                            title="Fullnýtt gjafabréf"
                                            value={
                                                shopStatistics?.used_gift_certificates
                                            }
                                        />
                                    </Card>
                                )}

                            {hasUserScope('SMS_OVERVIEW') &&
                                selectedShop.sms_send_name && (
                                    <SMSOverview
                                        shop={selectedShop}
                                    ></SMSOverview>
                                )}

                            {hasUserScope('CARD_PAYMENTS_OVERVIEW') && (
                                <Card
                                    title={
                                        <div
                                            className="heading"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <PaymentProviderLogo
                                                margin={10}
                                                shop={selectedShop}
                                                maxWidth={80}
                                                opacity={1}
                                            />{' '}
                                        </div>
                                    }
                                    extra={
                                        <Button type="primary">
                                            <a
                                                href={
                                                    '/faerslur/' +
                                                    selectedShop.uuid
                                                }
                                            >
                                                Yfirlit
                                            </a>
                                        </Button>
                                    }
                                >
                                    <SaltPay
                                        isPublic={false}
                                        columns={['name', 'date', 'type']}
                                        hideShowMore={true}
                                        url={`/payment/api/payment_requests/?page_size=5&ordering=-created&shop_uuid=${selectedShop.uuid}`}
                                    ></SaltPay>
                                </Card>
                            )}

                            {hasUserScope('PRODUCTS') && (
                                <ProductOverview
                                    shop={selectedShop}
                                ></ProductOverview>
                            )}

                            {hasUserScope('EMAIL_OVERVIEW') && (
                                <Card
                                    title={
                                        <>
                                            <img
                                                style={{
                                                    height: 45,
                                                }}
                                                src="/static/images/svg/email.svg"
                                                alt=""
                                            />{' '}
                                            Sendir tölvupóstar
                                        </>
                                    }
                                    extra={
                                        <Button type="primary">
                                            <a
                                                href={
                                                    '/tolvupostar/' +
                                                    selectedShop.uuid
                                                }
                                            >
                                                Yfirlit
                                            </a>
                                        </Button>
                                    }
                                >
                                    <EmailOverview
                                        url={
                                            '/tolvupostur/api/message/?page_size=5&ordering=-created&shop__uuid=' +
                                            selectedShop.uuid
                                        }
                                        shopUUID={selectedShop.uuid}
                                    ></EmailOverview>
                                </Card>
                            )}
                        </div>
                    </Card>
                )}

            {(shops?.length || 0) < 1 && !selectedShop && (
                <Card
                    loading={shopsLoading}
                    className="mainDashboardCard"
                    title={shopsLoading ? '' : 'Velkomin/n á repeat.is!'}
                >
                    <Card
                        style={{
                            justifySelf: 'center',
                            gridColumn: '1 / -1',
                            border: 'none',
                            boxShadow: 'none',
                            maxWidth: 600,
                        }}
                        title="Byrjum á því að stofna verslun"
                        extra={
                            <Button
                                size="large"
                                className="mt20 btn-success"
                                type="primary"
                            >
                                <a
                                    style={{ color: '#fff' }}
                                    href="/ny_verslun/"
                                >
                                    + Stofna verslun
                                </a>
                            </Button>
                        }
                    >
                        <div>
                            <div
                                style={{
                                    textAlign: 'justify',
                                    marginBottom: 20,
                                }}
                            >
                                <p>
                                    Til hamingju með nýja aðganginn þinn, við
                                    erum spennt að fá þig með!
                                </p>

                                <p>
                                    Repeat.is býður upp á allt sem þú þarft til
                                    að selja vörur og/eða taka við greiðslum með
                                    reglulegum hætti.
                                </p>

                                <p>
                                    Hafðu í huga að við erum til staðar og
                                    teymið okkar er tilbúið að aðstoða þig,
                                    hvort sem þú hefur spurningar, þarft
                                    leiðsögn eða ef þú vilt að við setjum kerfið
                                    upp fyrir þig/með þér.
                                </p>

                                <p>
                                    En fyrst skalt þú byrja á því að stofna
                                    verslun og skoða þig um.
                                </p>
                            </div>
                            <div>
                                <img
                                    className="mb20 missing_product"
                                    src="/static/images/svg/missingproduct.svg"
                                    alt=""
                                    style={{ maxWidth: 350 }}
                                />
                            </div>
                        </div>
                    </Card>
                </Card>
            )}

            {!!clipboardModal && (
                <CopyIframeCodeModal
                    uuid={clipboardModal.uuid}
                    type={clipboardModal.type}
                    open={!!clipboardModal}
                    onCancel={() => {
                        setClipboardModal(false);
                    }}
                ></CopyIframeCodeModal>
            )}

            {publicWelfareModal && (
                <PublicWelfareModal
                    open={!!publicWelfareModal}
                    shop={publicWelfareModal}
                    onCancel={() => {
                        setPublicWelfareModal(false);
                    }}
                ></PublicWelfareModal>
            )}
        </div>
    );
}
