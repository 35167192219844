import { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
    Shop as CheckoutShop,
    Customer,
} from '../interfaces/repeatCheckoutInterfaces';
import Shop from '../../../interfaces/shop';
import { SimpleCustomer } from '../../../interfaces/customer';
import translate from '../../../translate/translate';
import { Currency } from '../../../interfaces/product';

declare global {
    interface Window {
        teyaCardToken: string;
        Cardinal: any;
    }
}

const Three3DSecureModal = (props: {
    cardNumber: string;
    cvc: string;
    month: string;
    year: string;
    shop: SimpleCustomer['shop'] | Shop | CheckoutShop;
    onSuccess: ({}) => void;
    onCancel: () => void;
    currencyCode: Currency['currency_code'];
    customer?: Customer | SimpleCustomer;
    billingCountry?: string;
}) => {
    const { cardNumber, shop, month, year, onSuccess, onCancel } = props;
    const [iframeSrc, setIframeSrc] = useState<string | undefined | null>();
    const [successHandled, setSuccessHandled] = useState(false);

    useEffect(() => {
        if (shop.straumur || shop.valitor) {
            initializeValitor();
        } else if (shop.salt_pay) {
            initializeSaltPay();
        } else if (shop.verifone) {
            initializeVerifone();
        }

        const handlePostMessage = (e: MessageEvent) => {
            if (e.data.hasOwnProperty('valitorMessage')) {
                setIframeSrc(null);
                if (!successHandled) {
                    setSuccessHandled(true);
                    onSuccess(e.data.valitorMessage);
                }
            }

            if (e.data.hasOwnProperty('teyaMessage')) {
                setIframeSrc(null);
                if (!successHandled) {
                    setSuccessHandled(true);
                    onSuccess({
                        ...e.data.teyaMessage,
                        teyaCardToken: window.teyaCardToken,
                    });
                }
            }
        };

        window.addEventListener('message', handlePostMessage);

        return () => {
            window.removeEventListener('message', handlePostMessage);
            setSuccessHandled(false);

            if (shop.verifone) {
                // @ts-ignore
                Cardinal.off('payments.validated');
                // @ts-ignore
                Cardinal.off('payments.setupComplete');
            }
        };
    }, [successHandled]);

    const initializeSaltPay = () => {
        axios
            .post(`/payment/api/teya_three_d_secure/`, {
                cardNumber: cardNumber,
                expMonth: month,
                expYear: '20' + year,
                cvc: props.cvc,
                shop_uuid: shop.uuid,
                currency_code: props.currencyCode,
            })
            .then((rsp) => {
                const teyaCardToken = rsp.data.cardToken;
                // Making card token available to the event listener
                window.teyaCardToken = teyaCardToken;

                if (['1', '2', '3', '4'].includes(rsp.data.res.MdStatus)) {
                    setIframeSrc(null);
                    onSuccess({
                        ...rsp.data.res,
                        teyaCardToken: teyaCardToken,
                    });
                } else if (!rsp.data.res.RedirectToACSForm) {
                    setIframeSrc(null);
                    onCancel();
                    message.error(
                        translate('error_came_up') +
                            ': ' +
                            rsp.data.res.MdErrorMessage
                    );
                }

                setIframeSrc(rsp.data.res.RedirectToACSForm);
            })
            .catch(() => {
                onCancel();
                setIframeSrc(null);
            });
    };

    const initializeValitor = () => {
        axios
            .post(`/payment/api/valitor_three_d_secure/`, {
                cardNumber: cardNumber,
                expMonth: month,
                expYear: '20' + year,
                shop_uuid: shop.uuid,
            })
            .then((rsp) => {
                setIframeSrc(rsp.data.res.cardVerificationRawResponse);
            });
    };

    const initializeVerifone = () => {
        // @ts-ignore
        Cardinal.configure({
            timeout: 10000,
            maxRetries: 3,
            logging: {
                level: 'verbose',
            },
        });

        axios
            .post(`/payment/api/get_verifone_three_d_secure_jwt/`, {
                shop_uuid: shop.uuid,
            })
            .then((rsp) => {
                const jwt = rsp.data.jwt;

                // @ts-ignore
                Cardinal.setup('init', { jwt });

                // @ts-ignore
                Cardinal.on(
                    'payments.setupComplete',
                    function (setupCompleteData: any) {
                        // @ts-ignore
                        Cardinal.trigger('bin.process', cardNumber)

                            .then(function (results: any) {
                                if (results.Status) {
                                    // Bin profiling was successful. Some merchants may want to only move forward with authentication 	if profiling was successful

                                    const card = {
                                        cardNumber: cardNumber,
                                        expiryMonth: month,
                                        expiryYear: year,
                                        cvv: props.cvc,
                                    };

                                    // @ts-ignore
                                    verifone
                                        .getEncryptedCardDetails(
                                            card,
                                            shop.verifone_encryption_key
                                        )
                                        .then((data: any) => {
                                            const encryptedCard =
                                                data.encryptedCard;

                                            const customer = props.customer;

                                            axios
                                                .post(
                                                    '/payment/api/make_three_d_s_lookup/',
                                                    {
                                                        shop_uuid: shop.uuid,
                                                        name: customer?.name,
                                                        email: customer?.email,
                                                        address:
                                                            customer?.street,
                                                        town: customer?.town,
                                                        encryptedCard:
                                                            encryptedCard,
                                                        currency_code:
                                                            props.currencyCode,
                                                        device_info_id:
                                                            setupCompleteData.sessionId,
                                                        billing_country:
                                                            props.billingCountry,
                                                        merchant_reference:
                                                            setupCompleteData.sessionId,
                                                        setupCompleteData:
                                                            setupCompleteData,
                                                        device_data_info: {
                                                            browser_language:
                                                                navigator.language,
                                                            browser_java_enabled:
                                                                navigator.javaEnabled
                                                                    ? navigator.javaEnabled()
                                                                    : false,
                                                            browser_color_depth:
                                                                (
                                                                    screen.colorDepth ||
                                                                    30
                                                                ).toString(),
                                                        },
                                                    }
                                                )
                                                .then((rsp) => {
                                                    setIframeSrc(null);

                                                    // Signature verification === Y þarf til að hægt sé að halda áfram

                                                    // Understanding the Impact of the different 3D Secure Responses
                                                    // If "PAResStatus" is one of the following:

                                                    // “Y" – 3DS is Successful
                                                    // “A” – 3DS was Attempted
                                                    // And “SignatureVerification” is:
                                                    // “Y” - Contents of the message can be trusted
                                                    // And “enrolled” is:
                                                    // “Y” - Cardholders bank is participating in 3D Secure Process.
                                                    // This will result in a liability shift off the merchant.

                                                    // If "PAResStatus” is one of the following:
                                                    // “N” - Failed
                                                    // “U” - Unavailable
                                                    // “R” - Rejected

                                                    // “C” - Challenge required (Temporary status)

                                                    // Or a blank value
                                                    // Or “SignatureVerification” is:
                                                    // “N” - Signature verification is invalid
                                                    // OR “enrolled” is:
                                                    // “N” - Cardholders bank is not participating in 3D Secure
                                                    // “U” - Enrollments status unavailable
                                                    // “B” - Enrollment status was bypassed

                                                    if (
                                                        rsp.data.enrolled ==
                                                            'Y' &&
                                                        [
                                                            'Y',
                                                            'A',
                                                            'U',
                                                        ].includes(
                                                            rsp.data
                                                                .pares_status
                                                        )
                                                    ) {
                                                        if (!successHandled) {
                                                            setSuccessHandled(
                                                                true
                                                            );
                                                            onSuccess({
                                                                verifonePaymentData:
                                                                    rsp.data,
                                                                verifoneEncryptedCard:
                                                                    encryptedCard,
                                                            });
                                                        }
                                                        // @ts-ignore
                                                        Cardinal.off();
                                                        return;
                                                    } else if (
                                                        ['N', 'R'].includes(
                                                            rsp.data
                                                                .pares_status
                                                        )
                                                    ) {
                                                        message.error(
                                                            translate(
                                                                'rejected_by_card_processor'
                                                            )
                                                        );
                                                        onCancel();
                                                        setIframeSrc(null);
                                                        // @ts-ignore
                                                        Cardinal.off();
                                                        return;
                                                    }

                                                    // @ts-ignore
                                                    Cardinal.continue(
                                                        'cca',
                                                        {
                                                            AcsUrl: rsp.data
                                                                .acs_url,
                                                            Payload:
                                                                rsp.data
                                                                    .payload,
                                                        },
                                                        {
                                                            OrderDetails: {
                                                                TransactionId:
                                                                    rsp.data
                                                                        .transaction_id,
                                                            },
                                                        }
                                                    );
                                                });

                                            // @ts-ignore
                                            Cardinal.on(
                                                'payments.validated',
                                                // @ts-ignore
                                                function (data, jwt) {
                                                    console.log(
                                                        'payments.validateed!!!!!',
                                                        data
                                                    );

                                                    console.log(
                                                        'VALIDATED JWT',
                                                        jwt
                                                    );
                                                    switch (data.ActionCode) {
                                                        case 'SUCCESS':
                                                            // Handle successful transaction, send JWT to backend to verify
                                                            if (
                                                                !successHandled
                                                            ) {
                                                                setSuccessHandled(
                                                                    true
                                                                );
                                                                onSuccess({
                                                                    verifonePaymentData:
                                                                        {
                                                                            ...data,
                                                                            ...data
                                                                                .Payment
                                                                                .ExtendedData,
                                                                        },
                                                                    verifoneEncryptedCard:
                                                                        encryptedCard,
                                                                });
                                                            }
                                                            // @ts-ignore
                                                            Cardinal.off();
                                                            break;

                                                        case 'NOACTION':
                                                            // Handle no actionable outcome
                                                            onCancel();
                                                            setIframeSrc(null);
                                                            // @ts-ignore
                                                            Cardinal.off();
                                                            break;

                                                        case 'FAILURE':
                                                            // Handle failed transaction attempt
                                                            onCancel();
                                                            setIframeSrc(null);
                                                            // @ts-ignore
                                                            Cardinal.off();
                                                            message.error(
                                                                translate(
                                                                    'error_came_up'
                                                                ) +
                                                                    ': ' +
                                                                    data.ErrorNumber
                                                            );
                                                            break;

                                                        case 'ERROR':
                                                            onCancel();
                                                            setIframeSrc(null);
                                                            // @ts-ignore
                                                            Cardinal.off();
                                                            message.error(
                                                                translate(
                                                                    'error_came_up'
                                                                ) +
                                                                    ': ' +
                                                                    data.ErrorNumber
                                                            );

                                                            // Handle service level error
                                                            break;
                                                        default:
                                                            onCancel();
                                                            setIframeSrc(null);
                                                            // @ts-ignore
                                                            Cardinal.off();
                                                            message.error(
                                                                translate(
                                                                    'error_came_up'
                                                                ) +
                                                                    ': ' +
                                                                    data.ErrorNumber
                                                            );
                                                            break;
                                                    }
                                                }
                                            );
                                        });
                                } else {
                                    // Bin profiling failed
                                    onCancel();
                                    message.error(
                                        translate('error_came_up') +
                                            ': ' +
                                            'Bin profiling failed:' +
                                            rsp.data.error
                                    );
                                }

                                // Bin profiling, if this is the card the end user is paying with you may start the CCA flow at this point or 	send the lookup request

                                // Cardinal.start('cca', myOrderObject);
                            })

                            .catch(function (error: any) {
                                console.log('error', error);
                                // An error occurred during profiling
                                onCancel();
                                setIframeSrc(null);
                                // @ts-ignore
                                Cardinal.off();
                                message.error(
                                    translate('error_came_up') + ': ' + error
                                );
                            });
                    }
                );
            });
    };

    return (
        <Modal open={true} footer={false} closable={false}>
            {!iframeSrc && <LoadingOutlined />}

            {iframeSrc && (
                <iframe
                    title="Valitor"
                    style={{ width: '100%', border: 'none', minHeight: 360 }}
                    srcDoc={iframeSrc}
                />
            )}
        </Modal>
    );
};

export default Three3DSecureModal;
