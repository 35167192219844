import { useState, useEffect } from 'react';
import { DroppLocationRes } from '../../interfaces/delivery';
import axios from 'axios';

interface IWindow extends Window {
    uuid?: string;
    chooseDroppLocation?: () => Promise<DroppLocationRes>;
}

interface DroppDeliveryResponse {
    codes: { code: number; town: string; capital: boolean }[];
    flytjandicodes: { code: number; town: string }[];
}

const w: IWindow = window;

const fallBackStoreId = '3d078200-ab08-4775-b159-2aa8d7c30bea';

const useDropp = (
    storeId?: string
): {
    droppSelection: DroppLocationRes | null | undefined;
    openDroppModal: () => void;
    clearDroppSelection: () => void;
    setDroppLocationId: (id: string, storeId?: string) => void;
    droppDeliveryZips?: DroppDeliveryResponse;
    droppLocationList?: DroppLocationRes[];
} => {
    storeId = storeId || fallBackStoreId;

    const [droppSelection, setDroppSelection] = useState<
        DroppLocationRes | null | undefined
    >();

    const [droppLocationList, setDroppLocationList] =
        useState<DroppLocationRes[]>();

    const [droppDeliveryZips, setDroppDeliveryZips] =
        useState<DroppDeliveryResponse>();

    useEffect(() => {
        if (!!!droppDeliveryZips)
            axios
                .get<DroppDeliveryResponse>(
                    'https://api.dropp.is/dropp/api/v1/dropp/location/deliveryzips',
                    {
                        headers: {
                            'x-csrftoken': null,
                        },
                    }
                )
                .then((rsp) => setDroppDeliveryZips(rsp.data));

        if (!!!droppLocationList)
            axios
                .get(
                    `https://api.dropp.is/dropp/api/v1/dropp/locations?store=${
                        storeId || ''
                    }`,
                    {
                        headers: {
                            'x-csrftoken': null,
                        },
                    }
                )
                .then((rsp) => setDroppLocationList(rsp.data.locations));
    }, []);

    const clearDroppSelection = () => {
        setDroppSelection(null);
    };

    const openDroppModal = () => {
        if (storeId && w.chooseDroppLocation) {
            w.chooseDroppLocation().then((res) => {
                setDroppSelection(res);
            });
        }
    };

    const setDroppLocationId = (id: string, storeId?: string) => {
        axios
            .get(
                `https://api.dropp.is/dropp/api/v1/dropp/locations?store=${storeId}`,
                {
                    headers: {
                        'x-csrftoken': null,
                    },
                }
            )
            .then((rsp) => {
                const selectedLocation = rsp.data.locations.find(
                    (location: DroppLocationRes) => location.id == id
                );
                if (selectedLocation) setDroppSelection(selectedLocation);
            });
    };

    return {
        droppSelection,
        clearDroppSelection,
        openDroppModal,
        setDroppLocationId,
        droppDeliveryZips,
        droppLocationList,
    };
};

export default useDropp;
