import React, { useState, useEffect } from 'react';
import Customer from '../../../interfaces/customer';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
    Form,
    InputNumber,
    Select,
    Button,
    Input,
    Table,
    Switch,
    message,
} from 'antd';
import useDropp from '../../../customHooks/Dropp/Dropp';
import AllDeliveryChoices from '../AllDeliveryChoices/AllDeliveryChoices';
import Shop from '../../../interfaces/shop';
import { DeliveryChoicePayload } from '../AllDeliveryChoices/AllDeliveryChoices';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

type Props = {
    customer: Customer;
};

// def calculate_tax_components(total_price_with_tax, tax_rate):
//     """
//     Calculate tax components based on Shopify's tax-inclusive formula.

//     :param total_price_with_tax: The total price including tax
//     :param tax_rate: The tax rate as a decimal (e.g., 0.24 for 24%)
//     :return: A tuple of (price_without_tax, tax_amount)
//     """
//     tax_amount = (tax_rate * total_price_with_tax) / (1 + tax_rate)
//     tax_amount = round(tax_amount, 2)

//     price_without_tax = total_price_with_tax - tax_amount
//     price_without_tax = round(price_without_tax, 2)

//     return price_without_tax, tax_amount

const calculateTaxComponents = (totalPriceWithTax: number, taxRate: number) => {
    const taxAmount = (taxRate * totalPriceWithTax) / (1 + taxRate);
    const priceWithoutTax = totalPriceWithTax - taxAmount;
    return { priceWithoutTax, taxAmount };
};

export default function CreateCustomShopifyOrder({ customer }: Props) {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [shopifyPayload, setShopifyPayload] = useState<any>(null);
    const [selectedStep, setSelectedStep] = useState<'one' | 'two' | 'three'>(
        'one'
    );

    const [selectedProducts, setSelectedProducts] = useState<
        {
            price: number;
            quantity: number;
            variantId: number;
            title: string;
            vsk: number;
        }[]
    >([]);
    const [reRender, setRerender] = useState(false);
    const [shopifyVariants, setShopifyVariants] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [shippingPayload, setShippingPayload] =
        useState<DeliveryChoicePayload | null>(null);
    const getShopifyVariants = async (shop_uuid: string) => {
        const rsp = await axios.get(
            `/askrift/get_all_shopify_products/${shop_uuid}/`
        );
        return rsp.data?.products?.products || [];
    };

    const { data, isLoading } = useQuery({
        queryKey: ['shopify_products', customer.shop_uuid],
        queryFn: () => getShopifyVariants(customer.shop_uuid),
    });

    useEffect(() => {
        if (data) {
            const flat = data.flatMap((product: any) =>
                product.variants.map((variant: any) => ({
                    ...variant,
                    product,
                }))
            );

            console.log(flat);

            setShopifyVariants(flat);
        }
    }, [data]);

    const getShopifyCarrierIdentifier = (paymentProvider: string) => {
        if (
            paymentProvider === 'DROPP_HOMESENT' ||
            paymentProvider === 'DROPP_PICKUP'
        ) {
            return '650f1a14fa979ec5c74d063e968411d4';
        }
        if (
            paymentProvider === 'POSTURINN_DPH' ||
            paymentProvider === 'POSTURINN_DPO' ||
            paymentProvider === 'POSTURINN_DPP' ||
            paymentProvider === 'POSTURINN_DPT'
        ) {
            return '553821baa598cfe4f234867716078187';
        }
        if (paymentProvider === 'EIMSKIP') {
            return '4ee7c0f767aab732ec14e1cb9014a295';
        }
        if (paymentProvider === 'PIKKOLO') {
            return 'b103bceadf9e818f7286d2ae2276931c';
        }
    };

    const getShopifySource = (paymentProvider: string) => {
        if (paymentProvider === 'DROPP_PICKUP') {
            return 'Dropp';
        }
        if (
            paymentProvider === 'POSTURINN_DPH' ||
            paymentProvider === 'POSTURINN_DPO' ||
            paymentProvider === 'POSTURINN_DPP' ||
            paymentProvider === 'POSTURINN_DPT'
        ) {
            return 'Posturinn';
        }
        if (paymentProvider === 'EIMSKIP') {
            return 'EIMSKIP';
        }
        if (paymentProvider === 'PIKKOLO') {
            return 'Pikkoló';
        }
        return null;
    };

    const getShopifyCode = (
        paymentProvider: string,
        externalDeliveryLocationId: string,
        deliveryTitle: string
    ) => {
        if (
            paymentProvider === 'DROPP_PICKUP' &&
            externalDeliveryLocationId &&
            deliveryTitle
        ) {
            return `DROPP_${externalDeliveryLocationId}_${deliveryTitle}`;
        }
        return null;
    };

    return (
        <div>
            <div>
                {selectedStep === 'one' && (
                    <>
                        <Form
                            onValuesChange={(values) => {
                                setRerender(!reRender);
                            }}
                            form={form}
                            layout="vertical"
                            size="large"
                            onFinish={(values) => {
                                if (
                                    values.quantity &&
                                    values.product &&
                                    values.title
                                ) {
                                    setSelectedProducts([
                                        ...selectedProducts,
                                        {
                                            price: values.price,
                                            quantity: values.quantity,
                                            variantId: values.product,
                                            title: values.title,
                                            vsk: values.vsk,
                                        },
                                    ]);

                                    form.resetFields();
                                } else {
                                    message.error(
                                        'Vinsamlegast fylltu út alla reitina'
                                    );
                                }
                            }}
                        >
                            <Form.Item label="Vara" name="product">
                                <Select
                                    showSearch
                                    searchValue={searchValue}
                                    onSearch={(value) => {
                                        setSearchValue(value);
                                    }}
                                    onSelect={(value) => {
                                        const selectedProduct =
                                            shopifyVariants.find(
                                                (product) =>
                                                    product.id === value
                                            );

                                        form.setFieldValue(
                                            'title',
                                            selectedProduct.title
                                        );
                                    }}
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Velja vöru"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={shopifyVariants.map((variant) => ({
                                        label:
                                            variant.title.toLowerCase() ===
                                            'default title'
                                                ? variant.product.title
                                                : variant.title,
                                        value: variant.id,
                                        searchValue:
                                            variant.title.toLowerCase() ===
                                            'default title'
                                                ? variant.product.title
                                                : variant.title,
                                        key: variant.id,
                                    }))}
                                />
                            </Form.Item>

                            <Form.Item label="Title" name="title" hidden>
                                <Input />
                            </Form.Item>

                            <div
                                style={{
                                    display: 'flex',
                                    gap: 10,
                                }}
                            >
                                <Form.Item label="Verð" name="price">
                                    <InputNumber min={0} />
                                </Form.Item>

                                <Form.Item label="Magn" name="quantity">
                                    <InputNumber min={1} />
                                </Form.Item>

                                <Form.Item label="VSK %" name="vsk">
                                    <InputNumber />
                                </Form.Item>
                            </div>

                            <Form.Item>
                                <Button
                                    type="ghost"
                                    htmlType="submit"
                                    size="small"
                                >
                                    + Bæta við pöntun
                                </Button>
                            </Form.Item>
                        </Form>

                        <Table
                            pagination={false}
                            dataSource={selectedProducts}
                            columns={[
                                {
                                    title: 'Vara',
                                    render: (_, record) => {
                                        return (
                                            <div>
                                                <div>{record.title}</div>
                                                <small>
                                                    {record.variantId}
                                                </small>
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: 'Magn',
                                    dataIndex: 'quantity',
                                },
                                {
                                    title: 'Verð',
                                    dataIndex: 'price',
                                },
                                {
                                    title: 'VSK %',
                                    dataIndex: 'vsk',
                                },
                            ]}
                        />

                        {selectedProducts.length > 0 && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    setSelectedStep('two');
                                }}
                            >
                                Næsta skref
                            </Button>
                        )}
                    </>
                )}

                {selectedStep === 'two' && (
                    <div>
                        <AllDeliveryChoices
                            shop={customer.shop as any}
                            onSelect={(payload) => {
                                setShippingPayload(payload);

                                const formValues = form2.getFieldsValue();

                                console.log(payload, formValues);

                                if (!formValues.zip && payload.postalCode) {
                                    console.log('setting zip');
                                    form2.setFieldValue(
                                        'zip',
                                        payload.postalCode
                                    );
                                }

                                if (!formValues.city && payload.deliveryTown) {
                                    console.log('setting city');
                                    form2.setFieldValue(
                                        'province',
                                        payload.deliveryTown
                                    );
                                }

                                if (
                                    !formValues.address1 &&
                                    payload.locationTitle
                                ) {
                                    console.log('setting address1');
                                    form2.setFieldValue(
                                        'address1',
                                        payload.locationTitle
                                    );
                                }

                                setRerender(!reRender);
                            }}
                        />

                        <Form
                            layout="vertical"
                            size="large"
                            form={form2}
                            initialValues={{
                                ...customer,
                                customer_uuid: customer.uuid,
                                country: 'Iceland',
                                province:
                                    customer.town ||
                                    shippingPayload?.deliveryTown,
                                city:
                                    customer.town ||
                                    shippingPayload?.deliveryTown,
                                zip:
                                    customer.postal_code ||
                                    shippingPayload?.postalCode,
                                address1:
                                    shippingPayload?.locationTitle ||
                                    customer.street,
                                first_name: customer.name.split(' ')[0],
                                last_name:
                                    customer.name.split(' ').length > 1
                                        ? customer.name.split(' ')[1]
                                        : customer.name,
                            }}
                        >
                            <Form.Item
                                label="Sendingarkostnaður"
                                name="shipping_price"
                            >
                                <InputNumber min={0} />
                            </Form.Item>

                            <Form.Item label="Nafn" name="name">
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Customer uuid"
                                name="customer_uuid"
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item label="Netfang" name="email">
                                <Input />
                            </Form.Item>

                            <Form.Item label="Sími" name="phone">
                                <Input />
                            </Form.Item>

                            <Form.Item label="Land" name="country">
                                <Input />
                            </Form.Item>

                            <Form.Item label="Bær" name="province">
                                <Input />
                            </Form.Item>

                            <Form.Item label="Street" name="city"></Form.Item>

                            <Form.Item label="Póstnúmer" name="zip">
                                <Input />
                            </Form.Item>
                        </Form>
                        <Button
                            disabled={selectedProducts.length === 0}
                            size="large"
                            block
                            type="primary"
                            onClick={() => {
                                const payload = {
                                    line_items: selectedProducts.map(
                                        (product) => {
                                            const {
                                                priceWithoutTax,
                                                taxAmount,
                                            } = calculateTaxComponents(
                                                product.price,
                                                product.vsk / 100
                                            );
                                            return {
                                                variant_id: product.variantId,
                                                quantity: product.quantity,
                                                price: priceWithoutTax,
                                                tax_lines: [
                                                    {
                                                        rate: product.vsk / 100,
                                                        title: 'VAT',
                                                        price: taxAmount,
                                                    },
                                                ],
                                            };
                                        }
                                    ),
                                    tags: 'Repeat.is',
                                    customer: {
                                        email: customer.email,
                                        first_name: customer.name.split(' ')[0],
                                        last_name:
                                            customer.name.split(' ').length > 1
                                                ? customer.name.split(' ')[1]
                                                : customer.name,
                                        name: customer.name,
                                        uuid: customer.uuid,
                                    },

                                    financial_status: 'paid',
                                    shipping_address: {
                                        zip: form2.getFieldValue('zip'),
                                        city: form2.getFieldValue('city'),
                                        name: form2.getFieldValue('name'),
                                        phone: form2.getFieldValue('phone'),
                                        country: form2.getFieldValue('country'),
                                        address1:
                                            form2.getFieldValue('address1'),
                                        province:
                                            form2.getFieldValue('province'),
                                        last_name:
                                            form2.getFieldValue('last_name'),
                                        first_name:
                                            form2.getFieldValue('first_name'),
                                    },
                                    fulfillment_status: null,
                                    inventory_behaviour:
                                        'decrement_ignoring_policy',
                                } as any;

                                if (shippingPayload) {
                                    const carrierIdentifier =
                                        getShopifyCarrierIdentifier(
                                            shippingPayload.deliveryOption
                                                ?.shipping_provider || ''
                                        );

                                    const shopifyCode = getShopifyCode(
                                        shippingPayload.deliveryOption
                                            ?.shipping_provider || '',
                                        shippingPayload.externalDeliveryLocationId ||
                                            '',
                                        shippingPayload.deliveryOption?.title ||
                                            ''
                                    );

                                    const shippingPrice =
                                        payload.shipping_price || 0;

                                    const { priceWithoutTax, taxAmount } =
                                        calculateTaxComponents(
                                            shippingPrice,
                                            0.24
                                        );

                                    payload.shipping_lines = [
                                        {
                                            code: shopifyCode,
                                            name:
                                                shippingPayload.deliveryOption
                                                    ?.title ||
                                                shippingPayload.title,
                                            price: priceWithoutTax,
                                            title:
                                                shippingPayload.deliveryOption
                                                    ?.title ||
                                                shippingPayload.title,
                                            custom: true,
                                            source: getShopifySource(
                                                shippingPayload.deliveryOption
                                                    ?.shipping_provider || ''
                                            ),
                                            tax_lines: [
                                                {
                                                    rate: 0.24,
                                                    price: taxAmount,
                                                    title: 'Shipping - VAT',
                                                },
                                            ],
                                            carrier_identifier:
                                                carrierIdentifier,
                                        },
                                    ];
                                }

                                setShopifyPayload(payload);
                                setSelectedStep('three');
                            }}
                        >
                            Senda pöntun
                        </Button>
                    </div>
                )}

                {selectedStep === 'three' && (
                    <div>
                        <h1>Staðfesta pöntun?</h1>

                        <AceEditor
                            mode="json"
                            readOnly
                            value={JSON.stringify(shopifyPayload, null, 2)}
                            theme="monokai"
                            setOptions={{
                                useWorker: false,
                            }}
                            showPrintMargin={false}
                            width="100%"
                            height="800px"
                        />

                        <Button
                            type="primary"
                            onClick={async () => {
                                const rsp = await axios.post(
                                    '/verslun/api/create_custom_shopify_order/',
                                    {
                                        shop_uuid: customer.shop_uuid,
                                        shopify_payload: shopifyPayload,
                                    }
                                );

                                if (rsp.status === 200) {
                                    message.success(
                                        'Pöntun búin til í Shopify'
                                    );
                                } else {
                                    message.error('Villa í pöntun');
                                }
                            }}
                            block
                            size="large"
                            style={{ marginTop: 20 }}
                        >
                            Senda pöntun í Shopify
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
