import React, { useState } from 'react';
import {
    Form,
    Card,
    Input,
    Button,
    Select,
    Switch,
    message,
    Modal,
    Space,
    InputNumber,
} from 'antd';
import ShopifyProductsOverivew from '../ShopifyProductsOverview/ShopifyProductsOverivew';
import axios from 'axios';

// Typescript
import Product from '../../interfaces/product';

type Props = {
    product: Product;
};

interface IWindow extends Window {
    me?: number;
}

const w: IWindow = window;

export default function ProductShopifySettings(props: Props) {
    const shopify = props.product.shopify_info;
    const [loading, setLoading] = useState(false);
    const [shopifyProductsModalOpen, setShopifyProductsModalOpen] = useState<
        false | any
    >(false);

    // useEffect(() => {
    //     axios.get(`/verslun/api/product/${props.product.uuid}/`).then((rsp) => {
    //         console.log(rsp);
    //     }, []);
    // });

    return (
        <div>
            <div className="text-right">
                <Space>
                    {w.me === 1 && (
                        <Button
                            size="small"
                            onClick={() => {
                                axios
                                    .post(
                                        '/askrift/get_shopify_variant_info/',
                                        {
                                            shop_uuid: props.product.shop_uuid,
                                            variant_id:
                                                shopify?.shopify_variant_id,
                                        }
                                    )
                                    .then((rsp) => console.log(rsp));
                            }}
                        >
                            Console log variant info
                        </Button>
                    )}

                    <Button
                        size="small"
                        onClick={() => {
                            axios
                                .get(
                                    '/askrift/get_all_shopify_products/' +
                                        props.product.shop_uuid +
                                        '/'
                                )
                                .then((rsp) => {
                                    setShopifyProductsModalOpen(rsp.data);
                                });
                        }}
                    >
                        Shopify vörunúmeralisti
                    </Button>
                </Space>
            </div>
            <Form
                layout="vertical"
                onFinish={(values) => {
                    axios
                        .post('/askrift/validate_shopify_variant_id', {
                            shop_uuid: props.product.shop_uuid,
                            variant_id: values.shopify_variant_id,
                        })
                        .then((rsp) => {
                            const variant_valid = rsp.data.variant_valid;

                            axios
                                .post(
                                    '/verslun/api/create_or_update_product_shopify_settings/',
                                    {
                                        product_uuid: props.product.uuid,
                                        ...values,
                                    }
                                )
                                .then(() => {
                                    if (!variant_valid) {
                                        message.success(
                                            'Stillingar hafa verið vistaðar. Athugaðu samt að þetta virðist ekki vera gildur "Variant ID". Ertu nokkuð að nota "Product ID"?',
                                            4000
                                        );
                                    } else {
                                        message.success('Tókst! 🥳');
                                    }
                                })
                                .catch(() => {
                                    message.error('Það kom upp villa :/');
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        });
                }}
                initialValues={{
                    ...shopify,
                    create_shopify_order:
                        shopify?.create_shopify_order || false,
                }}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    label="Variant ID"
                    name="shopify_variant_id"
                >
                    <Input size="large" autoComplete="off" />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    label='Viltu búa til Shopify "order" þegar þessi vara er seld?'
                    name="create_shopify_order"
                    valuePropName="checked"
                >
                    <Switch
                        checkedChildren="JÁ"
                        unCheckedChildren="NEI"
                    ></Switch>
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    label='Merkja Shopify "order" sem greidda?'
                    name="shopify_paid_status"
                    extra="Athugið að repeat á ekki fleiri samskipti við Shopify varðandi þessa pöntun eftir stofnun."
                >
                    <Select size="large">
                        <Select.Option value="paid">Greitt</Select.Option>
                        <Select.Option value="pending">Ógreitt</Select.Option>
                        <Select.Option value="MATCH_CURRENT_STATUS">
                            Fara eftir repeat.is við stofnun
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    name="shopify_fulfillment_status"
                    label='Merkja sem afreitt "fulfilled" í Shopify'
                >
                    <Select size="large">
                        <Select.Option value="fulfilled">
                            Afgreitt
                        </Select.Option>
                        <Select.Option value={'unfulfilled'}>
                            Óafgreitt
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Þessi reitur er nauðsynlegur',
                        },
                    ]}
                    name="shopify_inventory_behaviour"
                    label="Lagerstaða í Shopify"
                >
                    <Select size="large">
                        <Select.Option value="bypass">
                            Ekki breyta Shopify lagerstöðu
                        </Select.Option>
                        <Select.Option value="decrement_ignoring_policy">
                            Hunsa lagerstöðureglu og lækka hana sama hvað
                        </Select.Option>
                        <Select.Option value="decrement_obeying_policy">
                            Fylgja lagerstöðureglu og lækka hana ef það er hægt
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="tax_percent"
                    label="VSK prósenta"
                    help="Mikilvægt að fylla út ef Shopify er tengt við bókhaldskerfi (má annars vera autt)"
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Vista
                    </Button>
                </Form.Item>
            </Form>

            <Modal
                footer={null}
                onCancel={() => setShopifyProductsModalOpen(false)}
                open={shopifyProductsModalOpen}
            >
                <ShopifyProductsOverivew
                    data={shopifyProductsModalOpen}
                    shopUUID={props.product.shop_uuid}
                ></ShopifyProductsOverivew>
            </Modal>
        </div>
    );
}
