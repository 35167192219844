import { ReactElement, useState } from 'react';
import { SubscriptionTransaction } from '../../interfaces/transaction';
import { Button, message } from 'antd';
import { displayCurrencyPrice } from '../../utils';
import axios from 'axios';

interface Props {
    transaction: SubscriptionTransaction;
    onCancel: (refundSuccessfull: boolean) => void;
}

export default function RefundTransaction(props: Props): ReactElement {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <div>
                Ertu viss um að þú viljir endurgreiða þessa færslu? (
                {displayCurrencyPrice(
                    props.transaction.amount,
                    props.transaction.currency_code
                )}
                )
            </div>
            <br></br>
            <Button
                onClick={() => {
                    setLoading(true);
                    axios
                        .post('/askrift/refund_transaction/', {
                            id: props.transaction.id,
                        })
                        .then((rsp) => {
                            setLoading(false);
                            message.success(
                                'Tókst! Færslan hefur verið endurgreidd.'
                            );
                            props.onCancel(true);
                        })
                        .catch((err) => {
                            setLoading(false);
                            message.error(err.response.data.message);
                            props.onCancel(false);
                        });
                }}
                size="large"
                type="primary"
                className="btn-success"
                block
                loading={loading}
            >
                Já, endurgreiða
            </Button>
        </>
    );
}
