import { ReactElement, useState } from 'react';
import { Button, InputNumber, message } from 'antd';
import { displayCurrencyPrice } from '../../utils';
import axios from 'axios';
import Payment from '../../interfaces/payment';
import { Currency } from '../../interfaces/product';

interface Props {
    paymentTransaction: Payment;
    onCancel: () => void;
    onSuccess: () => void;
}

const partialRefundSupported = ['SALT_PAY'];

const getSuffix = (currency: Currency['currency_code']) => {
    if (currency === 'ISK') {
        return ' kr';
    }

    const userLocale = navigator.language || 'us-US';

    const formatted = new Intl.NumberFormat(userLocale, {
        style: 'currency',
        currency: currency,
    }).format(' ');
};

export default function RefundPaymentTransaction(props: Props): ReactElement {
    const amount = (props.paymentTransaction.amount || 1) / 100;
    const [loading, setLoading] = useState(false);
    const [refundAmount, setRefundAmount] = useState(amount);

    console.log(props.paymentTransaction);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}
        >
            <div>
                Ertu viss um að þú viljir endurgreiða þessa færslu? (
                {displayCurrencyPrice(
                    amount,
                    props.paymentTransaction.currency_code
                )}
                )
            </div>

            {partialRefundSupported.includes(
                props.paymentTransaction.payment_provider
            ) && (
                <div>
                    <span>
                        <InputNumber
                            value={refundAmount}
                            onChange={(value) => setRefundAmount(value ?? 0)}
                            max={amount}
                        />
                    </span>

                    <span
                        style={{
                            marginLeft: 10,
                        }}
                    >
                        {getSuffix(props.paymentTransaction.currency_code)}
                    </span>
                </div>
            )}
            <Button
                onClick={() => {
                    setLoading(true);
                    axios
                        .post('/payment/api/refund_payment_transaction/', {
                            id: props.paymentTransaction.id,
                            amount: refundAmount,
                        })
                        .then((rsp) => {
                            setLoading(false);
                            message.success(
                                'Tókst! Færslan hefur verið endurgreidd.'
                            );
                            props.onCancel();
                        })
                        .catch(() => {
                            setLoading(false);
                            message.error(
                                'Því miður tókst ekki að endurgreiða þessa færslu.'
                            );
                            props.onCancel();
                        });
                }}
                size="large"
                type="primary"
                className="btn-success"
                block
                loading={loading}
            >
                Já, endurgreiða
            </Button>
        </div>
    );
}
