import { Button, Form, Input, InputNumber, message } from 'antd';
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import translate from '../../translate/translate';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import axios from 'axios';

type Props = {};
const w = window as any;

export default function KenniShop({}: Props) {
    const [form] = Form.useForm();
    const shopLogo = w.shop_logo;
    const [selectedLanguage, setSelectedLanguage] = useState<'is' | 'en'>('is');
    const [loading, setLoading] = useState(false);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
                gap: '20px',
            }}
        >
            <SelectLanguage
                defaultValue={selectedLanguage}
                onChange={(val) => setSelectedLanguage(val)}
            />
            <div
                style={{
                    backgroundColor: '#fff',
                    maxWidth: '400px',
                    margin: '0 auto',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img
                    src={shopLogo}
                    alt="Shop Logo"
                    style={{
                        maxWidth: '140px',
                    }}
                />

                <Form
                    form={form}
                    style={{
                        width: '100%',
                    }}
                    size="large"
                    layout="vertical"
                    onFinish={(values) => {
                        setLoading(true);

                        axios
                            .get(
                                '/audkenning/get_kenni/' +
                                    w.uuid +
                                    '/' +
                                    values.ssid
                            )
                            .then((res) => {
                                console.log(res);
                                w.location.href = res.data.url;
                            })
                            .catch((err) => {
                                console.log(err);
                                setLoading(false);
                                message.error(
                                    translate('error_came_up', selectedLanguage)
                                );
                            });
                    }}
                >
                    <Form.Item
                        name="ssid"
                        label={translate('ssid', selectedLanguage)}
                        rules={[
                            {
                                required: true,
                                message: translate(
                                    'ssid_is_required',
                                    selectedLanguage
                                ),
                            },
                            {
                                validator: (rule, value, callback) => {
                                    if (
                                        value &&
                                        value.toString().length !== 10
                                    ) {
                                        callback(
                                            translate(
                                                'ssid_length_error',
                                                selectedLanguage
                                            ) as string
                                        );
                                    }
                                    callback();
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            size="large"
                            controls={false}
                            formatter={(value) => {
                                return `${value}`.replace(/[^\d]/g, '');
                            }}
                            parser={(value) => {
                                return value!.replace(/[^\d]/g, '');
                            }}
                            style={{
                                width: '100%',
                                textAlign: 'center',
                            }}
                            maxLength={10}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={loading}
                            style={{
                                background: '#303030',
                                border: 'none',
                                color: '#fff',
                            }}
                            block
                            type="primary"
                            htmlType="submit"
                        >
                            {translate('confirm', selectedLanguage)}
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            <div
                style={{
                    textAlign: 'center',
                }}
            >
                <a href="https://repeat.is" target="blank">
                    <SVG
                        style={{
                            width: '130px',
                            boxSizing: 'initial',
                            paddingBottom: '0px',
                        }}
                        id="hero_logo"
                        baseURL="/"
                        cacheRequests={true}
                        description="Logo"
                        src={'/static/images/logo/logo.svg'}
                        title="Logo"
                        key="hero_logo"
                        uniqueHash="hero_logo"
                        uniquifyIDs={false}
                        fill={'lightgray'}
                    />
                    <div
                        style={{
                            paddingBottom: 35,
                            color: 'lightgray',
                        }}
                    >
                        {translate('powered_by_repeat', selectedLanguage)}
                    </div>
                </a>
            </div>
        </div>
    );
}
