import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Glans from './Glans/Glans';
import translate from '../../translate/translate';
import { useState } from 'react';

type Props = {};

const getLending = async (uuid: string) => {
    const response = await axios.get(`/verslun/lending/${uuid}/`);
    return response.data;
};

export default function Lending({}: Props) {
    const location = useLocation();
    const split = location.pathname.split('/').filter((item) => item);
    const uuid = split[split.length - 1];

    const { data, isLoading } = useQuery({
        queryKey: ['lending', uuid],
        queryFn: () => getLending(uuid),
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (data) {
        if (data.error === 'LANDING_PAGE_EXPIRED') {
            return <div>{translate('token_expired', 'is')}</div>;
        } else if (data.slug === 'GLANS') {
            return <Glans uuid={uuid} />;
        } else {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    Lendingarsíða ekki til
                </div>
            );
        }
    }
}
