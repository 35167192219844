import axios from 'axios';
import Customer from '../interfaces/customer';
import Shop from '../interfaces/shop';
import Subscription, {
    CronConfig,
    PublicSubscription,
    SubscriptionBundle,
    SubscriptionBundleDeliveryInfo,
} from '../interfaces/subscription';
import { SubscriptionTransaction } from '../interfaces/transaction';
import dayjs from 'dayjs';
import { DeliveryConfig, DeliveryOption } from '../interfaces/delivery';
import { SelectedDeliveryOption } from '../Pages/EditCustomerPublic/DeliveryChoicesForNewSubscription';
import { getAvailablePaymentMethods } from '../utils';
import { OrderProduct } from '../interfaces/order';
import Product from '../interfaces/product';

export const createSingleSubscription = (data: {
    shop: Shop;
    customer: Customer;
    cardTokenId?: number;
    productId: number;
    price?: number;
    quantity?: number;
    currencyCode?: string;
    interval?: {
        count: number;
        type: 'MONTH' | 'YEAR' | 'WEEK';
        base_date: string;
    };
    deliveryOption?: {
        external_delivery_location_id?: number;
        delivery_option?: number;
        delivery_postal_code?: string;
        posturinn_location_name?: string;
    } | null;
    paymentMethod?: string;
    chargeNow?: boolean;
    attributes?: {
        [key: string]: any;
    };
    send_order_emails?: boolean;
}) => {
    const {
        shop,
        customer,
        cardTokenId,
        productId,
        price,
        quantity,
        currencyCode,
        interval,
        deliveryOption,
        paymentMethod,
        chargeNow,
        attributes,
        send_order_emails,
    } = data;

    const payload = {
        shop_uuid: shop.uuid,
        product_id: productId,
        customer_uuid: customer.uuid,
        card_token_id: cardTokenId,
    } as any;

    if (price || price === 0) payload.price = price;
    if (currencyCode) payload.currency_code = currencyCode;
    if (quantity) payload.quantity = quantity;
    if (interval) payload.interval = interval;
    if (deliveryOption) payload.delivery = deliveryOption;
    if (paymentMethod) payload.payment_method = paymentMethod;
    if (typeof chargeNow === 'boolean') payload.skip_transaction = !chargeNow;
    if (attributes) payload.attributes = attributes;
    if (send_order_emails) payload.send_order_emails = send_order_emails;

    return axios.post('/askrift/create_single_subscription', payload);
};

const _getSubscriptionPaymentString = (
    subscription: Subscription | PublicSubscription,
    subscriptionTransaction?: SubscriptionTransaction
) => {
    const paymentMethod =
        subscriptionTransaction?.payment_method || subscription.payment_method;

    const cardMethods = getAvailablePaymentMethods('cards');

    let paymentString = '';
    if (paymentMethod === 'BANK_CLAIM') {
        paymentString = 'Krafa';
    } else if (cardMethods.includes(paymentMethod)) {
        paymentString = 'Kort';
    } else if (paymentMethod === 'PAYDAY') {
        paymentString = 'Payday';
    }

    paymentString =
        subscription.type.title === 'Gjafabréf'
            ? subscription.type.title
            : paymentString;

    if (paymentString === '') {
        paymentString = 'Óvitað';
    }

    return paymentString;
};

export const getSubscriptionPaymentString = _getSubscriptionPaymentString;

export const getTransactionPaymentString = (
    subscriptionTransaction: SubscriptionTransaction
) => {
    if (subscriptionTransaction.is_free) return 'Frítt';
    if (
        subscriptionTransaction.is_free &&
        (subscriptionTransaction.payment_request_log?.length || 0) > 0
    )
        return 'Kort';
    if (subscriptionTransaction.subscription)
        return _getSubscriptionPaymentString(
            subscriptionTransaction.subscription,
            subscriptionTransaction
        );

    return 'Óvitað';
};

export const getSubscriptionCronDetails = (
    subscription: Subscription | PublicSubscription
) => {
    const subscriptionConfigUUID =
        subscription.subscription_product.cron_transaction?.uuid;
    const productConfigUUID = subscription.product.cron_transaction?.uuid;

    const promises: Promise<{
        data: CronConfig;
    } | null>[] = [];

    if (productConfigUUID) {
        promises.push(
            axios.get(`/verslun/api/transaction_cron/${productConfigUUID}/`)
        );
    } else {
        promises.push(new Promise((resolve) => resolve(null)));
    }

    if (subscriptionConfigUUID) {
        promises.push(
            axios.get(
                `/verslun/api/transaction_cron/${subscriptionConfigUUID}/`
            )
        );
    } else {
        promises.push(new Promise((resolve) => resolve(null)));
    }

    return new Promise<{
        productConfig?: CronConfig;
        subscriptionConfig?: CronConfig;
        activeConfig?: CronConfig;
        activeConfigKey: 'subscriptionConfig' | 'productConfig' | null;
    } | null>((resolve, reject) => {
        Promise.all(promises).then((rsp) => {
            const productConfig = rsp[0]?.data;
            const subscriptionConfig = rsp[1]?.data;
            const activeConfig = subscriptionConfig || productConfig;
            const activeConfigKey = subscriptionConfig
                ? subscriptionConfig
                    ? 'subscriptionConfig'
                    : 'productConfig'
                : null;

            resolve({
                productConfig,
                subscriptionConfig,
                activeConfig,
                activeConfigKey,
            });
        });
    });
};

export const getSubscriptionLifetimePotential = (
    subscription: Subscription
) => {
    const cronDetails = getSubscriptionCronDetails(subscription);

    const { cancel_notice, commitment_period, commitment_period_type } =
        subscription.product;

    const cancelNoticeEndDate = dayjs().add(
        cancel_notice,
        (
            subscription.subscription_product.cron_transaction?.interval_type ||
            'month'
        ).toLowerCase() as 'month' | 'year' | 'week'
    );

    const commitmentPeriodEndDate =
        commitment_period && commitment_period_type
            ? dayjs(subscription.created).add(
                  commitment_period,
                  commitment_period_type.toLowerCase() as
                      | 'month'
                      | 'year'
                      | 'week'
              )
            : null;

    let boundUntil = commitmentPeriodEndDate?.isAfter(cancelNoticeEndDate)
        ? commitmentPeriodEndDate
        : cancelNoticeEndDate;

    return new Promise<{
        pastPayments: dayjs.Dayjs[];
        upcomingPayments: dayjs.Dayjs[];
        mandatoryUpcomingPayments: dayjs.Dayjs[];
        boundUntil: dayjs.Dayjs | null;
        accessUntil: dayjs.Dayjs | null;
        cancelNotice: number;
    }>((resolve) => {
        if (!cronDetails) {
            resolve({
                pastPayments: [],
                upcomingPayments: [],
                mandatoryUpcomingPayments: [],
                boundUntil,
                accessUntil: dayjs().add(cancel_notice + 1, 'month'),
                cancelNotice: cancel_notice,
            });
            return;
        }

        cronDetails.then((c) => {
            if (!c || !c.activeConfig) {
                resolve({
                    pastPayments: [],
                    upcomingPayments: [],
                    mandatoryUpcomingPayments: [],
                    boundUntil,
                    accessUntil: dayjs().add(cancel_notice + 1, 'month'),
                    cancelNotice: cancel_notice,
                });
                return;
            }

            const config = c.activeConfig;
            const {
                interval,
                interval_type,
                base_date,
                base_date_is_signup_date,
            } = config;

            // const {interval: productInterval, interval_type: productIntervalType} = c.productConfig;

            const baseDate = base_date_is_signup_date
                ? subscription.created
                : base_date;

            const now = dayjs();
            let newestDate = dayjs(baseDate);
            const paymentDates = [];

            while (now.diff(newestDate) > 0) {
                paymentDates.push(newestDate);
                newestDate = newestDate.add(
                    interval,
                    interval_type.toLowerCase() as 'month' | 'year' | 'week'
                );
            }

            const newestPaymentDate = paymentDates.length
                ? paymentDates[paymentDates.length - 1]
                : dayjs(baseDate);

            const upcomingPayments = [];

            let nextPayment;
            while (
                upcomingPayments.length <
                (cancel_notice > 20 ? cancel_notice : 20)
            ) {
                nextPayment = (nextPayment || newestPaymentDate).add(
                    interval,
                    interval_type.toLowerCase() as 'month' | 'year' | 'week'
                );
                upcomingPayments.push(nextPayment);
            }

            resolve({
                pastPayments: paymentDates,
                upcomingPayments,
                mandatoryUpcomingPayments: boundUntil
                    ? upcomingPayments.filter(
                          (d) => boundUntil.add(1, 'day').diff(d) > 0
                      )
                    : [],
                boundUntil,
                accessUntil: !cancel_notice
                    ? upcomingPayments[0]
                    : upcomingPayments[cancel_notice - 1],
                cancelNotice: cancel_notice,
            });
        });
    });
};

const _getSubscriptionBundleNextDates = (
    subscriptionBundle: SubscriptionBundle,
    count: number
) => {
    const now = dayjs();

    const { interval, intervalType, date } = subscriptionBundle;

    if (interval && intervalType) {
        const baseDate = dayjs(date);
        const nextDates = [];

        let nextDate = baseDate;

        if (nextDate.diff(now) > 0) {
            nextDates.push(nextDate);
        }

        while (nextDates.length < count) {
            nextDate = nextDate.add(
                interval,
                intervalType.toLowerCase() as 'month' | 'year' | 'week'
            );
            if (nextDate.diff(now) > 0) {
                nextDates.push(nextDate);
            }
        }

        return nextDates;
    }

    return [];
};

export const getSubscriptionBundleNextDates = _getSubscriptionBundleNextDates;

const _getBundleDeliveryInfo = (
    bundle: SubscriptionBundle
): SubscriptionBundleDeliveryInfo => {
    const { subscriptions } = bundle;

    const deliveryOptions = subscriptions

        .map((s) => {
            if (!s.subscription_product) return null;
            const {
                delivery_option,
                external_delivery_location_id,
                delivery_postal_code,
                posturinn_location_name,
            } = s.subscription_product;

            if (!delivery_option) return null;

            const selectedDeliveryOption = {
                external_delivery_location_id,
                delivery_option: delivery_option.id,
                delivery_postal_code,
                posturinn_location_name,
                price: delivery_option.price,
                shipping_provider: delivery_option.shipping_provider,
                title: delivery_option.title,
            };

            return selectedDeliveryOption;
        })
        .filter((d) => d) as SelectedDeliveryOption[];

    const deliveryConfigs = subscriptions
        .map((s) => {
            if (!s.product) return null;
            const { delivery_config } = s.product;
            return delivery_config;
        })
        .filter((d) => (d ? true : false)) as DeliveryConfig[];

    return {
        selectedDeliveryOption:
            deliveryOptions.length > 0 ? deliveryOptions[0] : undefined,
        selectedDeliveryConfig:
            deliveryConfigs.length > 0 ? deliveryConfigs[0] : undefined,
        deliveryOptions: deliveryOptions,
        deliveryConfigs: deliveryConfigs,
    };
};

export const getSubscriptionsAsBundles = (
    subscriptions: Subscription[] | PublicSubscription[]
): SubscriptionBundle[] => {
    const hashMap: { [key: string]: (PublicSubscription | Subscription)[] } =
        {};

    subscriptions.forEach((subscription) => {
        const subscriptionConfig =
            subscription?.subscription_product?.cron_transaction;
        const productConfig = subscription?.product?.cron_transaction;

        const activeConfig = subscriptionConfig || productConfig;

        if (
            activeConfig &&
            activeConfig?.interval &&
            activeConfig?.interval_type
        ) {
            const baseDate = activeConfig?.base_date;
            const interval = activeConfig?.interval;
            const intervalType = activeConfig?.interval_type;
            const baseDateIsSignupDate = activeConfig?.base_date_is_signup_date;
            const actualBaseDate =
                baseDateIsSignupDate || !baseDate
                    ? subscription.created
                    : baseDate;
            const baseDateMoment = dayjs(actualBaseDate);

            const mapKey =
                baseDateMoment.format('YYYY-MM-DD') +
                '_' +
                interval +
                '_' +
                intervalType;

            hashMap[mapKey] = hashMap[mapKey] || [];
            hashMap[mapKey].push(subscription);
        } else {
            const baseDateMoment = dayjs(subscription.created);
            let mapKey = baseDateMoment.format('YYYY-MM-DD');
            hashMap[mapKey] = hashMap[mapKey] || [];
            hashMap[mapKey].push(subscription);
        }
    });

    const res = Object.keys(hashMap).map((key) => {
        let interval, intervalType, date;

        const keyParts = key.split('_');
        const hasUnderscores = keyParts.length > 1;

        if (hasUnderscores) {
            date = keyParts[0];
            interval = parseInt(keyParts[1]);
            intervalType = keyParts[2];
        } else {
            date = key;
        }

        const customer = hashMap[key][0].customer;

        const bundle = {
            key,
            interval,
            intervalType,
            date,
            subscriptions: hashMap[key],
            customer: customer,
        } as SubscriptionBundle;

        const deliveryInfo = _getBundleDeliveryInfo(bundle);
        bundle.deliveryInfo = deliveryInfo;

        const nextDates = _getSubscriptionBundleNextDates(bundle, 4);

        bundle.upcomingDates = nextDates;

        return bundle;
    });

    return res;
};

export const getNextTransactionDate = (subscription: Subscription) => {
    const cronSettings = subscription.subscription_product.cron_transaction
        ? subscription.subscription_product.cron_transaction
        : subscription.product.cron_transaction;

    const fromDate = cronSettings
        ? cronSettings.base_date_is_signup_date
            ? subscription.created
            : cronSettings.base_date
        : '';

    let isToday = false;

    let isTomorrow = false;

    const shipments = [];
    let nextShipment = dayjs(fromDate);
    let futureCount = 0;

    if (
        nextShipment.isAfter(dayjs(new Date()).subtract(1, 'day').endOf('day'))
    ) {
        futureCount += 1;
        shipments.push(nextShipment);
        if (nextShipment.isSame(dayjs(new Date()), 'day')) isToday = true;

        if (nextShipment.isSame(dayjs(new Date()).add(1, 'day'), 'day'))
            isTomorrow = true;
    }

    while (
        futureCount < 1 &&
        cronSettings &&
        cronSettings.interval &&
        subscription.active
    ) {
        const intervalType =
            cronSettings.interval_type === 'MONTH'
                ? 'months'
                : cronSettings.interval_type === 'YEAR'
                  ? 'years'
                  : 'weeks';

        nextShipment = nextShipment.add(cronSettings.interval, intervalType);
        shipments.push(nextShipment);

        if (
            nextShipment.isAfter(
                dayjs(new Date()).subtract(1, 'day').endOf('day')
            )
        )
            futureCount += 1;
    }

    if (
        subscription.transaction_count === 0 &&
        dayjs(new Date()).isBefore(fromDate)
    ) {
        shipments.push(dayjs(fromDate));
    }

    return {
        formattedDate:
            shipments && shipments.length
                ? shipments[shipments.length - 1].format('D. MMM, YYYY')
                : '',
        isToday,
        isTomorrow,
    };
};

export const getSubscriptionCommitmentPeriod = (subscription: Subscription) => {
    const subscriptionProduct = subscription?.subscription_product;

    const { commitment_period, commitment_period_type, cancel_notice } =
        subscription.product;

    const commitmentEndDate =
        commitment_period && commitment_period_type
            ? dayjs(subscriptionProduct.created).add(
                  commitment_period,
                  commitment_period_type.toLowerCase() as
                      | 'day'
                      | 'week'
                      | 'month'
                      | 'year'
              )
            : null;

    const commitmentHasEnded = commitmentEndDate
        ? commitmentEndDate?.isBefore(dayjs())
        : true;

    const cancelNoticeEndDate = dayjs(subscriptionProduct.created).add(
        cancel_notice,
        (subscriptionProduct.cron_transaction?.interval_type ||
            ''.toLowerCase()) as 'month' | 'year' | 'week'
    );

    const cancelNoticeHasEnded = cancel_notice
        ? cancelNoticeEndDate.isBefore(dayjs())
        : true;

    let finalNoticeAndCommitmentDate;

    if (cancelNoticeEndDate && commitmentEndDate) {
        finalNoticeAndCommitmentDate = cancelNoticeEndDate.isAfter(
            commitmentEndDate
        )
            ? cancelNoticeEndDate
            : commitmentEndDate;
    } else if (cancelNoticeEndDate && !commitmentEndDate) {
        finalNoticeAndCommitmentDate = cancelNoticeEndDate;
    } else if (!cancelNoticeEndDate && commitmentEndDate) {
        finalNoticeAndCommitmentDate = commitmentEndDate;
    }

    if (finalNoticeAndCommitmentDate) {
        finalNoticeAndCommitmentDate = finalNoticeAndCommitmentDate.add(
            1,
            'day'
        );
    }

    const freeToCancel = commitmentHasEnded && cancelNoticeHasEnded;

    return {
        commitmentEndDate,
        commitmentHasEnded,

        cancelNoticeEndDate,
        cancelNoticeHasEnded,

        finalNoticeAndCommitmentDate,
        freeToCancel,
    };
};

export const getOrderProductCommitmentPeriod = (orderProduct: OrderProduct) => {
    const { commitment_period, commitment_period_type, cancel_notice } =
        orderProduct.product;

    const commitmentEndDate =
        commitment_period && commitment_period_type
            ? dayjs(orderProduct.created).add(
                  commitment_period,
                  commitment_period_type.toLowerCase() as
                      | 'day'
                      | 'week'
                      | 'month'
                      | 'year'
              )
            : null;

    const commitmentHasEnded = commitmentEndDate
        ? commitmentEndDate?.isBefore(dayjs())
        : true;

    const cancelNoticeEndDate = dayjs(orderProduct.created).add(
        cancel_notice,
        (orderProduct.cron_transaction?.interval_type || ''.toLowerCase()) as
            | 'month'
            | 'year'
            | 'week'
    );

    const cancelNoticeHasEnded = cancel_notice
        ? cancelNoticeEndDate.isBefore(dayjs())
        : true;

    let finalNoticeAndCommitmentDate;

    if (cancelNoticeEndDate && commitmentEndDate) {
        finalNoticeAndCommitmentDate = cancelNoticeEndDate.isAfter(
            commitmentEndDate
        )
            ? cancelNoticeEndDate
            : commitmentEndDate;
    } else if (cancelNoticeEndDate && !commitmentEndDate) {
        finalNoticeAndCommitmentDate = cancelNoticeEndDate;
    } else if (!cancelNoticeEndDate && commitmentEndDate) {
        finalNoticeAndCommitmentDate = commitmentEndDate;
    }

    if (finalNoticeAndCommitmentDate) {
        finalNoticeAndCommitmentDate = finalNoticeAndCommitmentDate.add(
            1,
            'day'
        );
    }

    const freeToCancel = commitmentHasEnded && cancelNoticeHasEnded;

    return {
        commitmentEndDate,
        commitmentHasEnded,

        cancelNoticeEndDate,
        cancelNoticeHasEnded,

        finalNoticeAndCommitmentDate,
        freeToCancel,
    };
};
