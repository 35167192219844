import { useRef, useState, useEffect, ReactNode } from 'react';
import './RepeatCheckout.less';
import {
    Space,
    Button,
    Card,
    Form,
    Input,
    message,
    Alert,
    Modal,
    Select,
} from 'antd';
//@ts-ignore
import { Kennitala } from '../../kennitala.js';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import SVG from 'react-inlinesvg';
import translate from '../../translate/translate';

// Interfaces
import { DeliveryOption } from '../../interfaces/delivery';
import { Currency, RepeatCheckoutProduct } from '../../interfaces/product';
import {
    Customer,
    Shop,
    OrderPayload,
    IWindow,
} from './interfaces/repeatCheckoutInterfaces';

import axios from 'axios';
import {
    displayCurrencyPrice,
    getPostalName,
    getProductTranslation,
    getSelectedAttributeInfo,
} from '../../utils';
import countries from '../../utils/countries';
import ThirdPartyModal from './ThirdPartyModal/ThirdPartyModal';
import PaymentProviderLogo from '../../components/PaymentProviderLogo/PaymentProviderLogo';
import ThreeDSecureModal from './3DSecureModal/3DSecureModal';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import WaitingListModal from '../../components/WaitingListModal/WaitingListModal';
import ShopifyProductCard from './ShopifyProductCard';
import ProductCard from './ProductCard';
import CheckoutFormItems from './CheckoutFormItems.js';
import CustomFormItems from './CustomFormItems.js';
import ProductAddonsTable from './ProductAddonsTable/ProductAddonsTable.js';

const w: IWindow = window;

export default function RepeatCheckout() {
    let errorTimeout: NodeJS.Timeout;

    const [searchParams, setSearchParams] = useSearchParams();
    const initalLanguage = (searchParams.get('language') || 'is') as
        | 'is'
        | 'en';

    const [shop, setShop] = useState<Shop>();
    const [checkoutCurrency, setCheckoutCurrency] = useState(
        w.currency || 'ISK'
    );
    const [customer, setCustomer] = useState<Customer>();
    const [products, setProducts] = useState<RepeatCheckoutProduct[]>(
        w.products || []
    );
    const [form] = Form.useForm();
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const termsRef = useRef<HTMLLabelElement>(null);
    const [totalProductPrice, setTotalProductPrice] = useState(0);
    const [thirdPartyModal, setThirdPartyModal] = useState(false);
    const [disableInputs, setDisableInputs] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<'is' | 'en'>(
        initalLanguage
    );
    const [threeDSecureModal, setThreeDSecureModal] = useState<{
        cardNumber: string;
        cvc: string;
        month: string;
        year: string;
        onSuccess: ({}) => void;
        customer?: Customer;
        billingCountry?: string;
    }>();
    const [waitingListModal, setWaitingListModal] = useState<
        RepeatCheckoutProduct | false
    >(false);
    const [checkoutErrors, setCheckoutErrors] = useState<string[]>([]);
    const [thirdPartyChecked, setThirdPartyChecked] = useState(false);

    // .filter((x) => !!x) is used to remove undefined values from the array
    const addOns = w.products
        ?.flatMap((p) => p.details.add_ons)
        .filter((x) => !!x);

    const [selectedAddons, setSelectedAddons] = useState<
        RepeatCheckoutProduct['details'][]
    >([]);

    // const hasSubscriptions = (w.products || []).find(
    //     (p) => p.details.type === 'SUBSCRIPTION'
    // );
    // const hasOneTimePurchases = (w.products || []).find((p) =>
    //     ['GIFT_CERTIFICATE', 'ONE_TIME'].includes(p.details.type)
    // );

    const outOfStock = (w.products || []).some(
        (p) =>
            !p.details.inventory.in_stock &&
            !p.details.inventory.sell_when_out_of_stock
    );

    const clearCheckoutErrors = () => {
        setCheckoutErrors([]);

        if (errorTimeout) {
            clearTimeout(errorTimeout);
        }
    };

    useEffect(() => {
        if (errorTimeout) {
            clearTimeout(errorTimeout);
        }

        if (checkoutErrors.length > 0) {
            errorTimeout = setTimeout(() => {
                setCheckoutErrors([]);
            }, 10000);
        }
    }, [checkoutErrors]);

    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries());
        let p = {
            ...params,
        };

        if (customer) {
            p = {
                ...p,
                ...customer,
            };
        }

        if (products) {
            products.forEach((product, key) => {
                p = {
                    ...p,
                    [`products.${key}.uuid`]: product.uuid,
                    [`products.${key}.quantity`]: (
                        product.quantity || 1
                    ).toString(),
                };

                if (product.details.attributes) {
                    product.details.attributes.forEach((attribute, i) => {
                        if (
                            attribute.attribute.type === 'SELECT' &&
                            attribute.order_attribute?.option_answers
                        ) {
                            p = {
                                ...p,
                                [`attributes.${key}.${attribute.attribute.uuid}`]:
                                    attribute.order_attribute.option_answers
                                        ?.map((s: { uuid: string }) => s.uuid)
                                        .join(','),
                            };
                        }
                    });
                }
            });
        }

        if (!selectedLanguage) {
            if (params.language || params.lang) {
                if (
                    ['is', 'en', 'IS', 'EN'].includes(
                        params.language || params.lang
                    )
                ) {
                    setSelectedLanguage(
                        params.language.toLocaleLowerCase() as 'is' | 'en'
                    );
                }
            }
        }

        if (p.lz) {
            delete p.lz;
        }

        const newParams = createSearchParams(p);

        setSearchParams(newParams, {
            replace: true,
        });
    }, [shop, products, customer]);

    useEffect(() => {
        if (!thirdPartyChecked && (shop?.rapyd || shop?.onpay)) {
            thirdPartyCheck();
        }

        const DisableDetailInputs = searchParams.get('disable_inputs');

        if (
            DisableDetailInputs &&
            DisableDetailInputs.toLocaleLowerCase() === 'true'
        )
            setDisableInputs(true);
    }, [searchParams]);

    useEffect(() => {
        thirdPartyCheck();

        if (w.customer) {
            setCustomer(w.customer);
        }

        if (w.products) {
            setProducts(w.products);
        }

        if (w.shop) {
            setShop(w.shop);
        }

        if (w.fbq && w.shop?.meta_pixel) {
            let combinedProductPrice = products.reduce((a, b) => {
                const productPrice = getProductPrice(b, checkoutCurrency);
                return a + b.quantity * productPrice;
            }, 0);

            w.fbq('track', 'InitiateCheckout', {
                content_ids: w.products?.map((p) => p.uuid),
                contents: w.products?.map((p) => {
                    return {
                        id: p.uuid,
                        quantity: p.quantity,
                        item_price: getProductPrice(p, checkoutCurrency),
                        content_type: p.details.type,
                    };
                }),
                num_items: w.products?.length,
                currency: checkoutCurrency,
                value: combinedProductPrice,
            });
        }
    }, []);

    useEffect(() => {
        let combinedSubscriptionPrice = 0;

        let combinedProductPrice = products.reduce((a, b) => {
            let productPrice = getProductPrice(b, checkoutCurrency);

            if (b.details.first_time_discount) {
                if (b.details.first_time_discount > 100) {
                    productPrice =
                        productPrice -
                        (b.details.first_time_discount / 100) * productPrice;
                } else if (b.details.first_time_discount === 100) {
                    productPrice = 0;
                }
            }

            if (b.details.type === 'SUBSCRIPTION') {
                combinedSubscriptionPrice += b.quantity * productPrice;
            }

            return a + b.quantity * productPrice;
        }, 0);

        let combinedOneTimePrice = products
            .filter((p) =>
                ['GIFT_CERTIFICATE', 'ONE_TIME'].includes(p.details.type)
            )
            .reduce((a, b) => {
                const productPrice = getProductPrice(b, checkoutCurrency);

                return a + b.quantity * productPrice;
            }, 0);

        if (w.shopifyCart) {
            combinedProductPrice += w.shopifyCart.reduce((a, b) => {
                return (
                    a +
                    parseFloat(b.node.merchandise.priceV2.amount) *
                        b.node.quantity
                );
            }, 0);

            combinedOneTimePrice += w.shopifyCart.reduce((a, b) => {
                return (
                    a +
                    parseFloat(b.node.merchandise.priceV2.amount) *
                        b.node.quantity
                );
            }, 0);
        }

        setTotalProductPrice(combinedProductPrice);
    }, [products]);

    const thirdPartyCheck = () => {
        if (!thirdPartyChecked && !!w.shop?.uuid) {
            const urlParams = new URLSearchParams(window.location.search);
            const customerName = urlParams.get('name');
            const customerEmail = urlParams.get('email');

            if (w.shop?.rapyd) {
                const rapydCustomerId = urlParams.get('rapyd_customer');
                const rapydSuccess = urlParams.get('rapyd_success');

                if (
                    // If we have a name and email but no Rapyd info then we need to redirect to Rapyd
                    !!!rapydCustomerId &&
                    !!!rapydSuccess &&
                    !!customerName &&
                    !!customerEmail
                ) {
                    setThirdPartyChecked(true);
                    axios
                        .get(
                            `/payment/get_rapyd_collect_card_url/${window.top?.location.search}&shop_uuid=${w.shop?.uuid}`
                        )
                        .then((rsp) => {
                            if (rsp.data) {
                                window.top?.location.replace(rsp.data);
                            }
                        });
                } else if (
                    // Otherwise if we dont have ny Rapyd info or names and email then we need to show the modal
                    !!!rapydCustomerId &&
                    !!!rapydSuccess &&
                    !!!customerName &&
                    !!!customerEmail
                ) {
                    setThirdPartyModal(true);
                }
            }

            if (w.shop?.onpay) {
                const onPayUUID = urlParams.get('onpay_uuid');
                const errorCode = urlParams.get('onpay_errorcode');
                const cardMask = urlParams.get('onpay_cardmask');

                if (
                    // If we have a name and email but no OnPay info then we need to redirect to OnPay
                    !!!errorCode &&
                    !!customerName &&
                    !!customerEmail
                ) {
                    setThirdPartyChecked(true);
                    axios
                        .get(
                            `/payment/get_onpay_collect_card_url/${window.top?.location.search}&shop_uuid=${w.shop?.uuid}`
                        )
                        .then((rsp) => {
                            if (rsp.data) {
                                window.top?.location.replace(rsp.data);
                            }
                        });
                } else if (
                    !!!onPayUUID &&
                    !!!errorCode &&
                    !!!customerName &&
                    !!!customerEmail
                ) {
                    setThirdPartyModal(true);
                }
            }
        }
    };

    const termsUrl = shop?.terms_url
        ? shop.terms_url
        : '/skilmalar/' + (shop?.uuid || '');

    const displayErros = (errors: string[]) => {
        const err = errors.map((error) => {
            let message = '';

            switch (error) {
                case 'CURRENCY_NOT_SUPPORTED':
                    message = translate(
                        'currency_not_supported',
                        selectedLanguage
                    ).toString();
                    break;

                case 'PAYMENT_FAILED':
                    message = translate(
                        'payment_failed',
                        selectedLanguage
                    ).toString();
                    break;

                case 'CARD_SAVE_FAILED':
                    message = translate(
                        'card_save_failed',
                        selectedLanguage
                    ).toString();
                    break;
                case 'EMAIL_MISSING':
                    message = translate(
                        'email_required',
                        selectedLanguage
                    ).toString();
                    break;
                case 'NAME_MISSING':
                    message = translate(
                        'name_required',
                        selectedLanguage
                    ).toString();
                    break;
                case 'NO_PRODUCTS':
                    message = translate(
                        'no_items_in_cart',
                        selectedLanguage
                    ).toString();
                    break;
                case 'PRODUCT_UUID_MISSING':
                    message = translate(
                        'product_uuid_missing',
                        selectedLanguage
                    ).toString();
                    break;
                case 'PRODUCT_DOES_NOT_EXIST':
                    message = translate(
                        'product_does_not_exists',
                        selectedLanguage
                    ).toString();
                    break;
                case 'PRODUCT_QUANTITY_MISSING':
                    message = translate(
                        'quantity_missing',
                        selectedLanguage
                    ).toString();
                    break;
                case 'SUBSCRIPTION_EXISTS':
                    message = translate(
                        'subscription_exists',
                        selectedLanguage
                    ).toString();
                    break;
                case 'NO_RAPYD_PAYMENT_METHODS':
                    message = 'Ekkert kort finnst á skrá';
                    break;
                case 'RESTRICTED_PRODUCT':
                    message = translate(
                        'cant_order_unless_on_list',
                        selectedLanguage
                    ).toString();
                    break;
                case 'ADD_ON_PURCHASE_LIMIT_PER_CUSTOMER_EXCEEDED':
                    message = translate(
                        'add_on_purchase_limit_per_customer_exceeded',
                        selectedLanguage
                    ).toString();
                    break;
                default:
                    message = translate(
                        'error_came_up',
                        selectedLanguage
                    ).toString();
                    break;
            }

            return message;
        });

        setCheckoutErrors(err);
    };

    const sendOrderPayload = (payload: OrderPayload) => {
        axios
            .post(`/confirm_repeat_checkout/${shop?.uuid}/`, payload)
            .then((rsp) => {
                if (rsp.data.success) {
                    if (w.fbq && shop?.meta_pixel) {
                        try {
                            w.fbq('track', 'Purchase', {
                                content_ids: payload.products.map(
                                    (p) => p.uuid
                                ),
                                contents: products.map((p) => {
                                    return {
                                        id: p.uuid,
                                        quantity: p.quantity,
                                        item_price: getProductPrice(
                                            p,
                                            checkoutCurrency
                                        ),
                                        content_type: p.details.type,
                                    };
                                }),

                                num_items: products.length,
                                currency: checkoutCurrency,
                                value: rsp.data.order_total,
                            });
                        } catch (error) {
                            console.error('Error sending fbq ', error);
                        }
                    }

                    window.location.href = rsp.data.success_return_url;
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                displayErros(err.response.data.errors || ['ERROR']);
                setLoading(false);
            });
    };

    const confirmOrder = (payload: OrderPayload) => {
        clearCheckoutErrors();

        if (
            shop?.valitor ||
            shop?.verifone ||
            shop?.straumur ||
            (shop?.salt_pay &&
                (shop.use_three_d_secure ||
                    searchParams.get('use_three_d_secure') === 'true'))
        ) {
            setThreeDSecureModal({
                cardNumber: payload.card_number || '',
                cvc: payload.cvc || '',
                month: payload.month || '',
                year: payload.year || '',
                onSuccess: (threeDSecureData) => {
                    setThreeDSecureModal(undefined);
                    sendOrderPayload({
                        ...payload,
                        ...threeDSecureData,
                    });
                },
                customer: customer,
                billingCountry: payload.billing_country,
            });
        } else {
            sendOrderPayload(payload);
        }
    };

    const getProductPrice = (
        product: RepeatCheckoutProduct,
        currency: Currency['currency_code']
    ) => {
        let productPrice = product.details.custom_price
            ? product.details.custom_price
            : product.details.prices.find(
                  (price) => price.currency.currency_code === currency
              )?.amount;

        const extraPrice = product.details.custom_price
            ? 0
            : product.details.attributes?.reduce((acc, attribute) => {
                  const attributeInfo = getSelectedAttributeInfo(
                      attribute.order_attribute,
                      currency
                  );

                  return acc + (attributeInfo?.extraCost || 0);
              }, 0) || 0;

        let finalProductPrice = (productPrice || 0) + extraPrice;

        if (!product.details.custom_price && product.interval_discount) {
            finalProductPrice =
                finalProductPrice -
                (product.interval_discount / 100) * finalProductPrice;
        }

        const discountPercentage = product.details.first_time_discount;
        if (discountPercentage) {
            if (discountPercentage === 100) return 0;

            finalProductPrice =
                finalProductPrice -
                (discountPercentage / 100) * finalProductPrice;
        }

        return finalProductPrice;
    };

    return (
        <div id="repeatCheckout">
            <header>
                <a href="/" target="_blank">
                    {w.custom_checkout_look?.logo ? (
                        <img
                            src={w.custom_checkout_look.logo.image}
                            alt="Logo"
                            style={{
                                maxWidth: 180,
                            }}
                        />
                    ) : (
                        <SVG
                            className="repeatLogo"
                            baseURL="/"
                            cacheRequests={false}
                            description="Teya"
                            preProcessor={(code) => {
                                code = code.replace(
                                    /<path/g,
                                    '<path fill="#000"'
                                );
                                return code;
                            }}
                            src="/static/images/logo/logo.svg"
                            title="Repeat logo"
                            key={'repeat_logo'}
                            uniqueHash={'repeat_logo'}
                            uniquifyIDs={true}
                        />
                    )}

                    <h3>
                        {translate('secure_repeat_checkout', selectedLanguage)}
                    </h3>
                </a>
            </header>

            <main>
                <Form
                    style={{ maxWidth: '100%' }}
                    disabled={loading || outOfStock}
                    initialValues={{
                        ...(w.customer || {}),
                    }}
                    onValuesChange={(changedValues: {
                        //Customer values
                        name?: string;
                        email?: string;
                        ssid?: string;
                        street?: string;
                        postal_code?: string;
                        town?: string;
                        phone?: string;
                    }) => {
                        if (
                            (changedValues.street ||
                                changedValues.postal_code ||
                                changedValues.town ||
                                changedValues.phone ||
                                changedValues.name ||
                                changedValues.ssid ||
                                changedValues.email) &&
                            customer
                        ) {
                            setCustomer({
                                ...customer,
                                ...changedValues,
                            });
                        }

                        if (
                            changedValues.postal_code &&
                            changedValues.postal_code.length === 3
                        ) {
                            const name = getPostalName(
                                changedValues.postal_code
                            );
                            if (name) {
                                form.setFieldsValue({
                                    town: name,
                                });
                            }
                        }

                        if (changedValues.street)
                            form.setFieldsValue({
                                street: changedValues.street,
                            });
                    }}
                    layout="vertical"
                    form={form}
                    size="large"
                    labelCol={{ span: 6 }}
                    onFinish={(values) => {
                        if (!termsAgreed) {
                            message.error({
                                content: translate(
                                    'please_agree_to_terms',
                                    selectedLanguage
                                ),
                            });
                            termsRef.current?.classList.add('pulse');

                            setTimeout(() => {
                                termsRef.current?.classList.remove('pulse');
                            }, 1000);

                            return;
                        }

                        const selectedDeliveryOption =
                            w.delivery_config?.options.find(
                                (d: DeliveryOption) =>
                                    d.id === values.delivery_option
                            );

                        if (!!selectedDeliveryOption) {
                            if (
                                (
                                    selectedDeliveryOption.applicable_postal_codes ||
                                    []
                                ).length > 0
                            ) {
                                if (
                                    !selectedDeliveryOption.applicable_postal_codes?.includes(
                                        values.postal_code
                                    )
                                ) {
                                    message.error(
                                        <div>
                                            <div>
                                                {translate(
                                                    'postal_code_not_accepted',
                                                    selectedLanguage
                                                )}
                                            </div>

                                            <div>
                                                {translate(
                                                    'applicable_postal_codes_are',
                                                    selectedLanguage
                                                )}
                                                :
                                                {(
                                                    selectedDeliveryOption.applicable_postal_codes ||
                                                    []
                                                )?.map((postalCode, key) => {
                                                    return (
                                                        <strong key={key}>
                                                            {key !== 0 && ','}{' '}
                                                            {postalCode}
                                                        </strong>
                                                    );
                                                })}
                                            </div>
                                        </div>,
                                        500
                                    );
                                    return;
                                }
                            }
                        } else if (w.delivery_config) {
                            message.error(
                                translate(
                                    'select_delivery_option',
                                    selectedLanguage
                                )
                            );
                            return;
                        }

                        if (values.ssid) {
                            const kt = new Kennitala(values.ssid);

                            // @ts-ignore
                            if (!kt.valid) {
                                message.error(
                                    translate(
                                        'ssid_is_invalid',
                                        selectedLanguage
                                    )
                                );
                                return;
                            }
                            // @ts-ignore
                            values.ssid = kt.kt;

                            const isPersonal = products.some(
                                (p) => p.details.ssid_type === 'PERSONAL'
                            );
                            const isCompany = products.some(
                                (p) => p.details.ssid_type === 'COMPANY'
                            );

                            if (isPersonal) {
                                if (kt.type !== 'person') {
                                    const invalidSsidMessage =
                                        products.find(
                                            (p) =>
                                                p.details.ssid_type ===
                                                    'PERSONAL' &&
                                                p.details.invalid_ssid_message
                                        )?.details.invalid_ssid_message ||
                                        translate(
                                            'ssid_is_invalid',
                                            selectedLanguage
                                        );

                                    Modal.info({
                                        title: invalidSsidMessage,
                                        content: ReactHtmlParser(
                                            invalidSsidMessage as string
                                        ) as ReactNode,
                                    });

                                    return;
                                }
                            }

                            if (isCompany) {
                                if (kt.type !== 'company') {
                                    const invalidSsidMessage =
                                        products.find(
                                            (p) =>
                                                p.details.ssid_type ===
                                                    'COMPANY' &&
                                                p.details.invalid_ssid_message
                                        )?.details.invalid_ssid_message ||
                                        translate(
                                            'ssid_is_invalid',
                                            selectedLanguage
                                        );

                                    Modal.info({
                                        title: translate(
                                            'ssid_is_invalid',
                                            selectedLanguage
                                        ),
                                        content: ReactHtmlParser(
                                            invalidSsidMessage as string
                                        ) as ReactNode,
                                    });
                                    return;
                                }
                            }
                        }

                        setLoading(true);

                        const externalDeliveryLocationId =
                            values.external_delivery_location_id;

                        if (
                            selectedDeliveryOption?.shipping_provider ===
                                'DROPP_PICKUP' &&
                            !externalDeliveryLocationId
                        ) {
                            message.error(
                                translate(
                                    'select_pickup_location',
                                    selectedLanguage
                                )
                            );
                            setLoading(false);
                            return;
                        }

                        const custom_data =
                            values.custom_data || values.add_ons_custom_data
                                ? {
                                      ...(values.custom_data || {}),
                                      ...(values.add_ons_custom_data || {}),
                                  }
                                : null;

                        const payload = {
                            ...values,
                            custom_data: custom_data,
                            currency: checkoutCurrency,
                            language: selectedLanguage || 'is',
                            delivery: values.delivery_option
                                ? {
                                      external_delivery_location_id:
                                          values.external_delivery_location_id,
                                      delivery_option: values.delivery_option,
                                      delivery_postal_code:
                                          values.posturinn_dpo_postal_code ||
                                          values.posturinn_dpt_postal_code,
                                      posturinn_location_name:
                                          values.posturinn_dpt_name ||
                                          values.posturinn_dpo_name,
                                  }
                                : null,

                            rapyd_customer_id:
                                searchParams.get('rapyd_customer'),
                            onpay_uuid: searchParams.get('onpay_uuid'),
                            onpay_cardmask: searchParams.get('onpay_cardmask'),
                            external_ref: searchParams.get('external_ref'),
                            add_ons: selectedAddons
                                ? selectedAddons.map((p) => {
                                      const product = {
                                          uuid: p.uuid,
                                          //   quantity: p.quantity | 1,
                                          quantity: 1,
                                      } as {
                                          uuid: string;
                                          quantity: number;
                                      };

                                      return product;
                                  })
                                : undefined,

                            products: products.map((p) => {
                                const product = {
                                    uuid: p.uuid,
                                    quantity: p.quantity,
                                    cp: p.details.cp || undefined,
                                    interval_type: p.interval_type,
                                    interval_count: p.interval_count,
                                    attributes: p.details.attributes?.map(
                                        (a) => {
                                            return {
                                                uuid: a.attribute.uuid,
                                                ...a.order_attribute,
                                                option_answers:
                                                    a.order_attribute?.option_answers?.map(
                                                        (o) => o.uuid
                                                    ),
                                            };
                                        }
                                    ),
                                } as {
                                    uuid: string;
                                    quantity: number;
                                    interval_type?: 'MONTH' | 'YEAR' | 'WEEK';
                                    interval_count?: number;
                                };

                                return product;
                            }),
                        };

                        if (w.shopifyCart) {
                            payload.shopify_cart = w.shopifyCart.map((line) => {
                                return {
                                    image: line.node.merchandise.image.url,
                                    variant_id: line.node.merchandise.id,
                                    quantity: line.node.quantity,
                                    title:
                                        line.node.merchandise.title.toLowerCase() ===
                                        'default title'
                                            ? line.node.merchandise.product
                                                  .title
                                            : line.node.merchandise.title,
                                };
                            });
                        }

                        confirmOrder(payload);
                    }}
                >
                    <div className="formContainer">
                        {thirdPartyChecked && (
                            <Card
                                title={translate(
                                    'redirecing_to_card_issuer',
                                    selectedLanguage
                                )}
                                loading={true}
                                style={{ flex: 3 }}
                            ></Card>
                        )}

                        {!thirdPartyChecked && (
                            <div className="formInputs">
                                <h2
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>
                                        {translate(
                                            'buyer_information',
                                            selectedLanguage
                                        )}
                                    </span>

                                    <SelectLanguage
                                        defaultValue={
                                            (searchParams.get('language') as
                                                | 'is'
                                                | 'en') ||
                                            (searchParams.get('lang') as
                                                | 'is'
                                                | 'en') ||
                                            'is'
                                        }
                                        onChange={(val: 'is' | 'en') =>
                                            setSelectedLanguage(val)
                                        }
                                    ></SelectLanguage>
                                </h2>

                                {outOfStock && (
                                    <Alert
                                        type="error"
                                        message={translate(
                                            'sold_out',
                                            selectedLanguage
                                        )}
                                        style={{
                                            marginTop: 20,

                                            borderRadius: 10,
                                        }}
                                        description={
                                            <>
                                                <p>
                                                    {translate(
                                                        'sold_out_message',
                                                        selectedLanguage
                                                    )}
                                                </p>
                                                {products
                                                    .filter(
                                                        (p) =>
                                                            !p.details.inventory
                                                                .in_stock &&
                                                            !p.details.inventory
                                                                .sell_when_out_of_stock
                                                    )
                                                    .map((product, key) => {
                                                        return (
                                                            <div key={key}>
                                                                <div>
                                                                    <strong>
                                                                        {
                                                                            getProductTranslation(
                                                                                product[
                                                                                    'details'
                                                                                ],
                                                                                selectedLanguage
                                                                            )
                                                                                .title
                                                                        }{' '}
                                                                        {}
                                                                    </strong>

                                                                    {product
                                                                        .details
                                                                        .out_of_stock_waiting_list && (
                                                                        <Button
                                                                            size="small"
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            onClick={() => {
                                                                                setWaitingListModal(
                                                                                    product
                                                                                );
                                                                            }}
                                                                        >
                                                                            {translate(
                                                                                'join_waiting_list',
                                                                                selectedLanguage
                                                                            )}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </>
                                        }
                                    ></Alert>
                                )}

                                {w.restricted_to_emails && (
                                    <Alert
                                        type="error"
                                        message={translate(
                                            'cart_is_restricted_to_emails',
                                            selectedLanguage
                                        )}
                                        style={{
                                            marginTop: 20,
                                            borderRadius: 10,
                                        }}
                                        description={
                                            <>
                                                <p>
                                                    {translate(
                                                        'cart_is_restricted_to_emails_message',
                                                        selectedLanguage
                                                    )}
                                                </p>
                                            </>
                                        }
                                    ></Alert>
                                )}

                                {w.restricted_to_ssids && (
                                    <Alert
                                        type="error"
                                        message={translate(
                                            'cart_is_restricted_to_ssids',
                                            selectedLanguage
                                        )}
                                        style={{
                                            marginTop: 20,
                                            borderRadius: 10,
                                        }}
                                        description={
                                            <>
                                                <p>
                                                    {translate(
                                                        'cart_is_restricted_to_ssids_message',
                                                        selectedLanguage
                                                    )}
                                                </p>
                                            </>
                                        }
                                    ></Alert>
                                )}

                                <Form.Item
                                    hidden
                                    name="external_delivery_location_id"
                                ></Form.Item>
                                <Form.Item
                                    hidden
                                    name="delivery_option"
                                ></Form.Item>
                                <Form.Item
                                    hidden
                                    name="shipping_provider"
                                ></Form.Item>

                                {shop && (
                                    <CheckoutFormItems
                                        selectedLanguage={selectedLanguage}
                                        form={form}
                                        disableInputs={disableInputs}
                                        loading={loading}
                                        outOfStock={outOfStock}
                                        deliveryConfig={w.delivery_config}
                                        infoToCollect={w.info_to_collect}
                                        totalProductPrice={totalProductPrice}
                                        shopUUID={shop.uuid}
                                        onValuesChange={(changedValues) => {
                                            form.setFieldsValue(changedValues);
                                        }}
                                        products={products}
                                    ></CheckoutFormItems>
                                )}

                                <div id="paymentOptionsContainer">
                                    <h2
                                        style={{ marginBottom: 0 }}
                                        className="text-left"
                                    >
                                        {translate(
                                            'card_information',
                                            selectedLanguage
                                        )}
                                    </h2>
                                    <div id="paymentOptions">
                                        <img
                                            alt="logo"
                                            src="/static/images/credit_card_icons/mastercard.svg"
                                        />
                                        <img
                                            alt="logo"
                                            src="/static/images/credit_card_icons/maestro.svg"
                                        />
                                        <img
                                            alt="logo"
                                            src="/static/images/credit_card_icons/visa.svg"
                                        />
                                        <img
                                            alt="logo"
                                            src="/static/images/credit_card_icons/unionpay.svg"
                                        />
                                        <img
                                            alt="logo"
                                            src="/static/images/credit_card_icons/jcb.svg"
                                        />
                                        <img
                                            alt="logo"
                                            src="/static/images/credit_card_icons/dinersclub.svg"
                                        />
                                        <img
                                            alt="logo"
                                            src="/static/images/credit_card_icons/discover.svg"
                                        />
                                    </div>
                                </div>
                                <>
                                    {searchParams.get('rapyd_customer') ||
                                    (searchParams.get('onpay_uuid') &&
                                        searchParams.get('onpay_errorcode') ===
                                            '0') ? (
                                        <div>
                                            <div>
                                                <img
                                                    src="/static/images/svg/card.svg"
                                                    alt="Card"
                                                    style={{
                                                        maxWidth: 120,
                                                        marginTop: 20,
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <SVG
                                                    className="paymentProviderLogo"
                                                    baseURL="/"
                                                    cacheRequests={false}
                                                    description="Rapyd"
                                                    style={{
                                                        maxWidth: 120,
                                                        marginTop: 20,
                                                    }}
                                                    preProcessor={(code) => {
                                                        code = code.replace(
                                                            /<path/g,
                                                            '<path fill="rgba(0,0,0,0.15)" '
                                                        );
                                                        return code;
                                                    }}
                                                    src={
                                                        '/static/images/svg/rapyd.svg'
                                                    }
                                                    title="Rapyd logo"
                                                    key={'rapyd_logo'}
                                                    uniqueHash={'rapyd_logo'}
                                                    uniquifyIDs={true}
                                                />
                                            </div>

                                            <div>
                                                {translate(
                                                    'card_information_is_ready',
                                                    selectedLanguage
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <Form.Item
                                                name="card_number"
                                                label={translate(
                                                    'card_number',
                                                    selectedLanguage
                                                )}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: translate(
                                                            'card_number_required',
                                                            selectedLanguage
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    placeholder={translate(
                                                        'card_number',
                                                        selectedLanguage
                                                    ).toString()}
                                                    size="large"
                                                    autoComplete="cc-number"
                                                    onBlur={async (e) => {
                                                        const countrySelected =
                                                            form.getFieldValue(
                                                                'billing_country'
                                                            );
                                                        if (!countrySelected) {
                                                            const value =
                                                                e.target.value;
                                                            if (
                                                                value?.length >
                                                                8
                                                            ) {
                                                                const firstEight =
                                                                    value.slice(
                                                                        0,
                                                                        8
                                                                    );
                                                                try {
                                                                    const response =
                                                                        await fetch(
                                                                            `/payment/api/get_bin_detail/${firstEight}/`
                                                                        );
                                                                    const binData =
                                                                        await response.json();
                                                                    if (
                                                                        binData.country_alpha2
                                                                    ) {
                                                                        form.setFieldValue(
                                                                            'billing_country',
                                                                            binData.country_alpha2
                                                                        );
                                                                    }
                                                                } catch (error) {
                                                                    console.error(
                                                                        'Error fetching BIN data:',
                                                                        error
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Form.Item>

                                            <div className="cardInfo">
                                                <Form.Item
                                                    name="month"
                                                    label={'MM'}
                                                    className="cardExtra"
                                                    rules={[
                                                        {
                                                            len: 2,
                                                            message:
                                                                'Þessi reitur á að innihalda 2 stafi',
                                                        },
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'field_required',
                                                                selectedLanguage
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder={'MM'}
                                                        maxLength={2}
                                                        autoComplete="cc-exp-month"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="year"
                                                    label={'YY'}
                                                    className="cardExtra"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'field_required',
                                                                selectedLanguage
                                                            ),
                                                        },
                                                        {
                                                            len: 2,
                                                            message:
                                                                'Þessi reitur á að innihalda 2 stafi',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size="large"
                                                        maxLength={2}
                                                        placeholder={'YY'}
                                                        autoComplete="cc-exp-year"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="cvc"
                                                    label="CVC"
                                                    className="cardExtra"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'field_required',
                                                                selectedLanguage
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size="large"
                                                        maxLength={4}
                                                        placeholder="CVC"
                                                        autoComplete="cc-csc"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div>
                                                <Form.Item
                                                    hidden={!shop?.verifone}
                                                    name="billing_country"
                                                    label={translate(
                                                        'billing_country',
                                                        selectedLanguage
                                                    )}
                                                    rules={[
                                                        {
                                                            required:
                                                                shop?.verifone,
                                                            message: translate(
                                                                'field_required',
                                                                selectedLanguage
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        allowClear
                                                        placeholder={translate(
                                                            'billing_country',
                                                            selectedLanguage
                                                        )}
                                                        showSearch
                                                        options={countries}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        {...({
                                                            autoComplete:
                                                                'new-password',
                                                        } as any)}
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) => {
                                                            return option?.label
                                                                ?.toString()
                                                                .toLowerCase()
                                                                .includes(
                                                                    input.toLowerCase()
                                                                );
                                                        }}
                                                    ></Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        )}

                        <div className="dividerLine"></div>

                        <div className="formInfo">
                            <h2>
                                {translate(
                                    'payment_information',
                                    selectedLanguage
                                )}
                            </h2>

                            <Space
                                style={{ display: 'flex' }}
                                direction="vertical"
                            >
                                <Card className="productCard">
                                    {products.map((product, key) => {
                                        return (
                                            <ProductCard
                                                key={product.uuid}
                                                product={product}
                                                currency={checkoutCurrency}
                                                language={selectedLanguage}
                                                intervalDiscounts={
                                                    shop?.interval_discounts
                                                }
                                            ></ProductCard>
                                        );
                                    })}

                                    {w.shopifyCart &&
                                        w.shopifyCart
                                            ?.filter(
                                                (line) =>
                                                    !products.some((p) => {
                                                        const split =
                                                            line.node.id.split(
                                                                '/'
                                                            );
                                                        const variantId =
                                                            split[
                                                                split.length - 1
                                                            ];

                                                        p.details.shopify_info?.shopify_variant_id.toString() ===
                                                            variantId;
                                                    })
                                            )
                                            .map((line, key) => {
                                                return (
                                                    <ShopifyProductCard
                                                        key={line.node.id}
                                                        line={line}
                                                        language={
                                                            selectedLanguage
                                                        }
                                                    ></ShopifyProductCard>
                                                );
                                            })}

                                    {
                                        <div className="mt20">
                                            <label>
                                                {translate(
                                                    'total',
                                                    selectedLanguage
                                                )}
                                            </label>

                                            <h2 className="price">
                                                <span>
                                                    {displayCurrencyPrice(
                                                        totalProductPrice,
                                                        checkoutCurrency
                                                    )}
                                                </span>{' '}
                                            </h2>
                                        </div>
                                    }
                                </Card>

                                {w.shop?.checkout_page_message && (
                                    <div
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            marginTop: 10,
                                            padding: '0 20px',
                                        }}
                                    >
                                        {
                                            ReactHtmlParser(
                                                w.shop.checkout_page_message
                                            ) as React.ReactNode
                                        }
                                    </div>
                                )}

                                {/* <SelectPaymentMethod></SelectPaymentMethod> */}

                                {addOns &&
                                    addOns.filter((addOn) => {
                                        return !selectedAddons?.some((sa) => {
                                            return sa.uuid === addOn.uuid;
                                        });
                                    }).length > 0 && (
                                        <div>
                                            <Card
                                                title={translate(
                                                    'add_addons_to_order',
                                                    selectedLanguage
                                                )}
                                                style={{
                                                    border: 'none',
                                                }}
                                                className="productCard"
                                            >
                                                <ProductAddonsTable
                                                    addOns={addOns.filter(
                                                        (addOn) => {
                                                            return !selectedAddons?.some(
                                                                (sa) =>
                                                                    sa.uuid ===
                                                                    addOn.uuid
                                                            );
                                                        }
                                                    )}
                                                    selectedLanguage={
                                                        selectedLanguage
                                                    }
                                                    currency={checkoutCurrency}
                                                    onAddonSelected={(
                                                        selected
                                                    ) => {
                                                        setSelectedAddons([
                                                            ...(selectedAddons ||
                                                                []),
                                                            selected,
                                                        ]);
                                                    }}
                                                ></ProductAddonsTable>
                                            </Card>
                                        </div>
                                    )}

                                {selectedAddons &&
                                    selectedAddons.length > 0 && (
                                        <Card
                                            title={translate(
                                                'add_ons',
                                                selectedLanguage
                                            )}
                                            className="productCard"
                                        >
                                            <ProductAddonsTable
                                                addOns={selectedAddons}
                                                selectedLanguage={
                                                    selectedLanguage
                                                }
                                                currency={checkoutCurrency}
                                                onAddonRemoved={(selected) => {
                                                    setSelectedAddons(
                                                        selectedAddons.filter(
                                                            (sa) =>
                                                                sa.uuid !==
                                                                selected.uuid
                                                        )
                                                    );
                                                }}
                                            ></ProductAddonsTable>

                                            <div>
                                                <CustomFormItems
                                                    style={{
                                                        padding: 20,
                                                    }}
                                                    parentKey="add_ons_custom_data"
                                                    products={selectedAddons}
                                                    selectedLanguage={
                                                        selectedLanguage
                                                    }
                                                    whenToCollect="CHECKOUT_PAGE"
                                                ></CustomFormItems>
                                            </div>
                                        </Card>
                                    )}

                                <div className="termsAndProvderLogoContainer">
                                    <label
                                        ref={termsRef}
                                        className="termsAgreement"
                                    >
                                        <Input
                                            name="terms_checkbox"
                                            size="large"
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                const checked =
                                                    e.target.checked;

                                                setTermsAgreed(checked);
                                            }}
                                            type="checkbox"
                                            style={{
                                                width: 22,
                                                height: 22,
                                                position: 'absolute',
                                            }}
                                        />

                                        <span
                                            style={{
                                                position: 'relative',
                                                left: 30,
                                            }}
                                        >
                                            {translate(
                                                'agree',
                                                selectedLanguage
                                            )}{' '}
                                            <a
                                                href={termsUrl}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {(
                                                    translate(
                                                        'terms',
                                                        selectedLanguage
                                                    ) as string
                                                )?.toLowerCase()}
                                            </a>
                                        </span>
                                    </label>

                                    <div>
                                        {shop && (
                                            <PaymentProviderLogo
                                                //@ts-ignore
                                                shop={shop}
                                                maxWidth={120}
                                            ></PaymentProviderLogo>
                                        )}
                                    </div>
                                </div>

                                {checkoutErrors.length > 0 && (
                                    <Alert
                                        type="error"
                                        message={translate(
                                            'error_came_up',
                                            selectedLanguage
                                        )}
                                        style={{
                                            marginTop: 20,
                                            borderRadius: 10,
                                        }}
                                        closable
                                        onClose={() => {
                                            setCheckoutErrors([]);
                                        }}
                                        description={
                                            <>
                                                {checkoutErrors.map(
                                                    (error, key) => {
                                                        return (
                                                            <div key={key}>
                                                                <p>{error}</p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </>
                                        }
                                    ></Alert>
                                )}

                                <div className="mt20">
                                    <Button
                                        htmlType="submit"
                                        className="submitButton"
                                        type="primary"
                                        size="large"
                                        block
                                        loading={loading}
                                        disabled={outOfStock || loading}
                                    >
                                        {translate('confirm', selectedLanguage)}
                                    </Button>
                                </div>
                            </Space>
                        </div>
                    </div>
                </Form>
            </main>

            <ThirdPartyModal
                open={thirdPartyModal}
                onSubmit={(values) => {
                    setCustomer({
                        ...customer,
                        ...values,
                    });
                }}
                language={selectedLanguage}
            ></ThirdPartyModal>

            {threeDSecureModal && shop && (
                <ThreeDSecureModal
                    onCancel={() => {
                        message.error(
                            translate('payment_cancelled', selectedLanguage)
                        );
                        setThreeDSecureModal(undefined);
                        setLoading(false);
                    }}
                    shop={shop}
                    currencyCode={checkoutCurrency}
                    {...threeDSecureModal}
                ></ThreeDSecureModal>
            )}

            {selectedLanguage && waitingListModal && (
                <WaitingListModal
                    onCancel={() => {
                        setWaitingListModal(false);
                    }}
                    title={waitingListModal.details.title}
                    productUUID={waitingListModal.details.uuid}
                    lang={selectedLanguage}
                    open={!!waitingListModal}
                />
            )}
        </div>
    );
}
