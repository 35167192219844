import { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import {
    Modal,
    Form,
    Input,
    Button,
    message,
    Select,
    Space,
    Tabs,
    Table,
} from 'antd';

import './EmailModal.less';

import { SubscriptionFilterPayload } from '../../interfaces/subscription';
import { SubscriptionTransactionFilterRequest } from '../../interfaces/transaction';
import SaveEmailTemplateModal from './SaveEmailTemplateModal';
import TiptapWrapper from '../TipTapWrapper/TipTapWrapper';
import { Editor } from '@tiptap/react';
import { OrderFilterPayload } from '../../interfaces/order';

interface EmailModalProps {
    subscriptionFilterPayload?: SubscriptionFilterPayload;
    subscriptionTransactionFilterPayload?: SubscriptionTransactionFilterRequest;
    dataType:
        | 'SUBSCRIPTION_FILTERS'
        | 'SUBSCRIPTION_TRANSACTION_FILTERS'
        | 'ORDER_FILTERS'
        | 'EMAIL_LIST';
    subject?: string;
    body?: string;
    selectedCount?: number;
    onCancel: () => void;
    open: boolean;
    to?: string[];
    shop: string;
    orderFilterPayload?: OrderFilterPayload;
}

export default function EmailModal(props: EmailModalProps) {
    const [form] = Form.useForm();
    const titleRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [initalHTML, setInitalHTML] = useState(props.body);
    const [emailTemplates, setEmailTemplates] = useState<
        {
            title: string;
            id: number;
            html: string;
        }[]
    >([]);

    const [saveEmailTemplateModal, setSaveEmailTemplateModal] = useState<
        | false
        | {
              html?: string;
              subject?: string;
          }
    >(false);
    const [deleteEmailTemplateModal, setDeleteEmailTemplateModal] = useState<
        false | number
    >(false);

    useEffect(() => {
        axios
            .get('/tolvupostur/get_email_templates/?shop__uuid=' + props.shop)
            .then((rsp) => {
                setEmailTemplates(rsp.data);
            });

        return () => {
            setEmailTemplates([]);
        };
    }, []);

    const emailSum = props.selectedCount || props.to?.length;
    const editorRef = useRef<Editor | null>(null);
    return (
        <>
            <Modal
                className="email_modal"
                title={`Sendi tölvupóst á ${emailSum} aðila`}
                footer={null}
                onCancel={props.onCancel}
                open={props.open}
                maskClosable={false}
                width={800}
            >
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Senda póst" key="1">
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={(values) => {
                                setLoading(true);

                                const { subject } = values;
                                const body = values.body || props.body;

                                let payload, endpoint;

                                if (props.dataType === 'EMAIL_LIST') {
                                    payload = {
                                        to: props.to || values.to,
                                        shop: props.shop,
                                    };
                                    endpoint = '/tolvupostur/send_email/';
                                } else if (
                                    props.dataType === 'SUBSCRIPTION_FILTERS'
                                ) {
                                    payload = props.subscriptionFilterPayload;
                                    endpoint =
                                        '/tolvupostur/send_emails_to_subscriptions/';
                                } else if (
                                    props.dataType ===
                                    'SUBSCRIPTION_TRANSACTION_FILTERS'
                                ) {
                                    payload =
                                        props.subscriptionTransactionFilterPayload;
                                    endpoint =
                                        '/tolvupostur/send_email_to_subscription_transactions/';
                                } else if (props.dataType === 'ORDER_FILTERS') {
                                    payload = props.orderFilterPayload;
                                    endpoint =
                                        '/tolvupostur/send_emails_to_orders/';
                                }

                                axios
                                    .post(endpoint || '', {
                                        ...payload,
                                        subject,
                                        body,
                                    })
                                    .then(() => {
                                        props.onCancel();
                                        message.success('Tókst!');
                                    })
                                    .catch((err) => {
                                        message.error(
                                            'Úps, eitthvað fór úrskeiðis :/'
                                        );
                                    });
                            }}
                        >
                            {emailTemplates.length > 0 && (
                                <Form.Item>
                                    <Select
                                        size="large"
                                        placeholder="Nota sniðmát (ekki nauðsynlegt)"
                                        onSelect={(id) => {
                                            const selectedTemplate =
                                                emailTemplates.find(
                                                    (template) =>
                                                        template.id === id
                                                );

                                            if (!selectedTemplate) {
                                                return;
                                            }

                                            form.setFieldsValue({
                                                subject: selectedTemplate.title,
                                            });

                                            setInitalHTML(
                                                selectedTemplate.html
                                            );
                                        }}
                                    >
                                        {emailTemplates.map((template) => (
                                            <Select.Option
                                                key={template.id}
                                                value={template.id}
                                            >
                                                {template.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}

                            <Form.Item
                                name="subject"
                                label="Titill"
                                initialValue={props.subject || ''}
                            >
                                <Input ref={titleRef} size="large"></Input>
                            </Form.Item>

                            {!!!props.to && !!!emailSum && (
                                <Form.Item name="to" label="Til">
                                    <Input size="large"></Input>
                                </Form.Item>
                            )}

                            <Form.Item name="body" label="Texti">
                                <TiptapWrapper
                                    initialValue={initalHTML}
                                    onEditorReady={(editor) =>
                                        (editorRef.current = editor)
                                    }
                                    onChange={(value) =>
                                        form.setFieldsValue({ content: value })
                                    }
                                    shopUUID={props.shop}
                                />
                            </Form.Item>
                            <div>
                                <div>
                                    <small>Smelltu til að bæta við: </small>
                                </div>

                                <Space style={{ marginTop: 5 }}>
                                    <Button
                                        size="small"
                                        key={'__customer_first_name__'}
                                        onClick={() => {
                                            if (editorRef.current) {
                                                editorRef.current
                                                    .chain()
                                                    .focus()
                                                    .insertContent(
                                                        '__customer_first_name__'
                                                    )
                                                    .run();
                                            }
                                        }}
                                    >
                                        Fyrsta nafn
                                    </Button>
                                    <Button
                                        size="small"
                                        key={'__customer_full_name__'}
                                        onClick={() => {
                                            if (editorRef.current) {
                                                editorRef.current
                                                    .chain()
                                                    .focus()
                                                    .insertContent(
                                                        '__customer_full_name__'
                                                    )
                                                    .run();
                                            }
                                        }}
                                    >
                                        Fullt nafn
                                    </Button>

                                    <Button
                                        size="small"
                                        key={'__customer_dashboard__'}
                                        onClick={() => {
                                            if (editorRef.current) {
                                                const { view, state } =
                                                    editorRef.current;
                                                const { from, to } =
                                                    view.state.selection;

                                                if (from !== to) {
                                                    editorRef.current
                                                        .chain()
                                                        .focus()
                                                        .extendMarkRange('link')
                                                        .toggleLink({
                                                            href: '__customer_dashboard__',
                                                        })
                                                        // deselect highlighted text
                                                        .focus(to + 1)
                                                        .run();
                                                } else {
                                                    message.error(
                                                        'Dragðu yfir texta áður en þú smellir á takkann.'
                                                    );
                                                }
                                            }
                                        }}
                                    >
                                        Bæta við hlekk á "Mínar síður"
                                    </Button>
                                </Space>
                                <div style={{ marginBottom: 20 }}>
                                    <small>
                                        Verður bætt við þar sem bendillinn er
                                        staðsettur.
                                    </small>
                                </div>
                            </div>

                            <Form.Item>
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Senda
                                </Button>
                            </Form.Item>

                            <Form.Item
                                style={{
                                    marginBottom: 0,
                                }}
                            >
                                <Button
                                    type="text"
                                    block
                                    onClick={() => {
                                        setSaveEmailTemplateModal({
                                            html: form.getFieldValue('body'),
                                            subject:
                                                form.getFieldValue('subject'),
                                        });
                                    }}
                                >
                                    Vista sem sniðmát
                                </Button>
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Sýsla með sniðmát" key="2">
                        <Table
                            pagination={false}
                            dataSource={emailTemplates}
                            columns={[
                                {
                                    title: 'Titill',
                                    dataIndex: 'title',
                                    key: 'title',
                                },
                                {
                                    title: 'Eyða',
                                    key: 'actions',
                                    render: (record) => (
                                        <Space>
                                            <Button
                                                size="small"
                                                danger
                                                onClick={() => {
                                                    setDeleteEmailTemplateModal(
                                                        record.id
                                                    );
                                                }}
                                            >
                                                Eyða
                                            </Button>
                                        </Space>
                                    ),
                                },
                            ]}
                        ></Table>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>

            {saveEmailTemplateModal && (
                <SaveEmailTemplateModal
                    shop_uuid={props.shop}
                    html={saveEmailTemplateModal.html}
                    subject={saveEmailTemplateModal.subject}
                    onCancel={() => setSaveEmailTemplateModal(false)}
                    onSuccess={(obj) => {
                        setSaveEmailTemplateModal(false);
                        setEmailTemplates((templates) => [
                            ...templates,
                            {
                                title: obj.title,
                                id: obj.id,
                                html: obj.html,
                            },
                        ]);
                        message.success('Sniðmátið hefur verið vistað!');
                    }}
                ></SaveEmailTemplateModal>
            )}

            <Modal
                title="Eyða sniðmát"
                open={!!deleteEmailTemplateModal}
                okText="Eyða"
                onOk={() => {
                    axios
                        .delete(
                            `/tolvupostur/delete_email_template/${deleteEmailTemplateModal}/`
                        )
                        .then(() => {
                            setEmailTemplates((templates) =>
                                templates.filter(
                                    (template) =>
                                        template.id !== deleteEmailTemplateModal
                                )
                            );
                            setDeleteEmailTemplateModal(false);
                        });
                }}
                onCancel={() => setDeleteEmailTemplateModal(false)}
            >
                Ertu viss um að þú viljir eyða þessu sniðmáti? Þessi aðgerð er
                óafturkræf.
            </Modal>
        </>
    );
}
