import React, { useState } from 'react';
import { Currency, RepeatCheckoutProduct } from '../../interfaces/product';
import {
    displayCurrencyPrice,
    getSelectedAttributeInfo,
    getProductTranslation,
} from '../../utils';
import SelectedAttributeInfo from '../../components/SelectedAttributeInfo/SelectedAttributeInfo';
import { Alert, Button, Image, Modal, Space, Typography } from 'antd';
import { RetweetOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import translate from '../../translate/translate';
import ReactHtmlParser from 'react-html-parser';
import dayjs from 'dayjs';
import { IWindow, Shop } from './interfaces/repeatCheckoutInterfaces';

const w: IWindow = window;
const { Paragraph } = Typography;

type Props = {
    product: RepeatCheckoutProduct;
    currency: string;
    language: 'is' | 'en';
    intervalDiscounts?: Shop['interval_discounts'];
};

const getProductPrice = (
    product: RepeatCheckoutProduct,
    currency: Currency['currency_code'],
    intervalDiscounts?: Shop['interval_discounts']
) => {
    const cp = product.details.custom_price;

    const productPrice = cp
        ? cp
        : product.details.prices.find(
              (price) => price.currency.currency_code === currency
          )?.amount;

    const extraPrice = cp
        ? 0
        : product.details.attributes?.reduce((acc, attribute) => {
              const attributeInfo = getSelectedAttributeInfo(
                  attribute.order_attribute,
                  currency
              );

              return acc + (attributeInfo?.extraCost || 0);
          }, 0) || 0;

    const productPriceWithoutDiscount = (productPrice || 0) + extraPrice;

    let intervalDiscountAmount = 0;

    const intervalCount = product.interval_count;
    const intervalType = product.interval_type;

    const intervalDiscount = intervalDiscounts?.find((intervalDiscount) => {
        const productUuid = product.details.uuid;
        const isAffected =
            intervalDiscount.affected_products.includes(productUuid) ||
            intervalDiscount.all_products;

        if (isAffected && intervalCount && intervalType) {
            if (
                intervalDiscount.interval_combinations.some(
                    (intervalCombination: {
                        type: string;
                        count_options: number[];
                    }) => {
                        return (
                            intervalCombination.type === intervalType &&
                            intervalCombination.count_options.includes(
                                intervalCount
                            )
                        );
                    }
                )
            ) {
                return intervalDiscount;
            }
        }
    });
    if (intervalDiscount) {
        intervalDiscountAmount =
            (intervalDiscount.discount / 100) * productPriceWithoutDiscount;
    }

    let firstTimeDiscount = 0;

    const firstTimeDiscountPercentage = product.details.first_time_discount;

    if (firstTimeDiscountPercentage) {
        if (firstTimeDiscountPercentage === 100) {
            firstTimeDiscount = productPriceWithoutDiscount;
        } else {
            firstTimeDiscount =
                (firstTimeDiscountPercentage / 100) *
                productPriceWithoutDiscount;
        }
    }

    let price =
        productPriceWithoutDiscount -
        intervalDiscountAmount -
        firstTimeDiscount;

    if (price < 0) {
        price = 0;
    }

    return {
        price,
        intervalDiscount: intervalDiscountAmount,
        firstTimeDiscount,
        fullPrice: productPriceWithoutDiscount,
    };
};

export default function ProductCard({
    product,
    currency,
    language,
    intervalDiscounts,
}: Props) {
    const [productDetailsModal, setProductDetailsModal] = useState<
        false | RepeatCheckoutProduct['details']
    >(false);

    const { price, intervalDiscount, fullPrice } = getProductPrice(
        product,
        currency,
        intervalDiscounts
    );

    let intro = getProductTranslation(product.details, language).description;

    //Sometimes the description is a html string. If so then we need the first 100 characters
    const isHtmlRegex = /<[a-z][\s\S]*>/i;
    const isHtml = isHtmlRegex.test(intro);

    if (isHtml) {
        try {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(intro, 'text/html');
            intro = htmlDoc.body.textContent || '';

            if (intro.length > 900) {
                intro = intro.slice(0, 900);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div>
            <div className="productInformation">
                <div
                    style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                    }}
                >
                    <img
                        style={{
                            width: 80,
                            marginBottom: 20,
                        }}
                        src={product.details.main_image.image}
                        alt="Vörumynd"
                    />
                    <div
                        style={{
                            color: 'gray',
                            fontSize: 14,
                        }}
                    >
                        {product.details.type === 'SUBSCRIPTION' && (
                            <>
                                <RetweetOutlined />{' '}
                                {translate('subscription', language)}
                            </>
                        )}

                        {product.details.type === 'ONE_TIME' && (
                            <>
                                <ShoppingCartOutlined />{' '}
                                {translate('one_time_purchase', language)}
                            </>
                        )}
                    </div>
                </div>

                <div className="mb20">
                    <div className="productTitle">
                        {getProductTranslation(product.details, language).title}
                    </div>
                    <div className="productPriceDetails">
                        <div>
                            {(intervalDiscount > 0 ||
                                product.details.first_time_discount) && (
                                <div
                                    style={{
                                        textDecoration: 'line-through',
                                        color: 'gray',
                                    }}
                                >
                                    {displayCurrencyPrice(fullPrice, currency)}
                                </div>
                            )}
                            {!intervalDiscount &&
                                !!product.details.price_before_discount && (
                                    <div
                                        style={{
                                            textDecoration: 'line-through',
                                            color: 'gray',
                                        }}
                                    >
                                        {displayCurrencyPrice(
                                            product.details
                                                .price_before_discount,
                                            currency
                                        )}
                                    </div>
                                )}
                            {product.quantity > 1 && (
                                <>
                                    <strong>{product.quantity}</strong> x{' '}
                                </>
                            )}
                            {displayCurrencyPrice(price, currency)}{' '}
                            {product.details.first_time_discount && (
                                <>
                                    {translate(
                                        'now_for_the_first_time',
                                        language
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {!!product.interval_count && product.interval_type && (
                        <div className="productIntervalDetails">
                            {!intervalDiscount &&
                                !product.details.first_time_discount && (
                                    <>
                                        {`${displayCurrencyPrice(
                                            price * product.quantity,
                                            currency
                                        )}`}{' '}
                                        /{' '}
                                    </>
                                )}
                            {product.interval_count}{' '}
                            {product.interval_type === 'WEEK'
                                ? translate('weeks', language)
                                : product.interval_type === 'YEAR'
                                  ? translate('years', language)
                                  : translate('months', language)}{' '}
                            {translate('apart', language)}
                        </div>
                    )}
                    {!!product.details.first_time_discount && (
                        <div>
                            <div className="productIntervalDetails">
                                {translate(
                                    'subscription_price_after_that',
                                    language
                                )}{' '}
                                {displayCurrencyPrice(
                                    fullPrice - intervalDiscount,
                                    currency
                                )}
                            </div>
                        </div>
                    )}

                    {!!product.details.cancel_notice && (
                        <div className="productIntervalDetails">
                            {translate('cancel_notice', language)}{' '}
                            {product.details.cancel_notice}{' '}
                            {translate('times', language)}
                        </div>
                    )}

                    {!!product.details.commitment_period &&
                        product.details.commitment_period_type && (
                            <div className="productIntervalDetails">
                                {translate('commitment_period', language)}:{' '}
                                {product.details.commitment_period}{' '}
                                {translate(
                                    product.details.commitment_period_type,
                                    language
                                )}{' '}
                            </div>
                        )}

                    {!!product.details.transaction_count_limit && (
                        <div className="productIntervalDetails">
                            {translate(
                                'transaction_count_limit_info',
                                language
                            )}{' '}
                            <strong>
                                {product.details.transaction_count_limit}{' '}
                                {translate('times', language)}
                            </strong>
                            {product.details.show_total_price_at_checkout && (
                                <div>
                                    <strong>
                                        {translate('combined', language)}{' '}
                                        {displayCurrencyPrice(
                                            product.details
                                                .transaction_count_limit *
                                                fullPrice,
                                            currency
                                        )}
                                    </strong>
                                </div>
                            )}
                        </div>
                    )}

                    <div>
                        {product.details.attributes?.map((a) => {
                            return (
                                <SelectedAttributeInfo
                                    key={a.attribute.uuid}
                                    attribute={a.attribute}
                                    orderAttribute={a.order_attribute}
                                ></SelectedAttributeInfo>
                            );
                        })}
                    </div>
                    {!!product?.details?.first_payment_day_buffer && (
                        <Alert
                            type="success"
                            style={{
                                marginBottom: 10,
                                marginTop: 10,
                            }}
                            message={`${translate(
                                'first_payment',
                                language
                            )}: ${dayjs(new Date())
                                .add(
                                    product.details.first_payment_day_buffer,
                                    'day'
                                )
                                .format('DD. MMM YYYY')}`}
                        ></Alert>
                    )}
                    <div>
                        <Paragraph
                            className="productDescription"
                            ellipsis={{
                                rows: 4,
                                // @ts-ignore
                                suffix: (
                                    <span key={product.uuid}>
                                        <Button
                                            size="small"
                                            type="link"
                                            style={{
                                                color: 'gray',
                                            }}
                                            onClick={() => {
                                                setProductDetailsModal(
                                                    product.details
                                                );
                                            }}
                                        >
                                            {translate('read_more', language)}
                                        </Button>
                                    </span>
                                ),
                            }}
                        >
                            {ReactHtmlParser(intro) as React.ReactNode}
                        </Paragraph>
                    </div>
                </div>

                {product.details.checkout_page_message && (
                    <div
                        style={{
                            whiteSpace: 'pre-wrap',
                            marginTop: 10,
                            background: '#f0f0f0',
                            padding: 10,
                            borderRadius: 5,
                            marginBottom: 20,
                        }}
                    >
                        {
                            ReactHtmlParser(
                                product.details.checkout_page_message
                            ) as React.ReactNode
                        }
                    </div>
                )}
            </div>

            <Modal
                open={!!productDetailsModal}
                onCancel={() => setProductDetailsModal(false)}
                footer={null}
                title={
                    (productDetailsModal &&
                        getProductTranslation(product.details, language)
                            .title) ||
                    ''
                }
            >
                <Space direction="vertical" size="large">
                    {productDetailsModal && (
                        <div style={{ textAlign: 'center' }}>
                            <Image
                                style={{ maxHeight: 350, width: 'auto' }}
                                src={productDetailsModal.main_image.image}
                                preview={{
                                    mask: (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor:
                                                    'rgba(0, 0, 0, 0.5)',
                                                padding: '5px 15px',
                                            }}
                                        >
                                            {translate(
                                                'click_to_enlarge',
                                                language
                                            )}
                                        </div>
                                    ),
                                }}
                            ></Image>
                        </div>
                    )}

                    <Paragraph>
                        {
                            ReactHtmlParser(
                                productDetailsModal
                                    ? getProductTranslation(
                                          productDetailsModal,
                                          language
                                      ).description
                                    : ''
                            ) as React.ReactNode
                        }
                    </Paragraph>
                </Space>
            </Modal>
        </div>
    );
}
