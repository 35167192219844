import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../authContext';
import axios from 'axios';
import { Button, Card, message, Table, TableColumnType } from 'antd';
import dayjs from 'dayjs';
import { CheckCircleFilled, SyncOutlined } from '@ant-design/icons';
type Props = {};

interface RepeatClaimBatch {
    id: number;
    count: number;
    created: string;
    batch_uuid: string;
    status: string;
    fully_processed: boolean;
    shop: number;
}

const getBatchStatusTitle = (status: string) => {
    switch (status) {
        case 'pending':
            return 'Í vinnslu';
        case 'success':
            return 'Kröfur stofnaðar';
        case 'some_success_some_errors':
            return 'Einhverjar kröfur stofnaðar, en einhverjar villur';
        case 'rejected':
            return 'Villa kom upp við að stofnun krafna';
    }
};

export default function RepeatBankingOverviewPage() {
    const { selectedShop } = useContext(AuthContext);
    const [batches, setBatches] = useState<any[]>([]);
    const [claimsBeingRefetched, setClaimsBeingRefetched] = useState<string[]>(
        []
    );

    useEffect(() => {
        if (selectedShop) {
            axios
                .get(
                    '/banking/overview/' +
                        selectedShop.uuid +
                        '/?ordering=-created&shop_uuid=' +
                        selectedShop.uuid
                )
                .then((rsp) => {
                    setBatches(rsp.data.results);
                });
        }
    }, [selectedShop]);

    const getBatchStatus = (batchUuid: string) => {
        setClaimsBeingRefetched([...claimsBeingRefetched, batchUuid]);
        axios
            .get('/banking/batch/' + batchUuid + '/fetch_status/')
            .then((rsp) => {
                setClaimsBeingRefetched(
                    claimsBeingRefetched.filter((id) => id !== batchUuid)
                );

                setBatches(
                    batches.map((batch) => {
                        if (batch.batch_uuid === batchUuid) {
                            return {
                                ...batch,
                                ...rsp.data.count,
                                status: rsp.data.status,
                            };
                        }
                        return batch;
                    })
                );
            })
            .catch((err) => {
                message.error('Villa kom upp við að sækja stöðu krafa');
                setClaimsBeingRefetched(
                    claimsBeingRefetched.filter((id) => id !== batchUuid)
                );
            });
    };

    const columns = [
        {
            title: 'Fjöldi',
            dataIndex: 'count',
            align: 'center',
        },
        {
            title: '',
            render: (record: RepeatClaimBatch) => {
                return (
                    <div>
                        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                            {dayjs(record.created).format(
                                'D. MMM YYYY - HH:mm'
                            )}
                        </div>
                        <small style={{ color: 'gray' }}>
                            {record.batch_uuid}
                        </small>
                    </div>
                );
            },
        },

        {
            title: 'Greitt',
            dataIndex: 'paid_count',
            align: 'center',
            render: (text: number) => {
                return (
                    <span
                        style={{
                            color: text > 0 ? 'green' : 'gray',
                        }}
                    >
                        {text}
                    </span>
                );
            },
        },
        {
            title: 'Ógreitt',
            dataIndex: 'unpaid_count',
            align: 'center',
            render: (text: number) => {
                return (
                    <span
                        style={{
                            color: text > 0 ? 'orange' : 'gray',
                        }}
                    >
                        {text}
                    </span>
                );
            },
        },
        {
            title: 'Villur',
            dataIndex: 'rejected_count',
            align: 'center',
            render: (text: number) => {
                return (
                    <span
                        style={{
                            color: text > 0 ? 'red' : 'gray',
                        }}
                    >
                        {text}
                    </span>
                );
            },
        },
        {
            title: 'Í vinnslu',
            dataIndex: 'pending_count',
            align: 'center',
            render: (text: number) => {
                return (
                    <span
                        style={{
                            color: text > 0 ? 'orange' : 'gray',
                        }}
                    >
                        {text}
                    </span>
                );
            },
        },
        {
            title: 'Staða bunka',
            dataIndex: 'status',
            align: 'center',
            render: (text: string) => {
                return (
                    <span
                        style={{
                            color: 'gray',
                        }}
                    >
                        {getBatchStatusTitle(text)}
                    </span>
                );
            },
        },

        {
            title: '',
            align: 'center',
            render: (record: RepeatClaimBatch) => {
                return (
                    <Button
                        type="text"
                        onClick={() => getBatchStatus(record.batch_uuid)}
                    >
                        <SyncOutlined
                            spin={claimsBeingRefetched.includes(
                                record.batch_uuid
                            )}
                        />
                    </Button>
                );
            },
        },
    ] as TableColumnType<RepeatClaimBatch>[];

    return (
        <Card
            className="basePageCard"
            title={
                <>
                    <img
                        style={{ height: 20 }}
                        src="/static/images/logo/logo.svg"
                        alt=""
                    />{' '}
                    <small>- Banking</small>
                </>
            }
        >
            <Card>
                <Table
                    size="small"
                    className="white_table"
                    dataSource={batches}
                    columns={columns}
                />
            </Card>
        </Card>
    );
}
