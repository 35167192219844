import React from 'react';
import axios from 'axios';

type Props = {
    uuid: string;
};

export default function Glans({ uuid }: Props) {
    const getLandingAction = async (customerType: string, uuid: string) => {
        return axios.post(`/verslun/api/lending/actions/${uuid}/`, {
            customer_type: customerType,
        });
    };

    return (
        <div id="glansLending">
            {/* <div id="glansLendingInner"></div> */}

            <div id="glansContent">
                <img src="/static/images/partner_logos/glans.svg" alt="Glans" />

                <div>
                    <h2>Veldu það sem á við til að ganga frá áskrift</h2>

                    <div className="glansContentCards">
                        <div
                            className="glansContentCard"
                            onClick={() =>
                                getLandingAction('person', uuid).then((rsp) => {
                                    const redirectUrl = rsp.data.url;
                                    window.location.href = redirectUrl;
                                })
                            }
                        >
                            <div className="glansContentCardInner">
                                Einstaklingur
                            </div>
                        </div>

                        <div
                            className="glansContentCard"
                            onClick={() =>
                                getLandingAction('company', uuid).then(
                                    (rsp) => {
                                        const redirectUrl = rsp.data.url;
                                        window.location.href = redirectUrl;
                                    }
                                )
                            }
                        >
                            <div className="glansContentCardInner">
                                Fyrirtæki
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import './Glans.less';
