import { useEffect, useState } from 'react';
import axios from 'axios';
import Shop from '../../interfaces/shop';
import {
    Badge,
    Button,
    Modal,
    Table,
    PaginationProps,
    Input,
    Dropdown,
} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/is'; //
import LogDetails from './IncomingLogDetails';
import {
    RequestLogOverview,
    IncomingRequestLog,
} from '../../interfaces/requestLog';
dayjs.locale('is'); // use locale

import { debounce } from 'lodash';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
type Props = {
    slugs?: string[];
    shop?: Shop;
    customer?: string;
    hiddenColumns?: string[];
    pageSize?: number;
    size?: 'small' | 'middle' | 'large';
    pagination?: boolean;
    onlyErrors?: boolean;
    gte?: string;
    lte?: string;
    emptyMessage?: React.ReactNode;
    onResponse?: (data: RequestLogOverview[]) => void;
    hideHeader?: boolean;
};

const getSlugDescription = (slug: string) => {
    const slugMapping = {
        GLANS_CHECK_PLATE: 'Glans bílnumer athugað',
        GLANS_SUBSCRIPTION_INITIATION: 'Glans áskriftardrög stofnuð',
        GLANS_KIOSK_SUBSCRIPTION: 'Glans áskriftardrög stofnuð í gegnum kiosk',
    };
    return slugMapping[slug as keyof typeof slugMapping] || slug;
};

export default function IncomingLogOverview(props: Props) {
    const [apiResponses, setApiResponses] = useState<IncomingRequestLog[]>();
    const [featured, setFeatured] = useState<IncomingRequestLog | false>(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [availableSlugs, setAvailableSlugs] = useState<string[]>([]);
    const [selectedSlugs, setSelectedSlugs] = useState<string[]>(
        props.slugs || []
    );
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (selectedPage) {
            setLoading(true);
            axios
                .get(
                    `/api/incoming_request_logs?slug__in=${
                        selectedSlugs ? selectedSlugs.join(',') : ''
                    }&shop__uuid=${
                        props.shop?.uuid
                    }&ordering=-created&page=${selectedPage}&page_size=${50}${
                        props.customer ? '&customers=' + props.customer : ''
                    }${props.onlyErrors ? '&success=false' : ''}${
                        props.gte ? '&created__gte=' + props.gte : ''
                    }${props.lte ? '&created__lte=' + props.lte : ''}${searchQuery ? '&search=' + searchQuery : ''}
                    `
                )
                .then((rsp) => {
                    setApiResponses(rsp.data.results);
                    setCount(rsp.data.count);
                    setLoading(false);
                    setAvailableSlugs(rsp.data.slugs || []);
                    if (props.onResponse) {
                        props.onResponse(rsp.data.results);
                    }
                });
        }
    }, [
        selectedPage,
        props.onlyErrors,
        props.gte,
        props.lte,
        selectedSlugs,
        props.shop,
        props.customer,
        searchQuery,
    ]);

    let columns = [
        {
            title: 'Aðferð',
            key: 'method',
            render: (item: IncomingRequestLog) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div>
                            <Badge
                                status={item.success ? 'success' : 'error'}
                            ></Badge>{' '}
                            <small
                                style={{
                                    marginLeft: 7,
                                }}
                            >
                                {item.status_code}
                            </small>
                            <small
                                style={{
                                    marginLeft: 7,
                                }}
                            >
                                {item.method}
                            </small>
                        </div>

                        <small style={{ color: 'gray', marginLeft: 16 }}>
                            {dayjs(item.created).format('D. MMM YYYY - HH:mm')}
                        </small>
                    </div>
                );
            },
        },
        {
            title: 'Þjónusta',
            key: 'slugs',
            filters: availableSlugs.map((slug) => {
                return {
                    value: slug,
                    text: getSlugDescription(slug),
                };
            }),
            render: (item: IncomingRequestLog) => {
                return (
                    <div>
                        {' '}
                        <small style={{ color: 'gray' }}>
                            {getSlugDescription(item.slug)}
                        </small>
                        <div
                            style={{
                                fontSize: '12px',
                                maxWidth: '500px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {item.message}
                        </div>
                    </div>
                );
            },
            className: 'logUrl',
        },

        {
            title: '',
            key: 'operation_successful',
            render: (item: IncomingRequestLog) => {
                return item.operation_successful ||
                    item.operation_successful === false ? (
                    <>
                        <Badge
                            status={
                                item.operation_successful ? 'success' : 'error'
                            }
                        />{' '}
                        {(item.operation_successful
                            ? item.operation_successful_reason
                            : item.operation_unsuccessful_reason) || ''}
                    </>
                ) : (
                    <>Ekki virkt</>
                );
            },
        },

        {
            key: 'payload',
            render: (item: IncomingRequestLog) => {
                return (
                    <Button
                        size="small"
                        onClick={() => {
                            setFeatured(item);
                        }}
                    >
                        Nánar
                    </Button>
                );
            },
        },
    ];

    if (props.hiddenColumns) {
        columns = columns.filter(
            (column) => !(props.hiddenColumns || []).includes(column.key)
        );
    }

    // if (props.admin)
    //     columns.unshift({
    //         title: 'Fyrirtæki',
    //         render: (item: RequestLogOverview) => {
    //             console.log('item', item);
    //             return item.shop.title;
    //         },
    //     });

    const showPagination = props.pagination === false ? false : true;

    const pagination = {
        hideOnSinglePage: true,
        defaultPageSize: props.pageSize || 50,
        showSizeChanger: false,
        total: count,
        onChange: (pageNumber: number) => {
            if (pageNumber === selectedPage) return;
            setLoading(true);
            setSelectedPage(pageNumber);
        },
    } as PaginationProps;

    if (!showPagination) {
        pagination.style = {
            display: 'none',
        };
    }

    return (
        <>
            {/* Search bar */}
            <Input.Search
                allowClear
                spellCheck={false}
                placeholder="Leita..."
                onChange={(e) => {
                    debounce(() => {
                        setSearchQuery(e.target.value);
                        setSelectedPage(1);
                    }, 1000)();
                }}
                enterButton={false}
            />

            {props.emptyMessage && (apiResponses?.length || 0) < 1 ? (
                props.emptyMessage
            ) : (
                <Table
                    id="logOverviewTable"
                    className="white_table"
                    loading={loading}
                    dataSource={apiResponses}
                    size={props.size || 'middle'}
                    pagination={pagination}
                    columns={columns}
                    showHeader={!props.hideHeader}
                    onChange={(pagination, filters, sorter, extra) => {
                        if (extra.action === 'filter') {
                            setSelectedSlugs(filters.slugs as string[]);
                        }
                    }}
                />
            )}

            <Modal
                onCancel={() => {
                    setFeatured(false);
                }}
                open={!!featured}
                footer={null}
            >
                <div style={{ width: '100%' }}>
                    {!!featured && <LogDetails log={featured}></LogDetails>}
                </div>
            </Modal>
        </>
    );
}
