import { useState } from 'react';
import CheckoutFormItems from '../RepeatCheckout/CheckoutFormItems';
import Customer, { SimpleCustomer } from '../../interfaces/customer';
import { SubscriptionDraft } from '../../interfaces/subscription';
import translate, { LanguageKey } from '../../translate/translate';
import { Button, Form, Select, Steps } from 'antd';
import UpdatePublicCreditCard from './UpdatePublicCreditCard';
import { displayCurrencyPrice } from '../../utils';
import { CustomerCardInfo } from './EditCustomerPublic';
import ProductAddonsTable from '../RepeatCheckout/ProductAddonsTable/ProductAddonsTable';
import { RepeatCheckoutProduct } from '../../interfaces/product';
import CustomFormItems from '../RepeatCheckout/CustomFormItems';
import axios from 'axios';
import { InfoToCollect } from '../RepeatCheckout/interfaces/repeatCheckoutInterfaces';

type Props = {
    customer: SimpleCustomer;
    draft: SubscriptionDraft;
    language: LanguageKey;
    cardToken?: CustomerCardInfo;
};

export default function FinishSubscriptionDraft({
    customer,
    draft,
    language,
    cardToken,
}: Props) {
    const [form] = Form.useForm();
    const [addOnsForm] = Form.useForm();
    const [current, setCurrent] = useState(0);
    const [selectedAddons, setSelectedAddons] = useState<
        RepeatCheckoutProduct['details'][]
    >([]);
    const [formValues, setFormValues] = useState<
        { [key: string]: any } | undefined
    >();
    const [addOnsFormValues, setAddOnsFormValues] = useState<
        { [key: string]: any } | undefined
    >();
    const [loading, setLoading] = useState(false);

    const intervalType =
        draft.subscription_product.cron_transaction.interval_type;
    const intervalCount = draft.subscription_product.cron_transaction.interval;

    const steps = [
        {
            title: 'Upplýsingar',
            slug: 'info',
        },
    ];

    if (!cardToken) {
        steps.push({
            title: translate('payment', language) as string,
            slug: 'payment',
        });
    }

    if ((draft.product.add_ons || []).length > 0) {
        steps.push({
            title: translate('add_ons', language) as string,
            slug: 'add_ons',
        });
    }

    steps.push({
        title: translate('confirm', language) as string,
        slug: 'confirm',
    });

    const customData = {} as { [key: string]: any };
    if (draft.custom_data && draft.product.custom_form?.uuid) {
        customData[draft.product.custom_form?.uuid] = draft.custom_data;
    }

    return (
        <>
            <Steps
                style={{
                    marginBottom: 20,
                }}
                current={current}
                size="small"
                items={steps.map((step) => ({
                    title: step.title,
                    onClick: () => {
                        const nextStep = steps.indexOf(step);

                        if (nextStep < current) {
                            setCurrent(nextStep);
                        }
                    },
                }))}
            />
            {steps[current].slug === 'info' && (
                <div
                    style={{
                        padding: 10,
                    }}
                >
                    <div
                        style={{
                            background: '#f1f1f1',
                            borderRadius: 10,
                            padding: 15,
                            fontSize: 14,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        {translate(
                            'your_subscription_is_almost_ready',
                            language
                        )}
                    </div>
                    <Form
                        size="large"
                        form={form}
                        layout="vertical"
                        initialValues={{
                            custom_data: customData,
                            name: customer.name,
                            email: customer.email,
                            phone: customer.phone,
                        }}
                        onFinish={(val) => {
                            setFormValues(val);
                            setCurrent(current + 1);
                        }}
                    >
                        <CheckoutFormItems
                            shopUUID={customer.shop_uuid}
                            totalProductPrice={
                                draft.skip_transaction
                                    ? 0
                                    : draft.subscription_product.final_price *
                                      draft.subscription_product.quantity
                            }
                            infoToCollect={
                                draft.product.info_to_collect.map(
                                    (i: { slug: string }) => i.slug
                                ) as InfoToCollect
                            }
                            disableInputs={false}
                            loading={false}
                            outOfStock={false}
                            selectedLanguage={language}
                            onValuesChange={(val) => {
                                console.log(val);
                            }}
                            products={[draft.product]}
                            form={form}
                        ></CheckoutFormItems>

                        <Button type="primary" block htmlType="submit">
                            {translate('next_step', language)}
                        </Button>
                    </Form>
                </div>
            )}
            {steps[current].slug === 'payment' && (
                <>
                    {/* {cardToken && (
                        <Select
                            style={{
                                width: '100%',
                            }}
                        >
                            <Select.Option value={cardToken.PAN} key={0}>
                                {cardToken.PAN
                                    ? cardToken.PAN
                                    : translate('use_existing_card', language)}
                            </Select.Option>
                        </Select>
                    )} */}

                    <UpdatePublicCreditCard
                        customer={customer}
                        currencyCode={draft.subscription_product.currency_code}
                        language={language}
                        onSuccess={() => {
                            setCurrent(2);
                        }}
                        onCancel={() => {}}
                    ></UpdatePublicCreditCard>
                </>
            )}
            {steps[current].slug === 'add_ons' && (
                <div style={{ padding: 10 }}>
                    <div
                        style={{
                            background: '#f1f1f1',
                            borderRadius: 10,
                            padding: 15,
                            fontSize: 14,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        {translate(
                            'do_you_want_add_ons_with_your_subscription',
                            language
                        )}
                    </div>

                    {draft.product.add_ons &&
                        draft.product.add_ons.filter(
                            (a) =>
                                !selectedAddons.find((sa) => sa.uuid === a.uuid)
                        ).length > 0 && (
                            <div
                                style={{
                                    padding: 10,
                                }}
                            >
                                <ProductAddonsTable
                                    addOns={draft.product.add_ons}
                                    selectedLanguage={language}
                                    currency={
                                        draft.subscription_product.currency_code
                                    }
                                    onAddonSelected={(selected) => {
                                        if (
                                            selectedAddons.find(
                                                (sa) =>
                                                    sa.uuid === selected.uuid
                                            )
                                        ) {
                                            return;
                                        }

                                        const selectedAddon = (
                                            draft.product.add_ons || []
                                        ).find(
                                            (
                                                sa: RepeatCheckoutProduct['details']
                                            ) => sa.uuid === selected.uuid
                                        );

                                        if (!selectedAddon) {
                                            return;
                                        }

                                        setSelectedAddons([
                                            ...selectedAddons,
                                            selectedAddon,
                                        ]);
                                    }}
                                ></ProductAddonsTable>
                            </div>
                        )}

                    {selectedAddons.length > 0 && (
                        <div style={{ padding: 10 }}>
                            <ProductAddonsTable
                                addOns={selectedAddons}
                                selectedLanguage={language}
                                currency={
                                    draft.subscription_product.currency_code
                                }
                                onAddonRemoved={(removed) => {
                                    console.log('Removed', removed);
                                    setSelectedAddons(
                                        selectedAddons.filter(
                                            (sa) => sa.uuid !== removed.uuid
                                        )
                                    );
                                }}
                            ></ProductAddonsTable>
                        </div>
                    )}

                    {selectedAddons.length > 0 && (
                        <Form
                            form={addOnsForm}
                            style={{
                                padding: 10,
                            }}
                            onFinish={(val) => {
                                setAddOnsFormValues(val);
                                setCurrent(
                                    steps.findIndex((s) => s.slug === 'confirm')
                                );
                            }}
                        >
                            <CustomFormItems
                                parentKey="add_ons_custom_data"
                                products={selectedAddons}
                                selectedLanguage={language}
                                whenToCollect="CHECKOUT_PAGE"
                            ></CustomFormItems>

                            <Button
                                htmlType="submit"
                                type="primary"
                                block
                                size="large"
                            >
                                {translate('next_step', language)}
                            </Button>
                        </Form>
                    )}

                    {selectedAddons.length === 0 && (
                        <Button
                            type="primary"
                            block
                            size="large"
                            onClick={() => {
                                setCurrent(
                                    steps.findIndex((s) => s.slug === 'confirm')
                                );
                            }}
                        >
                            {translate('no_thanks', language)}
                        </Button>
                    )}
                </div>
            )}
            {steps[current].slug === 'confirm' && (
                <div>
                    <div
                        style={{
                            background: '#f1f1f1',
                            borderRadius: 10,
                            padding: 15,
                            fontSize: 14,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        <h2>{translate('confirm_subscription', language)}</h2>
                        <div>
                            {draft.product.title}
                            {': '}
                            {displayCurrencyPrice(
                                draft.subscription_product.price *
                                    (draft.subscription_product.quantity || 1),
                                draft.subscription_product.currency_code
                            )}
                        </div>

                        {selectedAddons.length > 0 &&
                            selectedAddons.map((a) => (
                                <p key={a.uuid}>
                                    {a.title}:{' '}
                                    {displayCurrencyPrice(
                                        a.prices.find(
                                            (p) =>
                                                p.currency.currency_code ===
                                                draft.subscription_product
                                                    .currency_code
                                        )?.amount || 0,
                                        draft.subscription_product.currency_code
                                    )}
                                </p>
                            ))}

                        {
                            <p>
                                {translate('total', language)}:{' '}
                                {displayCurrencyPrice(
                                    draft.subscription_product.price *
                                        (draft.subscription_product.quantity ||
                                            1) +
                                        selectedAddons.reduce((acc, a) => {
                                            return (
                                                acc +
                                                (a.prices.find(
                                                    (p) =>
                                                        p.currency
                                                            .currency_code ===
                                                        draft
                                                            .subscription_product
                                                            .currency_code
                                                )?.amount || 0)
                                            );
                                        }, 0),
                                    draft.subscription_product.currency_code
                                )}{' '}
                                / {intervalCount}{' '}
                                {intervalType === 'DAY' && (
                                    <span>{translate('days', language)}</span>
                                )}
                                {intervalType === 'WEEK' && (
                                    <span>{translate('weeks', language)}</span>
                                )}
                                {intervalType === 'MONTH' && (
                                    <span>{translate('months', language)}</span>
                                )}
                                {intervalType === 'YEAR' && (
                                    <span>{translate('years', language)}</span>
                                )}{' '}
                                {translate('apart', language)}
                            </p>
                        }
                    </div>

                    <Button
                        type="primary"
                        block
                        size="large"
                        loading={loading}
                        onClick={() => {
                            console.log('FormValues', formValues);
                            console.log('addonsFormValues', addOnsFormValues);
                            console.log('selectedAddons', selectedAddons);
                            setLoading(true);

                            axios
                                .post(
                                    '/askrift/api/finish_subscription_draft_public/' +
                                        draft.uuid +
                                        '/',
                                    {
                                        uuid: draft.uuid,
                                        ...formValues,
                                        custom_data:
                                            formValues?.custom_data ||
                                            addOnsFormValues?.add_ons_custom_data
                                                ? {
                                                      ...formValues?.custom_data,
                                                      ...addOnsFormValues?.add_ons_custom_data,
                                                  }
                                                : null,
                                        add_ons: selectedAddons.map((a) => ({
                                            uuid: a.uuid,
                                            quantity: 1,
                                            custom_data: addOnsFormValues
                                                ? addOnsFormValues
                                                      .add_ons_custom_data[
                                                      a.uuid
                                                  ]
                                                : null,
                                        })),
                                    }
                                )
                                .then((rsp) => {
                                    console.log('RSP', rsp);
                                    setLoading(false);
                                    window.location.reload();
                                })
                                .catch((err) => {
                                    console.error(err);
                                    setLoading(false);
                                });
                        }}
                    >
                        {translate('confirm_subscription', language)}
                    </Button>
                </div>
            )}
        </>
    );
}
