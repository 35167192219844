import { useEffect, useRef, useState } from 'react';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import './TipTapStyles.less';

import AceEditor from 'react-ace';
import ImageResize from 'tiptap-extension-resize-image';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import BulletList from '@tiptap/extension-bullet-list';

import { EditorContent, useEditor } from '@tiptap/react';
import { Blockquote } from '@tiptap/extension-blockquote';
import { Bold } from '@tiptap/extension-bold';
import { Code } from '@tiptap/extension-code';
import { CodeBlock } from '@tiptap/extension-code-block';
import { Document } from '@tiptap/extension-document';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { HardBreak } from '@tiptap/extension-hard-break';
import { Heading } from '@tiptap/extension-heading';
import { History } from '@tiptap/extension-history';
import { TextAlign } from '@tiptap/extension-text-align';
import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { Italic } from '@tiptap/extension-italic';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Strike } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';
import { Button, Dropdown, message } from 'antd';
import { formatHtml, uploadFileToDigitalOcean } from '../../utils';

interface Props {
    initialValue?: string;
    onEditorReady?: (editor: any) => void;
    onChange?: (value: string) => void;
    shopUUID: string;
}

const TiptapWrapper = ({
    initialValue = '',
    onEditorReady,
    onChange,
    shopUUID,
}: Props) => {
    const [startValue, setStartValue] = useState(initialValue);
    const [aceContent, setAceContent] = useState<false | string>('');
    const [selectedEditor, setSelectedEditor] = useState<
        'tipTap' | 'sourceCode'
    >('tipTap');

    const aceRef = useRef<AceEditor | null>(null);

    const editor = useEditor({
        extensions: [
            Document,
            History,
            Text,
            Bold,
            Italic,
            Image,
            ImageResize,
            Link.configure({
                openOnClick: false,
                autolink: true,
                defaultProtocol: 'https',
            }),
            Blockquote,
            BulletList,
            ListItem,
            Code,
            CodeBlock,
            Dropcursor,
            Gapcursor,
            HardBreak,
            Heading.configure({
                levels: [1, 2, 3],
            }),
            HorizontalRule,
            OrderedList,
            Paragraph,
            Strike,
            TextAlign.configure({
                alignments: ['left', 'right', 'center', 'justify'],
                types: ['heading', 'paragraph'],
            }),
        ],
        content: initialValue,
        onUpdate: ({ editor }) => {
            onChange?.(editor.getHTML());
        },
    });

    useEffect(() => {
        if (editor && startValue !== initialValue) {
            setSelectedEditor('tipTap');
            editor.commands.setContent(initialValue);
            setStartValue(initialValue);
            onChange?.(editor.getHTML());
        }
    }, [editor, initialValue, startValue]);

    useEffect(() => {
        if (editor && onEditorReady) {
            onEditorReady(editor);
        }
    }, [editor, onEditorReady]);

    return (
        <div className="tipTapWrapper">
            <div>
                {editor && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                        }}
                    >
                        <div className="tipTapToolBar">
                            {selectedEditor === 'tipTap' && (
                                <>
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() =>
                                            editor.chain().focus().undo().run()
                                        }
                                        disabled={!editor.can().undo()}
                                    >
                                        <i className="undo"></i>
                                    </Button>

                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() =>
                                            editor.chain().focus().redo().run()
                                        }
                                        disabled={!editor.can().redo()}
                                    >
                                        <i className="redo"></i>
                                    </Button>

                                    <div className="divider"></div>

                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleBold()
                                                .run()
                                        }
                                        style={{ marginRight: 5 }}
                                    >
                                        <i className="bold"></i>
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleItalic()
                                                .run()
                                        }
                                    >
                                        <i className="italic"></i>
                                    </Button>

                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => {
                                            const domElm =
                                                document.createElement('input');
                                            domElm.type = 'file';
                                            domElm.accept = 'image/*';
                                            domElm.click();

                                            domElm.addEventListener(
                                                'change',
                                                (e) => {
                                                    if (!domElm.files) return;
                                                    const file =
                                                        domElm.files[0];
                                                    uploadFileToDigitalOcean(
                                                        file,
                                                        shopUUID,
                                                        (obj) => {
                                                            editor
                                                                .chain()
                                                                .focus()
                                                                .setImage({
                                                                    src: obj.url,
                                                                })
                                                                .run();
                                                        },
                                                        (error) => {
                                                            console.error(
                                                                'Error uploading file',
                                                                error
                                                            );
                                                            message.error(
                                                                'Error uploading file'
                                                            );
                                                        }
                                                    );
                                                }
                                            );
                                        }}
                                    >
                                        <i className="image"></i>
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            const hasLink =
                                                editor.isActive('link');

                                            if (hasLink) {
                                                editor
                                                    .chain()
                                                    .focus()
                                                    .unsetLink()
                                                    .run();
                                            } else {
                                                const url =
                                                    window.prompt('Vefslóð');
                                                const { view, state } = editor;
                                                const { from, to } =
                                                    view.state.selection;

                                                if (!url) {
                                                    return;
                                                }

                                                if (from !== to) {
                                                    editor
                                                        .chain()
                                                        .focus()
                                                        .extendMarkRange('link')
                                                        .toggleLink({
                                                            href: url,
                                                        })
                                                        .focus(to + 1)

                                                        .run();
                                                } else {
                                                    editor
                                                        .chain()
                                                        .focus()
                                                        .extendMarkRange('link')
                                                        .command(
                                                            ({
                                                                tr,
                                                                dispatch,
                                                            }) => {
                                                                if (!dispatch)
                                                                    return true;

                                                                const {
                                                                    from,
                                                                    to,
                                                                } =
                                                                    tr.selection;

                                                                tr.insertText(
                                                                    url,
                                                                    from,
                                                                    to
                                                                );
                                                                tr.addMark(
                                                                    from,
                                                                    from +
                                                                        url.length,
                                                                    editor.schema.marks.link.create(
                                                                        {
                                                                            href: url,
                                                                        }
                                                                    )
                                                                );

                                                                return true;
                                                            }
                                                        )
                                                        .focus()
                                                        .run();
                                                }
                                            }
                                        }}
                                    >
                                        <i className="link"></i>
                                    </Button>

                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => {
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign('left')
                                                .run();
                                        }}
                                    >
                                        <i className="left-align"></i>
                                    </Button>

                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign('center')
                                                .run()
                                        }
                                    >
                                        <i className="center-align"></i>
                                    </Button>

                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign('right')
                                                .run()
                                        }
                                    >
                                        <i className="right-align"></i>
                                    </Button>

                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign('justify')
                                                .run()
                                        }
                                    >
                                        <i className="justify-align"></i>
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleBulletList()
                                                .run();
                                        }}
                                    >
                                        <i className="list-ul"></i>
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleOrderedList()
                                                .run();
                                        }}
                                    >
                                        <i className="list-ol"></i>
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            editor
                                                .chain()
                                                .focus()
                                                .sinkListItem('listItem')
                                                .run();
                                        }}
                                        disabled={
                                            !editor
                                                .can()
                                                .sinkListItem('listItem')
                                        }
                                    >
                                        <i className="list-indent"></i>
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            editor
                                                .chain()
                                                .focus()
                                                .liftListItem('listItem')
                                                .run();
                                        }}
                                        disabled={
                                            !editor
                                                .can()
                                                .liftListItem('listItem')
                                        }
                                    >
                                        <i className="list-outdent"></i>
                                    </Button>

                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    key: 1,
                                                    onClick: () => {
                                                        editor
                                                            .chain()
                                                            .focus()
                                                            .toggleHeading({
                                                                level: 1,
                                                            })
                                                            .run();
                                                    },
                                                    className: editor.isActive(
                                                        'heading',
                                                        {
                                                            level: 1,
                                                        }
                                                    )
                                                        ? 'is-active'
                                                        : '',
                                                    label: <h1>Fyrirsögn 1</h1>,
                                                },
                                                {
                                                    key: 2,
                                                    onClick: () => {
                                                        editor
                                                            .chain()
                                                            .focus()
                                                            .toggleHeading({
                                                                level: 2,
                                                            })
                                                            .run();
                                                    },
                                                    className: editor.isActive(
                                                        'heading',
                                                        {
                                                            level: 2,
                                                        }
                                                    )
                                                        ? 'is-active'
                                                        : '',
                                                    label: <h2>Fyrirsögn 2</h2>,
                                                },
                                                {
                                                    key: 3,
                                                    label: <h3>Fyrirsögn 3</h3>,
                                                    className: editor.isActive(
                                                        'heading',
                                                        {
                                                            level: 3,
                                                        }
                                                    )
                                                        ? 'is-active'
                                                        : '',
                                                    onClick: () => {
                                                        editor
                                                            .chain()
                                                            .focus()
                                                            .toggleHeading({
                                                                level: 3,
                                                            })
                                                            .run();
                                                    },
                                                },
                                                {
                                                    key: 'paragraph',
                                                    label: 'Málsgrein',
                                                    onClick: () => {
                                                        editor
                                                            .chain()
                                                            .focus()
                                                            .toggleHeading({
                                                                level: 4,
                                                            })
                                                            .run();
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: '#959595',
                                                borderRadius: 10,
                                                padding: '3px 15px',
                                                background: '#f4f4f4',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Textastærð
                                        </div>
                                    </Dropdown>

                                    {/* <Button
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleHeading({ level: 1 })
                                                .run()
                                        }
                                        className={
                                            editor.isActive('heading', {
                                                level: 1,
                                            })
                                                ? 'is-active'
                                                : ''
                                        }
                                    >
                                        <i className="h1"></i>
                                    </Button>

                                    <Button
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleHeading({ level: 2 })
                                                .run()
                                        }
                                        className={
                                            editor.isActive('heading', {
                                                level: 2,
                                            })
                                                ? 'is-active'
                                                : ''
                                        }
                                    >
                                        <i className="h2"></i>
                                    </Button>

                                    <Button
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleHeading({ level: 3 })
                                                .run()
                                        }
                                        className={
                                            editor.isActive('heading', {
                                                level: 3,
                                            })
                                                ? 'is-active'
                                                : ''
                                        }
                                    >
                                        <i className="h3"></i>
                                    </Button> */}
                                </>
                            )}
                        </div>

                        <div className="tipTapToolBar">
                            <Button
                                size="small"
                                className={
                                    selectedEditor === 'tipTap' ? '' : 'active'
                                }
                                onClick={async () => {
                                    if (selectedEditor === 'tipTap') {
                                        const val = editor.getHTML();
                                        const formattedHtml = await formatHtml(
                                            val || ''
                                        );

                                        console.log(
                                            'formattedHtml',
                                            formattedHtml
                                        );

                                        setAceContent(formattedHtml || '');
                                        setSelectedEditor('sourceCode');
                                    } else {
                                        const val =
                                            aceRef.current?.editor.getValue();
                                        if (val) {
                                            editor?.commands.setContent(val);
                                        }
                                        setSelectedEditor('tipTap');
                                        setAceContent(false);
                                    }
                                }}
                            >
                                <i className="code"></i>
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            {selectedEditor === 'tipTap' && (
                <>
                    <EditorContent editor={editor} />
                </>
            )}

            {selectedEditor === 'sourceCode' &&
                (aceContent || aceContent === '') && (
                    <AceEditor
                        ref={aceRef}
                        mode="html"
                        theme="monokai"
                        value={aceContent}
                        setOptions={{
                            useWorker: false,
                        }}
                        showPrintMargin={false}
                        width="100%"
                    ></AceEditor>
                )}
        </div>
    );
};

export default TiptapWrapper;
