import { ReactElement, useState } from 'react';
import { Modal, Button, message } from 'antd';
import CardToken from '../../interfaces/cardToken';
import { SimpleCustomer } from '../../interfaces/customer';
import { Currency } from '../../interfaces/product';
import translate, { LanguageKey } from '../../translate/translate';
import UpdatePublicCreditCard from './UpdatePublicCreditCard';
import UpdatePublicCreditCardNew from './UpdatePublicCreditCardNew';

interface Props {
    open: boolean;
    onCancel: () => void;
    onSuccess: () => void;
    customer: SimpleCustomer;
    className?: string;
    currencyCode: Currency['currency_code'];
    language: LanguageKey;
}

export default function UpdateCreditCardModal({
    customer,
    onCancel,
    onSuccess,
    open,
    className,
    currencyCode,
    language,
}: Props): ReactElement {
    return (
        <Modal
            title={translate('connected_card', language)}
            footer={false}
            open={open}
            onCancel={() => {
                onCancel();
            }}
            className={className || ''}
        >
            {customer.shop.payment_provider === 'ONPAY' ||
            customer.shop.payment_provider === 'RAPYD' ? (
                <UpdatePublicCreditCard
                    customer={customer}
                    currencyCode={currencyCode}
                    language={language}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                ></UpdatePublicCreditCard>
            ) : (
                <UpdatePublicCreditCardNew
                    customer={customer}
                    currencyCode={currencyCode}
                    language={language}
                    onCancel={onCancel}
                    onSuccess={onSuccess}
                ></UpdatePublicCreditCardNew>
            )}
        </Modal>
    );
}
