import { Button, Form, Input, Modal, message } from 'antd';
import { useState } from 'react';
import { SubscriptionFilterPayload } from '../../interfaces/subscription';
import { SubscriptionTransactionFilterRequest } from '../../interfaces/transaction';
import Axios from 'axios';

type Props = {
    open: boolean;
    subscriptionFilterPayload?: SubscriptionFilterPayload;
    subscriptionTransactionFilterPayload?: SubscriptionTransactionFilterRequest;
    orderFilterPayload?: any;
    productFilterPayload?: any;
    defaultEmail?: string;
    onClose: () => void;
};

export default function ExcelModal({
    open,
    subscriptionFilterPayload,
    subscriptionTransactionFilterPayload,
    orderFilterPayload,
    productFilterPayload,
    defaultEmail,
    onClose,
}: Props) {
    const [email, setEmail] = useState(defaultEmail || '');
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            title="Sækja gögn á Excel sniði"
            open={open}
            footer={false}
            onCancel={() => onClose()}
        >
            <Form
                onFinish={(values) => {
                    setLoading(true);

                    let url;

                    if (subscriptionFilterPayload) {
                        url = '/askrift/askriftir_excel_skjal/';
                    } else if (subscriptionTransactionFilterPayload) {
                        url = '/askrift/subscription_transactions_excel/';
                    } else if (orderFilterPayload) {
                        url = '/verslun/api/orders_excel';
                    } else if (productFilterPayload) {
                        url = '/verslun/api/products_excel';
                    }

                    if (url) {
                        Axios.post(url, {
                            ...(subscriptionFilterPayload || {}),
                            ...(subscriptionTransactionFilterPayload || {}),
                            ...(orderFilterPayload || {}),
                            ...(productFilterPayload || {}),
                            email: values.email,
                        }).then((rsp) => {
                            message.success(
                                'Aðgerð hefur verið sett af stað. Skjal verður sent á netfangið þitt á næstu mínútum.'
                            );
                            setLoading(false);
                            onClose();
                        });
                    } else {
                    }
                }}
                layout="vertical"
                size="large"
            >
                <p>
                    Þú færð Excel skjal send á netfangið þitt. Athugaðu að það
                    getur tekið nokkrar mínútur þangað til skjalið skilar sér.
                </p>
                <Form.Item
                    label="Netfang"
                    name="email"
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Netfang er nauðsynlegt',
                        },
                        {
                            type: 'email',
                            message: 'Netfang er ekki rétt',
                        },
                    ]}
                >
                    <Input type="email"></Input>
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Staðfesta
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
