import { useContext, useState } from 'react';
import { Tabs, Card, Checkbox, Segmented, DatePicker } from 'antd';
import LogOverview from '../../components/LogOverview/LogOverview';
import AuthContext from '../../authContext';
import { DatabaseOutlined } from '@ant-design/icons';
import IncomingLogOverview from '../../components/IncomingLogOverview/IncomingLogOverview';
import dayjs, { Dayjs } from 'dayjs';
const { TabPane } = Tabs;

import isIS from 'antd/es/locale/is_IS';

export default function LogOverviewPage() {
    const { selectedShop } = useContext(AuthContext);
    const [onlyErrors, setOnlyErrors] = useState<boolean>(false);
    const [inOrOut, setInOrOut] = useState<string>('out');
    const [fromDate, setFromDate] = useState<dayjs.Dayjs | null>(null);
    const [toDate, setToDate] = useState<dayjs.Dayjs | null>(null);

    const lte = toDate ? dayjs(toDate).endOf('day').toISOString() : '';
    const gte = fromDate ? dayjs(fromDate).startOf('day').toISOString() : '';

    return (
        <Card
            title={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <DatabaseOutlined
                        style={{
                            fontSize: 35,
                            marginRight: 10,
                        }}
                    />{' '}
                    Loggar
                </div>
            }
            className="basePageCard"
            extra={
                <label>
                    <Checkbox
                        checked={onlyErrors}
                        onChange={(e) => setOnlyErrors(e.target.checked)}
                    />{' '}
                    Bara sýna villur
                </label>
            }
        >
            <div
                style={{
                    marginBottom: 20,
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'space-between',
                }}
            >
                <DatePicker.RangePicker
                    onChange={(dates) => {
                        if (dates?.[0]) {
                            const fDate = dayjs(dates[0] as Dayjs);
                            setFromDate(fDate);
                        }
                        if (dates?.[1]) {
                            const tDate = dayjs(dates[1] as Dayjs);
                            setToDate(tDate);
                        }
                    }}
                    format="DD. MMM YYYY"
                    locale={isIS.DatePicker}
                />

                <Segmented
                    options={[
                        { label: 'Samskipti út', value: 'out' },
                        { label: 'Samskipti inn', value: 'in' },
                    ]}
                    value={inOrOut}
                    shape="round"
                    onChange={(value) => setInOrOut(value as string)}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            </div>

            <div
                style={{
                    padding: 20,
                    background: 'white',
                    borderRadius: 20,
                }}
            >
                {inOrOut === 'out' ? (
                    <Tabs>
                        <>
                            <TabPane tab="Allt" key="all">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="Vefkrókar" key="webhooks">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="WEBHOOK"
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="Shopify" key="shopify">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="SHOPIFY"
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="Woocommerce" key="woocommerce">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="WOOCOMMERCE"
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="DK" key="dk">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="DK"
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="Open banking" key="openbanking">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="OPENBANKING"
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="Dropp" key="dropp">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="DROPP"
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="Pósturinn" key="posturinn">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="POSTURINN"
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>

                            <TabPane tab="Payday" key="payday">
                                <LogOverview
                                    lte={lte}
                                    gte={gte}
                                    admin={false}
                                    shop={selectedShop}
                                    service="PAYDAY"
                                    onlyErrors={onlyErrors}
                                ></LogOverview>
                            </TabPane>
                        </>
                    </Tabs>
                ) : (
                    <IncomingLogOverview
                        shop={selectedShop}
                        onlyErrors={onlyErrors}
                        lte={lte}
                        gte={gte}
                    />
                )}
            </div>
        </Card>
    );
}
