import { Button, Card, Table } from 'antd';
import { useEffect, useState } from 'react';
import Shop from '../../../interfaces/shop';
import axios from 'axios';
import { MessageOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

type Props = {
    shop: Shop;
};

const STATUS_MAP = {
    DELIVERED: 'Skeyti hefur verið afhent í handtæki.',
    EXPIRED: 'Skeyti hefur ekki verið afhent og er biðtími þess útrunninn.',
    DELETED: 'Skeyti var eytt.',
    UNDELIVERABLE: 'Ekki er mögulegt að afhenda skeyti.',
    ACCEPTED: 'Skeyti móttekið af SMS kerfi.',
    UNKNOWN: 'Skeytið er í óþekktri stöðu.',
    REJECTED: 'Skeyti hafnað.',
};

const pageSize = 5;

const getStatus = (uuid: string) => {
    axios
        .get('/sms/api/sms_status/' + uuid)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export default function SMSOverview({ shop }: Props) {
    const [data, setData] = useState<
        {
            updated: string;
            created: string;
            uuid: string;
            to: string;
            user: string;
            shop: number;
            message: string;
            external_request_log: string;
        }[]
    >([]);

    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        axios
            .get(
                '/sms/api/sms_list/?uuid=' +
                    shop.uuid +
                    '&page=' +
                    page +
                    '&page_size=' +
                    pageSize +
                    '&ordering=-created'
            )
            .then((response) => {
                setData(response.data.results);
                setTotal(response.data.count);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [shop.uuid, page]);

    return (
        <Card
            className="statistics_container"
            title={
                <>
                    <MessageOutlined
                        style={{
                            fontSize: 32,
                            marginRight: 5,
                            position: 'relative',
                            top: 5,
                        }}
                    />{' '}
                    Yfirlit SMS sendinga
                </>
            }
            bodyStyle={{
                justifyContent: 'flex-start',
            }}
        >
            <Table
                size="small"
                className="white_table"
                style={{ width: '100%', overflow: 'hidden' }}
                dataSource={data}
                pagination={{
                    current: page,
                    onChange: (page) => setPage(page),
                    total: total,
                    defaultPageSize: pageSize,
                }}
                showHeader={false}
                columns={[
                    {
                        title: '',
                        render: (text, record) => (
                            <div>
                                <div
                                    style={{
                                        fontSize: 12,
                                        paddingLeft: 10,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            color: 'gray',
                                        }}
                                    >
                                        {record.to}
                                    </div>
                                    <small>
                                        {dayjs(record.created).format(
                                            'D. MMM YYYY - HH:mm'
                                        )}
                                    </small>
                                </div>
                                <div
                                    style={{
                                        fontSize: 12,
                                        padding: 10,
                                        marginTop: 5,
                                        borderRadius: 20,
                                        // background: '#fafafa',
                                        color: '#555',
                                    }}
                                >
                                    {record.message}
                                </div>
                            </div>
                        ),
                        key: 'message',
                    },
                ]}
            ></Table>
        </Card>
    );
}
