export const VERIFONE_ERROR_CODE_MAP = {
    '0000': 'Approved',
    '0001': 'Honour with identification',
    '0002': 'Approved for partial amount',
    '0003': 'Approved (VIP)',
    '0004': 'Approved, update track 3',
    '0005': 'Approved, account type specified by card issuer',
    '0006': 'Approved for partial amount, account type specified by card issuer',
    '0008': 'Approved but fees disputed',
    '0009': 'Approved with overdraft',
    '0010': 'Approved, customer reactivated',
    '0011': 'Approved, terminal unable to process online',
    '0012': 'Approved, transaction processed offline by terminal',
    '0013': 'Approved, transaction processed offline after referral',
    '0085': 'Declined, no reason given',
    '0800': 'Declined, additional customer identification required',
    '0801': 'Advice accepted, no financial liability accepted',
    '0802': 'Approved, but AVS or CVV mismatch',
    '0803': 'Approved as paper cheque. Keep cheque for deposit',
    '1000': 'Do not honour',
    '1001': 'Expired card',
    '1002': 'Suspected fraud',
    '1003': 'Card acceptor contact acquirer. Phone for authorisation',
    '1004': 'Restricted card',
    '1005': "Card acceptor call acquirer's security department",
    '1006': 'Allowable PIN tries exceeded',
    '1007': 'Refer to card issuer',
    '1008': "Refer to card issuer's special conditions",
    '1009': 'Invalid card acceptor, Merchant',
    '1010': 'Invalid amount',
    '1011': 'Invalid card number',
    '1012': 'PIN data required',
    '1013': 'Unacceptable fee or commission',
    '1014': 'No account of type requested. Account Type Error',
    '1015': 'Requested function not supported',
    '1016': 'Not sufficient funds',
    '1017': 'Incorrect PIN',
    '1018': 'No card record',
    '1019': 'Transaction not permitted to cardholder',
    '1020': 'Transaction not permitted to terminal',
    '1021': 'Exceeds withdrawal amount limit',
    '1022': 'Security violation',
    '1023': 'Exceeds withdrawal frequency limit',
    '1024': 'Violation of law',
    '1025': 'Card not effective',
    '1026': 'Invalid PIN block',
    '1027': 'PIN length error',
    '1028': 'PIN key sync error',
    '1029': 'Suspected counterfeit card',
    '1030': 'Currency unacceptable to card issuer',
    '1031': 'Not authorised and fees disputed',
    '1032': 'Lost/stolen card',
    '1033': 'Authorization lifecycle unacceptable',
    '1034': 'Authorization lifecycle has expired',
    '1035': 'Closed account',
    '1036': 'Closed savings account, or restricted for closing',
    '1037': 'Closed credit account or restricted for closing',
    '1038': 'Closed credit facility cheque account or restricted for closing',
    '1039': 'Closed cheque account or restricted for closing',
    '1041': 'From account bad status',
    '1042': 'To account bad status',
    '1044': 'Information not on file',
    '1045': 'Card verification data failed',
    '1046': 'Amount not found',
    '1047': 'PIN change required',
    '1048': 'New PIN invalid',
    '1049': 'Bank not found',
    '1050': 'Bank not effective',
    '1051': 'Customer vendor not found',
    '1052': 'Customer vendor not effective',
    '1053': 'Customer vendor account invalid',
    '1054': 'Vendor not found',
    '1055': 'Vendor not effective',
    '1056': 'Vendor data invalid',
    '1057': 'Payment date invalid',
    '1058': 'Personal identification not found',
    '1059': 'Scheduled transactions exist',
    '1060': 'Transaction did not complete normally at terminal',
    '1061': 'Transaction not supported by the card issuer',
    '1062': 'Cashback not allowed',
    '1063': 'Cashback amount exceeded',
    '1064': 'Declined, transaction processed offline by terminal',
    '1065': 'Declined, terminal unable to process offline',
    '1066': 'Declined, transaction processed offline after referral',
    '1068': 'Identification number invalid',
    '1069': 'Driver number invalid',
    '1070': 'Vehicle number invalid',
    '1071': 'Digital certificate expired',
    '1800': 'Partial, Additional Customer identification required',
    '1801': 'Request format error',
    '1802': 'New Card Issued',
    '1803': 'Cancelled or Closed Merchant',
    '1804': 'PIN verification not supported',
    '1805': 'Terminal date-time error',
    '1806': 'Too many fallbacks',
    '1807': 'Fallback not allowed',
    '1808': 'Wrong EMV or ICC cryptogram',
    '1809': 'Cryptogram error',
    '1810': 'Track missing',
    '1811': 'Original transaction rejected',
    '1812': 'Original transaction already reversed',
    '1813': 'SCA - PIN required',
    '1814': 'Use another interface',
    '1815': 'Additional customer authentication required (CDCVM/Passcode/Biometric). Authenticate and tap/re-submit authorisation again',
    '1816': 'Acquirer requested SCA action not supported',
    '1817': 'Invalid issuer',
    '1818': 'Customer Cancellation',
    '1819': 'Does not fulfill AML (Anti Money Laundering) Requirements',
    '1820': 'Card Number Blocked',
    '1821': 'Address Verification Failed',
    '1822': 'Transaction type not permitted for offline processing',
    '1823': 'Offline limit exceeded',
    '1824': 'Invalid use of SCA Exemption Indicators',
    '1825': 'Invalid terminal id',
    '1826': 'Try another card',
    '1827': "Authorisation order (recurring payment) revoked. Corresponds to Visa's R1 response code",
    '1828': "All authorisation orders (recurring payments) revoked. Corresponds to Visa's R3 response code",
    '1829': 'Bad authorisation code',
    '1830': 'Inactive account',
    '1831': 'Card already active',
    '1832': 'Account locked',
    '1833': 'Invalid track data',
    '1834': 'Security code required',
    '1835': "Cardholder refused a single recurring payment. Contact cardholder for more details. Corresponds to Visa's R0 response code",
    '1836': 'SCA Exemption Soft Decline. Transaction Risk Analysis service not available',
    '1837': 'New card. Activate your card before making a payment',
    '1838': 'Declined, additional customer identification required',
    '1839': 'Invalid country code',
    '2000': 'Do not honour',
    '2001': 'Expired card',
    '2002': 'Suspected fraud',
    '2003': 'Card acceptor contact acquirer',
    '2004': 'Restricted card',
    '2005': "Card acceptor call acquirer's security department",
    '2006': 'Allowable PIN tries exceeded',
    '2007': 'Special conditions',
    '2008': 'Lost card',
    '2009': 'Stolen card',
    '2010': 'Suspected counterfeit card',
    '2011': 'Daily withdrawal uses exceeded',
    '2012': 'Daily withdrawal amount exceeded',
    '5000': 'Reconciled, in balance',
    '5001': 'Reconciled, out of balance',
    '5002': 'Amount not reconciled, totals provided',
    '5003': 'Totals not available',
    '5004': 'Not reconciled, totals provided',
    '5800': 'Open session message, the session number used is less than current session. Correct session number attached',
    '5801': 'Wrong session number, the session number used is not the current one, it is not possible to reconcile other session than current open session. Current open session number attached',
    '8000': 'Accepted',
    '8001': 'Rejected, unable to perform request at current time, try later',
    '8002': 'Rejected, key verification failed. Key sync error or key check value does not match',
    '9000': 'Advice acknowledged, no 9000-9001 financial liability accepted Used in all message types to indicate error or response actions',
    '9001': 'Advice acknowledged, financial liability accepted',
    '9080': 'Accepted, ATC Synchronization',
    '9102': 'Invalid transaction',
    '9103': 'Re-enter transaction',
    '9105': 'Acquirer not supported by switch',
    '9106': 'Cutover in process',
    '9107': 'Card issuer or switch inoperative',
    '9108': 'Transaction destination cannot be found for routing',
    '9109': 'System malfunction',
    '9110': 'Card issuer signed off',
    '9111': 'Card issuer timed out',
    '9112': 'Card issuer unavailable',
    '9113': 'Duplicate transmission',
    '9114': 'Not able to trace back to original transaction',
    '9115': 'Reconciliation cutover or checkpoint error',
    '9116': 'MAC incorrect',
    '9117': 'MAC key sync error',
    '9118': 'No communication keys available for use',
    '9119': 'Encryption key sync error',
    '9120': 'Security software/hardware error - try again',
    '9121': 'Security software/hardware error - no action',
    '9122': 'Message number out of sequence',
    '9123': 'Request in progress',
    '9124': 'Invalid security code',
    '9125': 'Database error',
    '9128': 'Customer vendor format error',
    '9132': 'Recurring data error',
    '9133': 'Update not allowed',
    '9200': 'Transaction refused before sending to acquirer',
    '9201': 'Transaction refused after sending to acquirer',
    '9280': 'Invalid token scope. Either no token scope was provided or a default token scope could not be selected because this entity has multiple scopes configured.',
    '9350': 'Violation of business arrangement',
    '9998': 'Transaction blocked by validation rules',
    '9999': 'General Error - Unknown or Unspecified reason',
};
