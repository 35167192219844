import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Input,
    message,
    Collapse,
    Button,
    Switch,
    Tabs,
    Popover,
    UploadFile,
} from 'antd';
import {
    QuestionCircleTwoTone,
    CreditCardFilled,
    BankFilled,
} from '@ant-design/icons';
import AuthContext from '../../../authContext';

// @ts-ignore
import { Kennitala } from '../../../kennitala';
import SVG from 'react-inlinesvg';
import axios from 'axios';
import UploadImage from '../../../components/UploadImage/UploadImage';

interface IWindow extends Window {
    uuid?: string;
    me?: number;
}

const w: IWindow = window;
const { Panel } = Collapse;

export default function CreateShop() {
    const { setSelectedShop, selectedShop } = useContext(AuthContext);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [imageFileList, setImageFileList] = useState<UploadFile<any>[]>();

    useEffect(() => {
        if (selectedShop && w.uuid) {
            form.setFieldsValue(selectedShop);

            setImageFileList([
                {
                    uid: selectedShop.logo.id.toString(),
                    url: selectedShop.logo.image,
                    name: 'logo',
                    status: 'done',
                },
            ]);

            try {
                form.setFieldsValue({
                    logo: parseInt(selectedShop.logo.id.toString()),
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [selectedShop]);

    const s = selectedShop;

    const x = [
        { slug: 'SALT_PAY', active: s?.salt_pay?.active === true },
        { slug: 'STRAUMUR', active: s?.straumur?.active === true },
        { slug: 'VALITOR', active: s?.valitor?.active === true },
        { slug: 'VERIFONE', active: s?.verifone?.active === true },
        { slug: 'RAPYD', active: s?.rapyd?.active === true },
        { slug: 'ONPAY', active: s?.onpay?.active === true },
    ];

    const defaultActive = x.find((y: any) => y.active === true) as {
        slug: string;
        active: boolean;
    };

    console.log('defaultActive', defaultActive?.slug, selectedShop);

    const [activeTab, setActiveTab] = useState(defaultActive?.slug);

    return (
        <>
            {selectedShop && (
                <Form
                    form={form}
                    layout="vertical"
                    name="basic"
                    onFinish={(values) => {
                        // console.log(values);
                        // if (!values.logo) {
                        //     message.error('Það vantar vörumerki');
                        //     return;
                        // }

                        const kt = new Kennitala(values.ssid);
                        // @ts-ignore
                        if (!kt.valid) {
                            message.error('Þetta er ekki gild kennitala');
                            return;
                        }
                        // @ts-ignore
                        values.ssid = kt.kt;

                        const activeCardProviderCount = [
                            values.valitor.active,
                            values.verifone.active,
                            values.rapyd.active,
                            values.salt_pay.active,
                            values.onpay.active,
                        ].filter((active) => active === true).length;

                        if (activeCardProviderCount > 1) {
                            message.error(
                                'Úps, aðeins hægt að hafa einn færsluhirði í kortagreiðslum'
                            );
                            return;
                        }

                        setLoading(true);

                        if (
                            (selectedShop && !selectedShop.admins) ||
                            !selectedShop
                        ) {
                            values.admins = [w.me];
                        }

                        if (w.uuid) {
                            if (typeof values.logo === 'object') {
                                values.logo = values.logo.id;
                            }

                            axios
                                .patch(
                                    '/verslun/api/update_shop/' + w.uuid + '/',
                                    values
                                )
                                .then((rsp) => {
                                    message.success(
                                        'Verslunin hefur verið uppfærð!'
                                    );
                                    setTimeout(() => {
                                        if (w.top?.location.href) {
                                            w.top.location.href =
                                                '/stjornbord/';
                                        }
                                    }, 500);
                                })
                                .catch((err) => {
                                    message.error(
                                        'Úps, eitthvað fór úrskeiðis 🤔 Farðu aftur yfir alla reiti.'
                                    );
                                    setLoading(false);
                                });
                        } else {
                            values.admins = [w.me];
                            axios
                                .post('/verslun/api/create_shop/', values)
                                .then((rsp) => {
                                    message.success(
                                        'Verslunin hefur verið stofnuð'
                                    );

                                    if (setSelectedShop) {
                                        setSelectedShop(rsp.data);
                                    }

                                    setTimeout(() => {
                                        w.location.href = '/stjornbord/';
                                    }, 500);
                                })
                                .catch((err) => {
                                    message.error(
                                        'Úps, eitthvað fór úrskeiðis 🤔 Farðu aftur yfir alla reiti.'
                                    );
                                    setLoading(false);
                                });
                        }
                    }}
                >
                    <Form.Item
                        label="Nafn (sýnilegt viðskiptavinum)"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Þú verður að gefa vörunni titil.',
                            },
                        ]}
                    >
                        <Input size="large" autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                        label="Kennitala"
                        name="ssid"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Þú verður að gefa upp kennitölu félagsins.',
                            },
                        ]}
                    >
                        <Input type="tel" size="large" autoComplete="off" />
                    </Form.Item>

                    <Form.Item
                        label="Netfang (sýnilegt viðskiptavinum)"
                        name="email"
                    >
                        <Input type="email" size="large" autoComplete="off" />
                    </Form.Item>

                    <Form.Item label="VSK númer (má sleppa)" name="vsk_number">
                        <Input type="tel" size="large" autoComplete="off" />
                    </Form.Item>

                    <div>
                        <h4>
                            Veldu greiðsluleiðir sem þú vilt bjóða upp á (má
                            fylla út seinna)
                        </h4>
                        <Collapse>
                            <Panel
                                header={
                                    <>
                                        <CreditCardFilled
                                            style={{
                                                width: 'auto',
                                                fontSize: 20,
                                                display: 'inline',
                                                marginRight: '5px',
                                            }}
                                        />
                                        <h2 style={{ display: 'inline' }}>
                                            Kortagreiðslur
                                        </h2>
                                    </>
                                }
                                forceRender
                                key="1"
                            >
                                <Tabs
                                    activeKey={activeTab}
                                    defaultActiveKey={defaultActive?.slug}
                                    onChange={(key) => {
                                        console.log('key', key);
                                        setActiveTab(key);
                                    }}
                                >
                                    <Tabs.TabPane
                                        tab={
                                            <SVG
                                                id="salt_logo"
                                                baseURL="/"
                                                cacheRequests={false}
                                                description="Salt logo"
                                                preProcessor={(code) => {
                                                    code = code.replace(
                                                        /<path/g,
                                                        '<path fill="#000" '
                                                    );
                                                    return code;
                                                }}
                                                src={
                                                    '/static/images/svg/teya.svg'
                                                }
                                                title="Salt logo"
                                                key={'salt_logo'}
                                                uniqueHash={'salt_logo'}
                                                uniquifyIDs={true}
                                                style={{
                                                    width: 'auto',
                                                    height: 30,
                                                    display: 'block',
                                                }}
                                            />
                                        }
                                        forceRender
                                        key="SALT_PAY"
                                    >
                                        <Form.Item
                                            label="Merchant Id"
                                            name={['salt_pay', 'merchant_id']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input
                                                type="tel"
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Private Key"
                                            name={['salt_pay', 'private_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            valuePropName="checked"
                                            label="Virkt"
                                            name={['salt_pay', 'active']}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane
                                        tab={
                                            <SVG
                                                id="straumur"
                                                baseURL="/"
                                                cacheRequests={false}
                                                description="Salt logo"
                                                preProcessor={(code) => {
                                                    code = code.replace(
                                                        /<path/g,
                                                        '<path fill="#000" '
                                                    );
                                                    return code;
                                                }}
                                                src={
                                                    '/static/images/svg/straumur.svg'
                                                }
                                                title="Straumur logo"
                                                key={'straumur_logo'}
                                                uniqueHash={'straumur_logo'}
                                                uniquifyIDs={true}
                                                style={{
                                                    width: 'auto',
                                                    height: 15,
                                                    display: 'block',
                                                }}
                                            />
                                        }
                                        forceRender
                                        key="STRAUMUR"
                                    >
                                        <Form.Item
                                            label="Private Key"
                                            name={['straumur', 'private_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input
                                                type="tel"
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Public Key"
                                            name={['straumur', 'public_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Agreement Number"
                                            name={[
                                                'straumur',
                                                'agreement_number',
                                            ]}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Terminal Id"
                                            name={['straumur', 'terminal_id']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            valuePropName="checked"
                                            label="Virkt"
                                            name={['straumur', 'active']}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane
                                        tab={
                                            <SVG
                                                id="valitor_logo"
                                                baseURL="/"
                                                cacheRequests={false}
                                                description="Valitor logo"
                                                preProcessor={(code) => {
                                                    code = code.replace(
                                                        /<path/g,
                                                        '<path fill="#000" '
                                                    );
                                                    code = code.replace(
                                                        /fill:#fff/g,
                                                        'fill="#000'
                                                    );

                                                    return code;
                                                }}
                                                src={
                                                    '/static/images/svg/valitor.svg'
                                                }
                                                title="Valitor logo"
                                                key={'valitor_logo'}
                                                uniqueHash={'valitor_logo'}
                                                uniquifyIDs={true}
                                                style={{
                                                    width: 'auto',
                                                    height: 15,
                                                    display: 'block',
                                                }}
                                            />
                                        }
                                        forceRender
                                        key="VALITOR"
                                    >
                                        <Form.Item
                                            label='Valitor "Private Key"'
                                            name={['valitor', 'private_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input
                                                type="tel"
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='Valitor "Public Key"'
                                            name={['valitor', 'public_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='Valitor "Agreement Number"'
                                            name={[
                                                'valitor',
                                                'agreement_number',
                                            ]}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label='Valitor "Terminal Id"'
                                            name={['valitor', 'terminal_id']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            valuePropName="checked"
                                            label="Virkt"
                                            name={['valitor', 'active']}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane
                                        tab={
                                            <>
                                                <SVG
                                                    id="verifone_logo"
                                                    baseURL="/"
                                                    cacheRequests={false}
                                                    description="verifone logo"
                                                    src={
                                                        '/static/images/svg/verifone.svg'
                                                    }
                                                    title="verifone logo"
                                                    key={'verifone_logo'}
                                                    uniqueHash={'verifone_logo'}
                                                    uniquifyIDs={true}
                                                    style={{
                                                        width: 'auto',
                                                        height: 20,
                                                        display: 'block',
                                                    }}
                                                    preProcessor={(code) => {
                                                        code = code.replace(
                                                            /<path/g,
                                                            '<path fill="#000" '
                                                        );
                                                        code = code.replace(
                                                            /fill:#fff/g,
                                                            'fill="#000'
                                                        );

                                                        return code;
                                                    }}
                                                />
                                            </>
                                        }
                                        forceRender
                                        key="VERIFONE"
                                    >
                                        <Form.Item
                                            label="API token"
                                            name={[
                                                'verifone',
                                                'payment_provider_contract_id',
                                            ]}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Organization Id"
                                            name={[
                                                'verifone',
                                                'organization_id',
                                            ]}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Three D Secure Contract Id"
                                            name={[
                                                'verifone',
                                                'three_d_secure_contract_id',
                                            ]}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Public Encryption Key"
                                            name={[
                                                'verifone',
                                                'public_encryption_key',
                                            ]}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Public Encryption Key Alias"
                                            name={[
                                                'verifone',
                                                'public_encryption_key_alias',
                                            ]}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Token Scope"
                                            name={['verifone', 'token_scope']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="API Key"
                                            name={['verifone', 'api_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="User Id"
                                            name={['verifone', 'user_id']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            valuePropName="checked"
                                            label="Virkt"
                                            name={['verifone', 'active']}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane
                                        tab={
                                            <SVG
                                                id="rapyd_logo"
                                                baseURL="/"
                                                cacheRequests={false}
                                                description="Rapyd logo"
                                                src={
                                                    '/static/images/svg/rapyd.svg'
                                                }
                                                title="Rapyd logo"
                                                key={'rapyd_logo'}
                                                uniqueHash={'rapyd_logo'}
                                                uniquifyIDs={true}
                                                style={{
                                                    width: 'auto',
                                                    height: 20,
                                                    display: 'block',
                                                }}
                                                preProcessor={(code) => {
                                                    code = code.replace(
                                                        /<path/g,
                                                        '<path fill="#000" '
                                                    );
                                                    code = code.replace(
                                                        /fill:#fff/g,
                                                        'fill="#000'
                                                    );

                                                    return code;
                                                }}
                                            />
                                        }
                                        forceRender
                                        key="RAPYD"
                                    >
                                        <Form.Item
                                            label="Access Key"
                                            name={['rapyd', 'access_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label="Secret Key"
                                            name={['rapyd', 'secret_key']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            valuePropName="checked"
                                            label="Virkt"
                                            name={['rapyd', 'active']}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Tabs.TabPane>

                                    <Tabs.TabPane
                                        tab={
                                            <SVG
                                                id="onpay_logo"
                                                baseURL="/"
                                                cacheRequests={false}
                                                description="Onpay logo"
                                                src={
                                                    '/static/images/svg/onpay.svg'
                                                }
                                                title="Onpay logo"
                                                key={'onpay_logo'}
                                                uniqueHash={'onpay_logo'}
                                                uniquifyIDs={true}
                                                style={{
                                                    width: 'auto',
                                                    height: 20,
                                                    display: 'block',
                                                }}
                                                preProcessor={(code) => {
                                                    code = code.replace(
                                                        /<path/g,
                                                        '<path fill="#000" '
                                                    );
                                                    code = code.replace(
                                                        /fill:#fff/g,
                                                        'fill="#000'
                                                    );

                                                    return code;
                                                }}
                                            />
                                        }
                                        forceRender
                                        key="onpay"
                                    >
                                        <Form.Item
                                            label="API token"
                                            name={['onpay', 'api_token']}
                                            extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                        >
                                            <Input.Password
                                                visibilityToggle={true}
                                                size="large"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            valuePropName="checked"
                                            label="Virkt"
                                            name={['onpay', 'active']}
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Tabs.TabPane>
                                </Tabs>
                            </Panel>

                            <Panel
                                header={
                                    <>
                                        <BankFilled
                                            style={{
                                                width: 'auto',
                                                fontSize: 20,
                                                display: 'inline',
                                                marginRight: '5px',
                                            }}
                                        />
                                        <h2 style={{ display: 'inline' }}>
                                            Bankatenging
                                        </h2>
                                    </>
                                }
                                forceRender
                                key="2"
                            >
                                {/* TODO: Laga uppsetningu banka-logo-a */}
                                <div className="text-center">
                                    <img
                                        style={{ width: 33 }}
                                        src="/static/images/svg/landsbankinn_kassi.svg"
                                        alt="Landsbankinn logo"
                                    />{' '}
                                    <img
                                        style={{ width: 33 }}
                                        src="/static/images/svg/arion_kassi.svg"
                                        alt="arion logo"
                                    />{' '}
                                    <img
                                        style={{ width: 44 }}
                                        src="/static/images/svg/islandsbanki_kassi.svg"
                                        alt="islandsbanki logo"
                                    />
                                </div>

                                <p>
                                    Vinsamlegast hafið samband við okkur ef þið
                                    vilt stofna kröfur með Repeat. Við hjálpum
                                    ykkur að setja upp tengingu í samvinnu við
                                    bankann ykkar.
                                </p>
                                {/* <Form.Item
                                        label="Client Id"
                                        name="banking_client_id"
                                        extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                    >
                                        <Input
                                            type="tel"
                                            size="large"
                                            autoComplete="off"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Client Secret"
                                        name="banking_client_secret"
                                        extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                    >
                                        <Input
                                            size="large"
                                            autoComplete="off"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Company Id"
                                        name="banking_company_id"
                                        extra="Þú getur fyllt þessar upplýsingar út seinna ef þú ert ekki með þær núna."
                                    >
                                        <Input
                                            size="large"
                                            autoComplete="off"
                                        />
                                    </Form.Item> */}
                            </Panel>
                        </Collapse>
                    </div>

                    <Form.Item
                        className="mt20"
                        label="Vörumerki (sýnilegt kúnnum)"
                        name="logo"
                        valuePropName="fileList"
                        rules={[
                            {
                                required: true,
                                message: 'Vörumerki er nauðsynlegt',
                            },
                        ]}
                    >
                        <UploadImage
                            defaultFileList={imageFileList}
                            onSuccess={(ImageId: string) => {
                                form.setFieldsValue({
                                    logo: parseInt(ImageId),
                                });
                            }}
                        ></UploadImage>
                    </Form.Item>

                    <Form.Item
                        label={
                            <>
                                <Popover
                                    content={
                                        <>
                                            <p>
                                                ATH. Flestir velja "Nei" (Þetta
                                                er annað en að halda utan um
                                                afgreiðslu sendingar).
                                            </p>
                                            <p>
                                                Þetta á bara við um pöntuna
                                                sjálfa (gerist einu sinni).
                                            </p>

                                            <p>
                                                Dæmi um fyrirtæki sem nota
                                                þennan fítus: Bílstólaleiga sem
                                                þarf að merkja við hvort að
                                                bílstóllinn sé afhentur eða ekki
                                                (gerist bara einu sinni).
                                            </p>
                                        </>
                                    }
                                >
                                    <QuestionCircleTwoTone
                                        twoToneColor="#afafaf"
                                        style={{
                                            position: 'absolute',
                                            fontSize: 16,
                                        }}
                                    />
                                </Popover>
                                <span
                                    style={{
                                        position: 'relative',
                                        left: 20,
                                    }}
                                >
                                    Halda utan um afgreiðslu pantana?
                                </span>
                            </>
                        }
                        name="manage_orders"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    manage_orders: checked,
                                });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <>
                                <Popover
                                    content={
                                        <>
                                            <p>
                                                Átt er við þegar fólk er skráð í
                                                áskrift að fleiri en einni vöru.
                                            </p>
                                            <p>
                                                Kortið er rukkað einu sinni
                                                fyrir heildarfjáræðina.
                                            </p>
                                            <p>
                                                Ef þú ert með tengingu við ytra
                                                kerfi (t.d Shopify eða
                                                Woocommerce) þá sendist allt
                                                saman í einni pöntun.
                                            </p>

                                            <p>
                                                Sendingarkostnaður verður bara
                                                rukkaður einu sinni (ef það á
                                                við).
                                            </p>

                                            <p>
                                                Athugaðu að þetta virkar
                                                einungis þegar rukkað er
                                                áskrifanda fyrir fleiri en eina
                                                áskrift SAMA DAGINN.
                                            </p>
                                        </>
                                    }
                                >
                                    <QuestionCircleTwoTone
                                        twoToneColor="#afafaf"
                                        style={{
                                            position: 'absolute',
                                            fontSize: 16,
                                        }}
                                    />
                                </Popover>
                                <span
                                    style={{
                                        position: 'relative',
                                        left: 20,
                                    }}
                                >
                                    Viltu sameina áskriftir í eina pöntun ef þær
                                    lenda á sama degi?
                                </span>
                            </>
                        }
                        valuePropName="checked"
                        name="bundle_same_day_subscription_transactions"
                    >
                        <Switch
                            checkedChildren="JÁ "
                            unCheckedChildren="NEI"
                            onChange={(checked) => {
                                form.setFieldsValue({
                                    bundle_same_day_subscription_transactions:
                                        checked,
                                });
                            }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className="btn-success"
                            block
                            size="large"
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                            Vista
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>
    );
}
