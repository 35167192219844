import { useState, useEffect } from 'react';
import { Card, Tooltip, Avatar, Space, Button, Modal, Table } from 'antd';

import {
    ScheduleTwoTone,
    FileTextTwoTone,
    FileTextOutlined,
    AudioTwoTone,
    IdcardTwoTone,
    TagTwoTone,
    MailOutlined,
    CarOutlined,
} from '@ant-design/icons';
import Axios from 'axios';
import { displayCurrencyPrice, kr } from '../../utils';
import Shop from '../../interfaces/shop';
import Product, { WaitingListEntry } from '../../interfaces/product';
import dayjs from 'dayjs';
import EmailModal from '../EmailModal/EmailModal';
import { DeliveryOption } from '../../interfaces/delivery';
import translate from '../../translate/translate';
import ProductDropdownOptions from '../ProductDropdownOptions/ProductDropdownOptions';

type Props = {
    product: Product;
    shop: Shop;
    lastItem: boolean;
};

export default function AdminProductPreview({
    product,
    lastItem,
    shop,
}: Props) {
    const [waitingList, setwaitingList] = useState<WaitingListEntry[]>([]);
    const [showWaitingList, setShowWaitingList] = useState<boolean>(false);
    const [emailModal, setEmailModal] = useState<string[] | false>(false);

    useEffect(() => {
        if (product.out_of_stock_waiting_list) {
            Axios.get(
                '/verslun/api/waiting_list/' +
                    product.uuid +
                    '/?ordering=created'
            ).then((rsp) => {
                setwaitingList(rsp.data);
            });
        }
    }, [product.out_of_stock_waiting_list]);

    const reduceDeliveryOptions = (options: DeliveryOption[]) => {
        return options.reduce(
            (
                prev: {
                    POSTURINN: string[];
                    DROPP: string[];
                    CUSTOM: string[];
                },
                next
            ) => {
                let currentKey = 'CUSTOM' as 'CUSTOM' | 'POSTURINN' | 'DROPP';

                if (next.shipping_provider.includes('POSTURINN')) {
                    currentKey = 'POSTURINN';
                } else if (next.shipping_provider.includes('DROPP')) {
                    currentKey = 'DROPP';
                }

                const label = next.title;

                const previousList = prev[currentKey];

                return { ...prev, [currentKey]: [...prev[currentKey], label] };
            },
            {
                POSTURINN: [],
                DROPP: [],
                CUSTOM: [],
            }
        );
    };

    const options = reduceDeliveryOptions(
        product.delivery_config?.options || []
    );

    return (
        <>
            <Card
                headStyle={lastItem ? { borderBottom: 'none' } : {}}
                style={lastItem ? { marginBottom: 0 } : {}}
                size="small"
                extra={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        {product.out_of_stock_waiting_list && (
                            <div style={{ marginBottom: 10, fontSize: 11 }}>
                                <Button
                                    onClick={() => {
                                        setShowWaitingList(true);
                                    }}
                                    block
                                    size="small"
                                >
                                    <FileTextOutlined /> Biðlisti (
                                    {waitingList.length})
                                </Button>
                            </div>
                        )}
                        <div style={{ marginBottom: 15 }}>
                            <Avatar.Group
                                maxStyle={{
                                    color: '#f56a00',
                                    backgroundColor: '#fde3cf',
                                }}
                            >
                                {product.shopify_info &&
                                    product.shopify_info
                                        .create_shopify_order && (
                                        <Tooltip
                                            title="Þessi vara er tengd Shopify"
                                            placement="top"
                                        >
                                            <Avatar src="/static/images/svg/shopify.svg" />
                                        </Tooltip>
                                    )}

                                {product.delivery_config &&
                                    product.delivery_config?.options && (
                                        <>
                                            {options.POSTURINN.length > 0 && (
                                                <Tooltip
                                                    title={options.POSTURINN.map(
                                                        (label) => (
                                                            <div> {label} </div>
                                                        )
                                                    )}
                                                    placement="top"
                                                >
                                                    <Avatar src="/static/images/svg/posturinn.svg" />
                                                </Tooltip>
                                            )}

                                            {options.DROPP.length > 0 && (
                                                <Tooltip
                                                    title={options.DROPP.map(
                                                        (label) => (
                                                            <div> {label} </div>
                                                        )
                                                    )}
                                                    placement="top"
                                                >
                                                    <Avatar src="/static/images/global_logos/dropp.svg" />
                                                </Tooltip>
                                            )}

                                            {options.CUSTOM.length > 0 && (
                                                <Tooltip
                                                    title={options.CUSTOM.map(
                                                        (label) => (
                                                            <div> {label} </div>
                                                        )
                                                    )}
                                                    placement="top"
                                                    color="black"
                                                >
                                                    <Avatar
                                                        style={{
                                                            color: '#aaa',
                                                            fill: 'black',
                                                        }}
                                                        icon={<CarOutlined />}
                                                    />
                                                </Tooltip>
                                            )}
                                        </>
                                    )}

                                {product.out_of_stock_waiting_list && (
                                    <Tooltip
                                        title="Þessi vara er með biðlista þegar hún er uppseld."
                                        placement="top"
                                    >
                                        <Avatar
                                            // style={{
                                            //     background:
                                            //         '#728bff',
                                            // }}
                                            src={
                                                <FileTextTwoTone
                                                    twoToneColor={'#9f9f9f'}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                )}

                                {product.appwrite_user_profiles && (
                                    <Tooltip
                                        title="Viðskiptavinir fá aðgang að sínu eigin svæði."
                                        placement="top"
                                    >
                                        <Avatar
                                            src={
                                                <IdcardTwoTone
                                                    twoToneColor={'#9f9f9f'}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                )}

                                {product.podcast_rss_feed && (
                                    <Tooltip
                                        title="Áskrifendur fá sinn eigin RSS hlaðvarp hlekk."
                                        placement="top"
                                    >
                                        <Avatar
                                            src={
                                                <AudioTwoTone
                                                    twoToneColor={'#9f9f9f'}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                )}

                                {((product.interval_combinations &&
                                    product.interval_combinations.length > 0) ||
                                    product.cron_transaction) &&
                                    product.type === 'SUBSCRIPTION' && (
                                        <Tooltip
                                            title={
                                                <>
                                                    {product.cron_transaction && (
                                                        <div>
                                                            {`Sjálfkrafa reglulegar færslur, grunnstilling: (${
                                                                product
                                                                    .cron_transaction
                                                                    .interval
                                                            } ${
                                                                (product
                                                                    .cron_transaction
                                                                    .interval_type ===
                                                                    'YEAR' &&
                                                                    'ára') ||
                                                                (product
                                                                    .cron_transaction
                                                                    .interval_type ===
                                                                    'MONTH' &&
                                                                    'mánaða') ||
                                                                (product
                                                                    .cron_transaction
                                                                    .interval_type ===
                                                                    'WEEK' &&
                                                                    'vikna')
                                                            } fresti)`}
                                                        </div>
                                                    )}

                                                    {product.interval_combinations &&
                                                        product
                                                            .interval_combinations
                                                            .length > 0 && (
                                                            <div className="mt20">
                                                                Leyfileg tíðni
                                                                sendinga:{' '}
                                                                {product.interval_combinations.map(
                                                                    (
                                                                        obj,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    textTransform:
                                                                                        'capitalize',
                                                                                }}
                                                                                key={
                                                                                    key
                                                                                }
                                                                            >
                                                                                {translate(
                                                                                    obj.type,
                                                                                    'is'
                                                                                )}
                                                                                {
                                                                                    ': '
                                                                                }
                                                                                {obj.count_options?.join(
                                                                                    ','
                                                                                )}{' '}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                </>
                                            }
                                            placement="top"
                                        >
                                            <Avatar
                                                src={
                                                    <ScheduleTwoTone
                                                        twoToneColor={'#9f9f9f'}
                                                    />
                                                }
                                            ></Avatar>
                                        </Tooltip>
                                    )}

                                {product.woocommerce_info &&
                                    product.woocommerce_info
                                        .create_woocommerce_order && (
                                        <Tooltip
                                            title="Þessi vara er tengd Woocommerce"
                                            placement="top"
                                        >
                                            <Avatar src="/static/images/svg/woocommerce.svg" />
                                        </Tooltip>
                                    )}

                                {product.show_customer_price_input && (
                                    <Tooltip
                                        title="Viðskiptavinur getur valið upphæð sjálf/ur"
                                        placement="top"
                                    >
                                        <Avatar
                                            src={
                                                <TagTwoTone
                                                    twoToneColor={'#9f9f9f'}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                )}
                            </Avatar.Group>
                        </div>

                        <Space
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                            }}
                        >
                            <ProductDropdownOptions
                                product={product}
                            ></ProductDropdownOptions>
                        </Space>
                    </div>
                }
                title={
                    <div style={{ display: 'flex', minWidth: 200 }}>
                        <div className="productImageContainer">
                            <img
                                className="product_image"
                                src={product.main_image?.image}
                                alt="Vörumynd"
                            />
                        </div>
                        <div style={{ whiteSpace: 'normal' }}>
                            <Tooltip title={product.title}>
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        maxWidth: 200,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {product.title}{' '}
                                </div>
                            </Tooltip>

                            <div
                                style={{
                                    color: 'lightgray',
                                    fontSize: 11,
                                }}
                            >
                                {product.type === 'GIFT_CERTIFICATE' &&
                                    'Gjafabréf'}

                                {product.type === 'SUBSCRIPTION' && 'Áskrift'}

                                {product.type === 'ONE_TIME' &&
                                    'Hefðbundin vara (ekki áskrift)'}

                                {product.type === 'ADD_ON' &&
                                    'Viðbótarvara (Add-on)'}
                            </div>

                            {product.attributes &&
                                product.attributes.length > 0 && (
                                    <div
                                        style={{
                                            fontSize: 11,
                                            color: '#ff5967',
                                        }}
                                        className="productAttributes"
                                    >
                                        {product.attributes.map(
                                            (attribute, key) => {
                                                return (
                                                    <span key={key}>
                                                        {attribute.label}{' '}
                                                        {key !==
                                                            (product.attributes
                                                                ?.length || 0) -
                                                                1 && ', '}
                                                    </span>
                                                );
                                            }
                                        )}
                                    </div>
                                )}

                            <div style={{ fontSize: 11 }}>
                                {product.inventory && (
                                    <div>
                                        Birgðastaða:{' '}
                                        {product.inventory.quantity}{' '}
                                    </div>
                                )}
                            </div>

                            <div
                                style={{
                                    color: 'lightgray',
                                    fontSize: 11,
                                }}
                            >
                                {product.price.custom_price_options &&
                                    product.price.custom_price_options?.map(
                                        (option, key) => {
                                            return (
                                                <span key={key}>
                                                    {kr(option)}{' '}
                                                    {key !==
                                                        (product.price
                                                            .custom_price_options
                                                            ?.length || 999) -
                                                            1 && ' | '}
                                                </span>
                                            );
                                        }
                                    )}

                                {product.prices?.map((obj, key) => {
                                    return (
                                        <span key={key}>
                                            {' '}
                                            {displayCurrencyPrice(
                                                obj.amount || 0,
                                                obj.currency.currency_code
                                            )}{' '}
                                            {key !==
                                                product.prices.length - 1 &&
                                                ' | '}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                }
                bodyStyle={{
                    display: 'none',
                }}
            ></Card>

            {showWaitingList && (
                <Modal
                    title="Biðlisti"
                    width={900}
                    open={showWaitingList}
                    onCancel={() => {
                        setShowWaitingList(false);
                    }}
                    footer={null}
                >
                    <Table
                        className="white_table"
                        pagination={false}
                        rowKey="product"
                        columns={[
                            {
                                title: 'Nafn',
                                dataIndex: 'name',
                            },
                            {
                                title: 'Netfang',
                                dataIndex: 'email',
                                render: (email) => {
                                    return (
                                        <>
                                            {email}{' '}
                                            <Button
                                                onClick={() => {
                                                    setEmailModal([email]);
                                                }}
                                                size="small"
                                            >
                                                <MailOutlined /> Senda póst{' '}
                                            </Button>{' '}
                                        </>
                                    );
                                },
                            },
                            {
                                title: 'Sími',
                                dataIndex: 'phone',
                            },
                            {
                                title: 'Dags',
                                dataIndex: 'created',
                                render: (obj) => {
                                    return dayjs(obj).format(
                                        'D. MMMM YYYY HH:mm'
                                    );
                                },
                            },
                            {
                                title: 'Eyða',
                                render: (obj) => {
                                    return (
                                        <Button
                                            onClick={() => {
                                                Axios.patch(
                                                    '/verslun/api/update_waiting_list_entry/' +
                                                        obj.id +
                                                        '/',
                                                    {
                                                        is_deleted: true,
                                                    }
                                                ).then(() => {
                                                    setwaitingList(
                                                        waitingList.filter(
                                                            (entry) =>
                                                                entry.id !==
                                                                obj.id
                                                        )
                                                    );
                                                });
                                            }}
                                            size="small"
                                            danger
                                        >
                                            Eyða
                                        </Button>
                                    );
                                },
                            },
                        ]}
                        dataSource={waitingList}
                    ></Table>
                </Modal>
            )}

            {!!emailModal && (
                <EmailModal
                    dataType="EMAIL_LIST"
                    to={emailModal}
                    shop={shop.uuid}
                    onCancel={() => {
                        setEmailModal(false);
                    }}
                    open={!!emailModal}
                ></EmailModal>
            )}
        </>
    );
}
