import { Progress } from 'antd';
import React from 'react';

import ApexCharts from 'react-apexcharts';
import { kr } from '../../../utils';

interface ShopData {
    shopName: string;
    lastMonth: number;
    thisMonth: number;
}

interface ShopSalesProps {
    shopData: ShopData[];
}

const ShopSalesChart: React.FC<ShopSalesProps> = ({ shopData }) => {
    const data = shopData.filter((shop) => {
        function calculatePercentageDifference(a: number, b: number) {
            return Math.abs((a - b) / ((a + b) / 2)) * 100;
        }

        // Example usage:
        const number1 = shop.thisMonth;
        const number2 = shop.lastMonth;

        const percentageDiff = calculatePercentageDifference(number1, number2);
        return percentageDiff > 30 && (number1 > 12000 || number2 > 12000);
    });

    // const chartData = {
    //     options: {
    //         chart: {
    //             id: 'shop-sales-chart',
    //         },
    //         xaxis: {
    //             categories: scaledShopData.map((shop) => shop.shopName),
    //             labels: {
    //                 rotate: -45,
    //             },
    //         },
    //     },
    //     series: [
    //         {
    //             name: 'Síðasta mánuður',
    //             data: scaledShopData.map((shop) => shop.lastMonth),
    //         },
    //         {
    //             name: 'Þessi mánuður',
    //             data: scaledShopData.map((shop) => shop.thisMonth),
    //         },
    //     ],
    // };

    return (
        <div>
            <div
                style={{
                    marginTop: 20,
                    marginBottom: 5,
                }}
            >
                Meiri en 30% munur á milli tímabila
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                {data
                    .sort(
                        (shop) =>
                            shop.lastMonth / (shop.lastMonth + shop.thisMonth)
                    )
                    .map((shop) => {
                        return (
                            <div>
                                <div
                                    style={{
                                        position: 'relative',
                                        left: 10,
                                        marginTop: 10,
                                        fontSize: 10,
                                    }}
                                >
                                    {shop.shopName}
                                </div>

                                <div
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                        display: 'flex',
                                        border: '1px solid rgba(158, 158, 158, 0.25)',
                                        textAlign: 'center',
                                        borderRadius: 20,
                                        overflow: 'hidden',
                                        fontSize: 8,
                                        color: '#656565',
                                    }}
                                >
                                    <div
                                        style={{
                                            width:
                                                (shop.lastMonth /
                                                    (shop.lastMonth +
                                                        shop.thisMonth)) *
                                                    100 +
                                                '%',
                                            background:
                                                'rgba(158, 158, 158, 0.25)',
                                            height: 12,
                                            position: 'relative',
                                        }}
                                    >
                                        {kr(shop.lastMonth)}
                                    </div>
                                    <div
                                        style={{
                                            width:
                                                (shop.thisMonth /
                                                    (shop.lastMonth +
                                                        shop.thisMonth)) *
                                                    100 +
                                                '%',
                                            height: 12,
                                            position: 'relative',
                                            background: 'white',
                                        }}
                                    >
                                        {' '}
                                        {kr(shop.thisMonth)}{' '}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default ShopSalesChart;
